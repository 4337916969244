<template>
  <div class="main-body" v-if="conVoteliveBgUrl!=null && conVoteliveBgUrl!=''" :style="{'background-image': 'url(' + conVoteliveBgUrl + ')'}">
   <!--<div class="main-body">-->
    <div class="main">
        <div class="title-box" id="titleBox" v-if="partNumber!=0">
            <div class="title" v-if="projData.contestName"></div><!--{{projData.contestName}}-->
            <div class="huanjie" v-if="projData.contvoteName"></div><!--{{projData.contvoteName}}-->
            <!-- <template v-if="projData.contprojList"> -->
              <!-- <div class="project-name" id="contproj">
                  <span class="city" v-if="cityHtml">{{cityHtml}}</span>
                  <template v-if="contprojName">
                    {{contprojName}}
                  </template>
              </div> -->
            <!-- </template> -->
            <div class="dfb-box">
                <div class="dfb-title">实时得分榜<span class="dfb-title-tips">15秒更新一次</span></div>
                <div class="dfb-head">
                    <div>序号</div>
                    <div>项目名</div>
                    <div>得分</div>
                </div>
                <div class="dfb-table-box">
                    <table>
                        <tbody>
                            <tr v-for="(item,index) in projScoreList" :key="index">
                                <td>{{index + 1}}</td>
                                <td><div>{{item.contprojName}}</div></td>
                                <td v-if="item.voteprojScore>=0">{{item.voteprojScore}}</td>
                                <td v-if="item.voteprojScore == -1">--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </div>
  </div>
</template>

<script>
var timer;


export default {
  data () {
    return {
      contvoteId: null,//现场id
      projData:[],
      cityHtml:null, 
      contprojName: null,

      partNumber: 1,
      nowProj: [],

      TimeCountDown: null,
      colorRed: false,

      pwList: [],

      progressBarWidth: 0,
      wclHTML: '',

      finalScore: null,

      conVoteliveBgUrl: null,

      projScoreList: [],

    }
  },
  methods: {
    //获取指定大赛现场信息
    getStageLive(){
      var that = this;
      this.$post('/api/votelive/get',{
        contvoteId: this.contvoteId,
      }).then((res)=>{
        // console.log(res);
        if(res.result){
            let data = res.resultData,
            projectList = res.resultData.contprojList;

            this.projData = res.resultData;
            console.log(data.conVoteliveBgUrl);
            console.log(this.$store.state.baseHost);
            if(data.conVoteliveBgUrl){
                this.conVoteliveBgUrl = this.$store.state.baseHost+"/"+data.conVoteliveBgUrl;
            }

            if (data.contprojList.length) {
            if(data.contprojList[0].contprojRegion!=null&&data.contprojList[0].contprojRegion!=''){
                this.cityHtml = data.contprojList[0].contprojRegion;
            }
            this.contprojName = data.contprojList[0].contprojName;
            }

            that.getProjScore()

            clearInterval(timer);
            timer = setInterval(function () {
                that.getProjScore()
            }, 15000)
          
        }else{
          this.$message.error(res.resultMsg);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取分数
    getProjScore(){
        this.$post('/api/votelive/listLiveProjScores',{
            contvoteId: this.contvoteId
        }).then((res)=>{
            console.log(res)
            if(res.result){
                this.projScoreList = res.resultData;
            }else{
                this.projScoreList = [];
            }
        }).catch((res)=>{
            console.log(res)
        })
    },
  },
  mounted () {
    if(this.$route.query.contvoteId){
      this.contvoteId = this.$route.query.contvoteId;
      this.getStageLive();
    }else{
      this.$message.error('缺少现场id');
    }

      
  }
}
</script>

<style scoped>
.main-body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: url('../assets/touping-bg1.png') no-repeat center;
    background-size: cover;
}

.main{max-width: 1340px;height: 100%;display: flex;align-items: center;margin: 0 auto;text-align: center;flex-direction: column;justify-content: center;}
.main .title-box{margin-bottom: auto;margin-top: 290px;}
.main .title{font-size: 55px;color: #fff;font-weight: bold;margin-bottom: 149px; font-family:"Microsoft Yahei";}
.main .huanjie{font-size: 46px;color: #fae111;font-weight: bold;margin-bottom: 37px;margin-top: -108px;}
.main .project-name{background: #3f91c9;border: 1px solid #fff;border-radius: 4px;overflow: hidden;padding: 15px;color: #fff;font-weight: bold;font-size: 36px;display: inline-block;}
.main .project-name .city{background: #faab11;border-radius: 15px;display: inline-block;padding: 5px 20px;margin-right: 30px;}
.main .content{width:100%;}

/* dfb-box是数据展示总高度，修改后，也要修改dfb-table-box的值，dfb-table-box = dfb-box的高度 减去 dfb-title的高度 减去 dfb-head的高度 */
.main .dfb-box{width: 1000px;height: 640px;background: #fff;margin: 0 auto;overflow: hidden;}
.main .dfb-box .dfb-title{background: #5B9BD5;height: 70px;line-height:70px;width: 100%;text-align: center;position: relative;font-size: 35px;font-weight:bold;color: #fff;}
.main .dfb-box .dfb-title .dfb-title-tips{font-size: 17px;color: #fff;position: absolute;right: 20px; top:0px;}
.main .dfb-box .dfb-head{width: 50px;line-height: 50px;background: #D2DEEF;overflow: hidden;width: 100%;}
.main .dfb-box .dfb-head div{width: 250px;font-size: 27px;font-weight: bold;text-align: center;display: inline-block;margin: 0;padding: 0;}
.main .dfb-box .dfb-head div:nth-of-type(2){width: 500px;}
.main .dfb-box .dfb-table-box{width: 100%;height: 500px;overflow-y: auto;overflow-x: hidden;}
.main .dfb-box table{width: 100%;height: auto;text-align: center;}
.main .dfb-box table tbody tr{height: 50px;line-height: 50px;}
.main .dfb-box table tbody tr:nth-child(odd){background: #EAEFF7;}
.main .dfb-box table tbody tr:nth-child(even){background: #D2DEEF;}
.main .dfb-box table tbody tr td{width: 250px;font-size: 23px;}
.main .dfb-box table tbody tr td:nth-of-type(2){width: 500px;font-size: 23px;}
.main .dfb-box table tbody tr td:nth-of-type(2) div{width: 500px;font-size: 23px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.main .dfb-box table tbody tr td:last-of-type{color: #ED8A4F;font-weight: bold;}

</style>