<template>
	<div>
		<!-- pc端 -->
		<!-- v-if="courseDetailData.course" 不判空控制台报错和警告 -->
		<div style="background-color: #f5f5f5;" class="hidden-md-and-down" v-if="courseDetailData.course">
			<nav-bar v-bind:currentId="99"></nav-bar>
			<div class="pc_course_detail_wrapper">
				<div class="zl_detail">
					<div class="w1200">
						<div class="crumbs">
							<!--<li>
								<router-link :to="'/home/' + this.$store.state.oid">首页</router-link>
								<i>\</i>
							</li>
							<li>
								<router-link to="/kechengguangchang">课程广场</router-link>
								<i>\</i>
							</li>
							<li>
								<a href="javascript:;" style="cursor: default;">
									{{courseDetailData.course.courseName}}
								</a>
							</li>-->
						</div>
						<div class="course_data">
							<div class="course_left">
								<h2 class="cq_title"> {{courseDetailData.course.courseName}}</h2>
								<ul class="zq_courseList">
									<li style="margin-bottom:0">課程評分:
										<p class="mintain">
										<ul class="rating">
											<li class="start" v-for="i in 5" :key="i">★</li>
										</ul>
										<span class="rating-value">(5分)</span>
										</p>
									</li>
									<li>主講人:
										<p>{{courseDetailData.course.courseAuthor}}</p>
									</li>
									<li>開課時間:
										<p>2021.07.31－2021.12.31</p>
									</li>
								</ul>
								<div class="start_learn">
									<el-button type="primary" size="medium" style="padding: 20px 90px;font-size:18px"
										@click="handleClickStartStudy">
										開始學習</el-button>
									<!-- <span class="inf">
										<span
											style="font-size:20px;color: #a3abcc;margin-right:5px">3.79万</span>人学习</span> -->
								</div>
							</div>
							<div class="course_right" id="videoContainer">
								<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + courseDetailData.course.courseCoverUrl"
									:onerror="defaultCourseCoverImg"
									v-if="courseDetailData.course.courseVideoUrl == null">
								<div class="" v-else style="width:100%;height:100%">
									<div class="stop_pic" v-show="showVideo==false">
										<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + courseDetailData.course.courseCoverUrl"
											:onerror="defaultCourseCoverImg">
										<a href="javascript:;" class="carton_button" @click="test">
											<span>▶</span>
										</a>
									</div>
									<d-player ref="player" id="player" :options="options" v-show="showVideo"
										style="width:100%;height:100%"></d-player>
								</div>
								<!-- <video-player v-else ref="videoPlayer" :playsinline="true" :options="playerOptions">
								</video-player> -->
								<!-- <vue-core-video-player :src=" $store.state.baseHost + '/fs/download?storeKey=' + courseDetailData.course.courseVideoUrl" v-else></vue-core-video-player> -->
							</div>
						</div>
					</div>
				</div>
				<div class="detail_main">
					<div class="w1200">
						<div class="main_courseilfy">
							<div class="tabs_wrap">
								<el-tabs v-model="activeName" @tab-click="handleClickTab">
									<el-tab-pane label="課程詳情" name="first">
										<div class="course_desc">簡介：
											<span v-html="courseDetailData.course.courseAbstract"></span>
										</div>
										<div class="category_title">
											<img src="../assets/gs.png" alt=""
												style="width:20px;height:20px;margin-right:10px">
											課程概述
										</div>
										<div ref="courseIntroduction" class="content edit_html"
											:style="{'height':more?'auto':'200px','padding-bottom':paddingBottom?'100px':''}">
											<input type="text" id="read-more">
											<label for="remode-more" v-show="labelShadow"></label>
											<div class="course_intro"
												v-html="courseDetailData.course.courseIntroduction">
											</div>
											<el-button type="primary" size="mini" class="show_more" plain
												@click="handleClickShowAllCourseIntroductionHtml" v-show="showAllBtn">
												展開更多
												<i class="el-icon-arrow-down el-icon--right"></i>
											</el-button>
											<el-button type="primary" size="mini" class="clear_more" plain
												@click="handleClickClearCourseIntroductionHtml" v-show="showClearBtn">
												收起更多
												<i class="el-icon-arrow-up el-icon--right"></i>
											</el-button>
										</div>
										<div class="" ref="dgBox">
											<div class="category_title">
												<img src="../assets/book.png" alt=""
													style="width:20px;height:20px;margin-right:10px;transform: translateY(2px);">
												課程大綱
											</div>
											<div class="content">
												<el-collapse v-model="collapseActive" @change="handleChange">
													<el-collapse-item
														:title="'第'+parseInt(index+1)+'章 ' + item.catalogName"
														:name="item.catalogId"
														v-for="(item,index) in courseDetailData.catalogList"
														:key="item.catalogId">
														<ul class="courseware">
															<p v-if="item.resourceList.length<=0"
																style="text-align:center;color:rgb(157, 157, 157);margin-top:25px;">
																暫無課件~</p>
															
															<li v-for="(items,indexs) in item.resourceList"
																:key="items.cataresId" v-else>
																<div id="" v-if="items.cataresCategory == 'VIDEO'">
																	<span class="iconfont icon-shipin"></span>
																	<span class="courseware_type">視頻：</span>
																</div>
																<div id=""
																	v-else-if="items.cataresCategory == 'DOCUMENT'">
																	<span class="iconfont icon-wenjian"></span>
																	<span class="courseware_type">文檔：</span>
																</div>
																<span class="courseware_title">
																	<span
																		class="van-ellipsis cou_tit">{{parseInt(index+1) + '-' + parseInt(indexs+1)}}&nbsp;{{items.cataresName}}<span v-if="items.stuProgress">（学习进度：{{items.stuProgress}}）</span></span>
																	<template v-if="items.cataresCategory == 'VIDEO'">
																		<el-button size="mini" type="primary"
																			style=" margin: 12px;"
																			class="start_learn_btn"
																			@click="handleShowCourseFile(items.cataresPath,(parseInt(index+1) + '-' + parseInt(indexs+1) + items.cataresName),items.cataresCategory,items.cataresId, courseDetailData.course.courseType)">
																			學習
																		</el-button>
																	</template>
																	<template
																		v-if="items.cataresCategory == 'DOCUMENT'">
																		<el-button size="mini" style=" margin: 12px;"
																			class="start_learn_btn"
																			@click="handleShowCourseFile(items.cataresPath,(parseInt(index+1) + '-' + parseInt(indexs+1) + items.cataresName),items.cataresCategory,items.cataresId, courseDetailData.course.courseType)">
																			下載
																		</el-button>
																		<!-- <el-button size="mini" type="primary"
																			style=" margin: 12px;"
																			class="start_learn_btn">
																			预览
																		</el-button> -->
																	</template>
																</span>
															</li>
														</ul>
													</el-collapse-item>
												</el-collapse>
											</div>
										</div>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
						<!-- 主讲人+ 相关课程 -->
						<div class="main_teacher">
							<div class="main_people">
								<div class="panel_title">主講人</div>
								<div class="panel_content">
									<div class="peo_info">
										<div class="main_peo_avatar">
											<img src="../assets/tou1.jpg"
												alt="">
										</div>
										<div class="people_info">
											<p class="names">{{courseDetailData.course.courseAuthor}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 主讲人+ 相关课程 -->
					</div>
				</div>
				<!-- 课件弹框 -->
				<el-dialog top="0" :title="dialogTitle" @closed="closeVideoDialog" :visible.sync="dialogVisible" width="1000px"
					:before-close="handleClose" center>
					<div id="courseFileWrap" style="width:100%;height:100%"></div>
				</el-dialog>
			</div>
			<bottom-bar></bottom-bar>
		</div>
		<!-- 移动端 -->
		<div class="hidden-lg-and-up phone_padding" v-if="courseDetailData.course">
			<phoneft-header :phoneCurrentId="99" />
			<!--<div class="xs_bread_nav">
				<ul>
					<li>
						<router-link :to="'/home/' + this.$store.state.oid">首页</router-link>
						<i>/</i>
					</li>
					<li>
						<router-link to="/kechengguangchang">课程广场</router-link>
						<i>/</i>
					</li>
					<li style="flex:1;overflow:hidden">
						<a href="javascript:;" style="cursor: default;width:100%;display: block;" class="van-ellipsis">
							{{courseDetailData.course.courseName}}
						</a>
					</li>
				</ul>
			</div>-->
			<div class="xs_class_detail_wrapper">
				<div class="xs_introduction_box">
					<!-- 课程视频||图片区域 -->
					<div class="xs_video_or_img">
						<template v-if="courseDetailData.course.courseVideoUrl == null">
							<div class="xs_hasnt_video_wrap">
								<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + courseDetailData.course.courseCoverUrl"
									:onerror="defaultCourseCoverImg" alt="">
							</div>
						</template>
						<template v-else>
							<div class="xs_has_video_wrap"  id="xsIntroPlayer">
								<div v-show="xsShowVideo==false" style="width:100%;height:100%">
									<div class="xs_stop_btn" @click="handleClickXsShowVideo">
										<span>▶</span>
									</div>
									<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + courseDetailData.course.courseCoverUrl"
										:onerror="defaultCourseCoverImg" alt="">
								</div>
								<d-player ref="xsIntroPlayer" :options="xsIntroPlayerOptions" v-show="xsShowVideo"
										style="width:100%;height:100%"></d-player>
							</div>
						</template>
					</div>
					<!-- 课程介绍 -->
					<div class="xs_project_intro">
						<h2 class="xs_tro_title">{{courseDetailData.course.courseName}}</h2>
						<div class="xs_tro_content">
							<ul class="list-1">
								<li>
									<i class="cont_name">課程評分：</i>
									<div style="display:inline-block;">
										<ul class="xs_star_wrap">
											<li class="xs_star" v-for="item in 5">★</li>
										</ul>
										<span style="color: #a3abcc;font-size: 12px;"> (5分)</span>
									</div>
								</li>

								<li>
									<i class="cont_name">開課時間：2020.01.01－2021.12.31</i>
								</li>
							</ul>
							<ul class="list-2">
								<li>
									<i class="cont_name">主講人：{{courseDetailData.course.courseAuthor}}</i>
								</li>
							</ul>
						</div>
					</div>
					<div class="xs_tab_container" :style="{'padding-bottom': xsTabActive==0?'40px':''}">
						<van-tabs v-model="xsTabActive">
							<van-tab title="介紹">
								<div class="xs_tab_content">
									<div class="xs_pane">
										<div class="xs_pane_title">
											主講人
										</div>
										<div class="xs_pane_content">
											<div class="xs_main_people_wrap">
												<div class="xs_main_people_left">
													<img src="../assets/tou1.jpg"
														alt="">
												</div>
												<div class="xs_main_people_right">
													<div class="xs_main_people_name van-ellipsis">
														{{courseDetailData.course.courseAuthor}}
													</div>
													<!-- <div class="xs_main_people_desc van-multi-ellipsis--l2">
														况秀猛，国际著名微电子封装专家，美国企业家，美国圣地亚哥最受尊重的CEO之一，美国亚马逊畅销书作者。改革开放以来出国留学生中第一位荣登美国白宫获奖的华人。

													</div> -->
													<!-- <div class="xs_main_people_zw van-ellipsis">
														总裁
													</div> -->
												</div>
											</div>
										</div>
									</div>
									<div style="margin-top:10px;padding:.4rem 0 0;">
										<div class="xs_pane">
											<div class="xs_pane_title">
												課程簡介
											</div>
											<div class="xs_pane_content">
												<span v-html="courseDetailData.course.courseAbstract"></span>
											</div>
										</div>
										<div class="xs_pane">
											<div class="xs_pane_title">
												課程概述
											</div>
											<div class="xs_pane_content">
												<div class="xs_course_intro_wrap" ref="xsCourseIntroduction" :style="{'height':xsMore?'auto':'200px','padding-bottom':xsPaddingBottom?'50px':''}">
													<input type="text" id="read-more">
													<label for="remode-more" v-show="xsLabelShadow"></label>
													<div class="xs_course_intro"
														v-html="courseDetailData.course.courseIntroduction">
													</div>
													<van-button icon="arrow-down" type="info" class="xs_show_more" size="mini" plain hairline v-show="showXsShowMore" @click="handleClickShowXsMore">
														展開
													</van-button>
													<van-button icon="arrow-up" type="info" class="xs_clear_more" size="mini" v-show="showXsClearMore" plain hairline @click="handleClickClearXsMore">
														收起
													</van-button>
												</div>
											</div>
										</div>
									</div>
									<!-- <div style="margin-top:10px;padding:.4rem 0 0;">
										<div class="xs_pane">
											<div class="xs_pane_title">相关课程</div>
											<div class="xs_pane_content" style="display:flex;flex-wrap:wrap;justify-content: space-between;">
												<div class="ohter_class_wrap" v-for="item in 5">
													<div class="ohter_left_cover_img">
														<img src="https://gxbfile-gs.gaoxiaobang.com/uploads/course_image/link/d7b03e85239341638dd7fffd19c6a17b.png"
														alt="">
													</div>
													<p class="class_title van-ellipsis">
															 高数不挂科-3小时学完高等数学/微积分·下 
													</p>
												</div>
											</div>
										</div>
									</div> -->
								</div>
							</van-tab>
							<van-tab title="大綱">
								<div class="xs_tab_content">
										<div class="xs_pane">
												<van-collapse v-model="activeNames">
												<van-collapse-item :title="'第'+parseInt(index+1)+'章 ' + item.catalogName" :name="item.catalogId"
														v-for="(item,index) in courseDetailData.catalogList"
														:key="item.catalogId">
														<div class="xs_coll_content_wrap">
															<p v-if="item.resourceList.length<=0"
															style="text-align:center;color:rgb(157, 157, 157);">
															暫無課件~</p>
															<div class="jie_item" v-for="(items,indexs) in item.resourceList" :key="items.cataresId" v-else>
																<template
																		v-if="items.cataresCategory == 'DOCUMENT'">
																		<span class="iconfont icon-wenjian xs_iconf"></span>
																</template>
																<template
																		v-else>
																		<span class="iconfont icon-shipin xs_iconf"></span>
																</template>
																
																<span class="jie_item_title van-ellipsis">{{parseInt(index+1) + '-' + parseInt(indexs+1)}}&nbsp;{{items.cataresName}}</span>
																<template
																		v-if="items.cataresCategory == 'DOCUMENT'">
																		<van-button type="default" size="small" @click="handleShowXsCourseFile(items.cataresPath,(parseInt(index+1) + '-' + parseInt(indexs+1) + items.cataresName),items.cataresCategory,courseDetailData.course.courseType)">下載</van-button>
																</template>
																<template v-else>
																	<van-button type="info" size="small" @click="handleShowXsCourseFile(items.cataresPath,(parseInt(index+1) + '-' + parseInt(indexs+1) + items.cataresName),items.cataresCategory,courseDetailData.course.courseType)">學習</van-button>
																</template>			
															</div>
														</div>
												</van-collapse-item>
												</van-collapse>
										</div>
								</div>
							</van-tab>
							<!-- <van-tab title="评价">
								<div class="xs_tab_content">3</div>
							</van-tab> -->
						</van-tabs>
						<van-button type="info" block style="position: fixed;bottom: 0;" size="large" @click="handleClickPhoneToStudy" v-show="xsTabActive==0">開始學習</van-button>
					</div>
				</div>
			</div>
			<!-- 视频弹出层 -->
			<el-dialog
				class="xs_dialog"
				:title="xsStudyPopupTitle"
				:visible.sync="xsShowStudyPopup"
				width="100vw"
				center>
					<div class="study_video_wrap"  style="width:100%;height:100%" id="xsStudyPlayers">
									<d-player ref="xsStudyPlayer" :options="xsStudyPlayerOptions"
									style="width:100%;height:100%"></d-player>
					</div>
			</el-dialog>							
		</div>
	</div>
</template>

<script>
	// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	import navBar from "@/components/navftBar";
	import bottomBar from "@/components/bottomftBar";
	import $ from 'jquery' //在需要使用的页面中
	import { isWeiXin, isMobile } from '../utils/userAgent.js'
	import DPlayer from 'dplayer'
	export default {
		name: "kecheng_detail",
		components: {
			navBar,
			bottomBar
		},
		// 定义属性
		data() {
			return {
				// 移动端start
				// 学习视频弹出层
				xsShowStudyPopup: false,
				// 移动端学习弹出层title
				xsStudyPopupTitle: '',
				// 移动端简介视频配置			
				xsIntroPlayerOptions: {
					container: document.getElementById("xsIntroPlayer"), //播放器容器
					mutex: true, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
					theme: "#409EFF", // 风格颜色，例如播放条，音量条的颜色
					loop: false, // 是否自动循环
					lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
					//screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
					hotkey: true, // 是否支持热键，调节音量，播放，暂停等
					preload: "auto", // 自动预加载
					volume: 0.7, // 初始化音量
					// playbackSpeed: [1.25,0.75,2], //可选的播放速度，可自定义
					//logo:"http://www.cxcyedu.com/fs/download?storeKey=LOCAL:0defd2798d3543abbb499250bb4675b0", // 在视频左角上打一个logo
					video: {
						url: "", // 播放视频的路径
						pic: "" // 视频封面图片
					},
				},
				// 学习视频配置
				xsStudyPlayerOptions: {
					container: document.getElementById("xsStudyPlayers"), //播放器容器
					mutex: true, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
					theme: "#409EFF", // 风格颜色，例如播放条，音量条的颜色
					loop: false, // 是否自动循环
					lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
					//screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
					hotkey: true, // 是否支持热键，调节音量，播放，暂停等
					preload: "auto", // 自动预加载
					volume: 0.7, // 初始化音量
					// playbackSpeed: [1.25,0.75,2], //可选的播放速度，可自定义
					//logo:"http://www.cxcyedu.com/fs/download?storeKey=LOCAL:0defd2798d3543abbb499250bb4675b0", // 在视频左角上打一个logo
					video: {
						url: "", // 播放视频的路径
						pic: "" // 视频封面图片
					},
				},
				xsShowVideo: false,
				xsTabActive: 0,
				xsMore: true,
				xsLabelShadow: false,
				xsPaddingBottom: false,
				// 展开
				showXsShowMore: false,
				// 收起
				showXsClearMore: false,
				// 移动端大纲折叠面板默认选中
				activeNames: [],
				// 移动端end
				activeName: "first",
				courseDetailData: {},
				defaultCourseCoverImg: 'this.src="' + require("../assets/image404.gif") + '"',
				collapseActive: [],
				// 课程评价总评星级
				totalSart: 2.5,
				colors: ["#efaa1f", "#efaa1f", "#efaa1f"],
				texts: [
					"有点小失望哦",
					"还有努力的空间，继续加油吧！",
					"很棒!做的还不错",
					"超出预期的精彩",
					"实在是太优秀了吧，课程非常非常不错"
				],
				// 我的星级评价
				myCommentSart: 5,
				ruleForm: {
					desc: ""
				},
				rules: {
					desc: [{
						required: true,
						message: "请填写评论内容",
						trigger: "blur"
					}]
				},
				// 评论列表用户评价星级
				commentUserStar: 5,
				showVideo: false,
				options: {
					container: document.getElementById("videoContainer"), //播放器容器
					mutex: true, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
					theme: "#409EFF", // 风格颜色，例如播放条，音量条的颜色
					loop: false, // 是否自动循环
					lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
					//screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
					hotkey: true, // 是否支持热键，调节音量，播放，暂停等
					preload: "auto", // 自动预加载
					volume: 0.7, // 初始化音量
					playbackSpeed: [0.5, 1, 1.25, 1.5, 2], //可选的播放速度，可自定义
					//logo:"http://www.cxcyedu.com/fs/download?storeKey=LOCAL:0defd2798d3543abbb499250bb4675b0", // 在视频左角上打一个logo
					video: {
						url: "", // 播放视频的路径
						quality: [
							// 设置多个质量的视频
							{
								name: "高清",
								url: "",
								type: "auto" // 'auto', 'hls', 'flv', 'dash', 'webtorrent', 'normal' 或 其他自定义类型
							}
							// {
							// name: "标清",
							// url:
							// 	"https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
							// type: "auto",
							// },
						],
						defaultQuality: 0, // 默认是高清
						pic: "" // 视频封面图片
						// thumbnails: "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606462956126&di=2d87964d4faf656af55d09d938640d97&imgtype=0&src=http%3A%2F%2Fattachments.gfan.com%2Fforum%2Fattachments2%2F201310%2F10%2F150326y7dzdd8d4kpjjdsd.jpg", // 进度条上的缩略图,需要通过dplayer-thumbnails来生成
					},
					subtitle: {
						//字幕
						url: "", //字幕网址
						color: "#fff", // 字幕颜色
						fontSize: "20px",
						bottom: "40px"
					}
					// contextmenu: [
					// //  自定义上下文菜单
					// // 右键菜单
					// {
					// 	text: "custom1",
					// 	link: "https://www.bilibili.com",
					// },
					// {
					// 	text: "custom2",
					// 	click: (player) => {
					// 	console.log(player);
					// 	},
					// },
					// ],
				},
				// 是否展开全部
				more: true,
				paddingBottom: false,
				// 展开全部按钮
				showAllBtn: false,
				// 是否展示收起遮罩白层
				labelShadow: false,
				// 是否显示收起更多按钮
				showClearBtn: false,
				// 控制课件弹框的显隐
				dialogVisible: false,
				// 课件弹框title
				dialogTitle: "",
				cataresId: 0,
				lookdialogVisible: true,
				currentTime: 0,
				timer: null,
				dp: null,
			};
		},
		// 计算属性，会监听依赖属性值随之变化
		computed: {},
		// 监控data中的数据变化
		watch: {},
		// 方法集合
		methods: {
			/**
			 * 移动端学习弹出课件
			 */
			handleShowXsCourseFile(cataresPath, title, cataresCategory, courseType){
				if(courseType==1){
					if(!this.$store.state.userInfo.student){
						this.$dialog.confirm({
						title: '提示',
						message: '觀看私有課程需要登入，是否前往登入?',
						confirmButtonText: '是',
						cancelButtonText: '否'
					}).then(() => {
							//点击确定的操作(调用接口)
							this.$router.push({name: 'ftlogin',query:{redirect: '/kechengft_detail?courseId='+this.courseId}})
						}).catch(() => {
							//点取消的提示
							return;
						});
						return;
					}
				}

				if (cataresCategory == "DOCUMENT") {
					let a = document.createElement("a");
					let url =
						this.$store.state.baseHost + "/fs/download?storeKey=" + cataresPath;
					a.setAttribute("href", url);
					a.setAttribute("target", "_blank");
					a.setAttribute("id", "openwin");
					a.setAttribute("download", title);
					document.body.appendChild(a);
					a.click();
				} else if (cataresCategory == "VIDEO") {
					this.xsShowStudyPopup = true
					this.xsStudyPopupTitle = title;
					this.$nextTick(() => {
						setTimeout(() => {
							// this.$refs.xsIntroPlayer.dp.pause();
							// console.log(this.$refs.filePlayers.dp);
							this.$refs.xsStudyPlayer.dp.switchVideo({
								url: this.$store.state.baseHost +
									"/fs/download?storeKey=" +
									cataresPath
							});
							//console.log("我执行了");
							this.$refs.xsStudyPlayer.dp.play();
						}, 0);
					});
				}
			},
			/**
			 * 移动端简介视频点击播放
			 */
			handleClickXsShowVideo(){
				this.xsShowVideo = true
				this.$refs.xsIntroPlayer.dp.play();
				// 监听视频播放完毕
				let that = this
				this.$refs.xsIntroPlayer.dp.on('ended', function() {
					//console.log('player ended');
					that.$refs.xsIntroPlayer.dp.fullScreen.cancel();
					that.xsShowVideo = false
				});
			},
			/**
			 * 移动端开始学习 
			 */
			handleClickPhoneToStudy(){
				this.xsTabActive = 1
			},
			/**
			 * 移动端点击展开
			 */
			handleClickShowXsMore(){
				this.xsMore = true;
				this.showXsShowMore = false;
				this.xsLabelShadow = false;
				// 显示收起
				this.showXsClearMore = true;
				this.xsPaddingBottom = true
			},
			/**
			 * 移动端点击收起 
			 */
			handleClickClearXsMore(){
				this.xsMore = false;
				this.showXsShowMore = true;
				this.xsLabelShadow = true;
				this.showXsClearMore = false;
				this.xsPaddingBottom = false;
			},
			/**
			 * 开始学习
			 */
			handleClickStartStudy() {
				if (this.activeName != 'first') {
					this.activeName = 'first'
					this.$nextTick(() => {
						//console.log(this.$refs.dgBox.getBoundingClientRect().top);
						$('body,html').animate({
							scrollTop: this.$refs.dgBox.getBoundingClientRect().top
						}, 500);
					});
					return;
				}
				this.$nextTick(() => {
					//console.log(this.$refs.dgBox.getBoundingClientRect().top);
					$('body,html').animate({
						scrollTop: this.$refs.dgBox.getBoundingClientRect().top
					}, 500);
				});

			},
			/**
			 * 展开全部
			 */
			handleClickShowAllCourseIntroductionHtml() {
				this.more = true;
				this.showAllBtn = false;
				this.labelShadow = false;
				// 显示收起
				this.showClearBtn = true;
				this.paddingBottom = true
			},
			/**
			 * 收起更多
			 */
			handleClickClearCourseIntroductionHtml() {
				this.more = false;
				this.showAllBtn = true;
				this.labelShadow = true;
				this.showClearBtn = false;
				this.paddingBottom = false;
			},
			/**
			 * 学习
			 *  @params {cataresPath} 课件地址
			 *  @param {title} 课件标题
			 *  @param {cataresCategory} 课件课件分类
			 *  @param {cataresId} 课件id
			 *
			 */
			handleShowCourseFile(cataresPath, title, cataresCategory, cataresId, courseType) {
				this.cataresId = cataresId;

				if(courseType==1){
					if(!this.$store.state.userInfo.student){
						this.$confirm('觀看私有課程需要登入，是否前往登入?', '提示', {
							confirmButtonText: '是',
							cancelButtonText: '否',
							type: 'warning'
						}).then(() => {
							//点击确定的操作(调用接口)
							this.$router.push({name: 'ftlogin',query:{redirect: '/kechengft_detail?courseId='+this.courseId}})
						}).catch(() => {
							//点取消的提示
							return;
						});
						return;
					}
				}

				if(cataresCategory == "DOCUMENT"){
					let a = document.createElement("a");
					let url =
						this.$store.state.baseHost + "/fs/download?storeKey=" + cataresPath;
					a.setAttribute("href", url);
					a.setAttribute("target", "_blank");
					a.setAttribute("id", "openwin");
					a.setAttribute("download", title);
					document.body.appendChild(a);
					a.click();
					if(courseType==1 && this.$store.state.userInfo!=null && this.$store.state.userInfo.student.studentId!=null){
						//获得学分
						this.$post("/api/course/addCredit",{studentId:this.$store.state.userInfo.student.studentId,cataresId:cataresId});
						//保存学生在学课程
						this.$post("/api/course/saveOngoing",{studentId:this.$store.state.userInfo.student.studentId,courseId:this.$route.query.courseId,cataresId:cataresId});
					}
				} else if (cataresCategory == "VIDEO") {
					this.dialogVisible = true;
					this.dialogTitle = title;
					this.$nextTick(() => {
						setTimeout(() => {
							var that = this;
							if(this.dp!=null){
								this.dp.destroy();
							}
							this.dp = new DPlayer({
								container: document.getElementById("courseFileWrap"), //播放器容器
								mutex: true, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
								theme: "#409EFF", // 风格颜色，例如播放条，音量条的颜色
								loop: false, // 是否自动循环
								lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
								screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
								hotkey: true, // 是否支持热键，调节音量，播放，暂停等
								preload: "auto", // 自动预加载
								volume: 0.7, // 初始化音量
								playbackSpeed: [0.5, 1, 1.25, 1.5, 2], //可选的播放速度，可自定义
								// autoplay: true,
								// logo:
								// "https://qczh-1252727916.cos.ap-nanjing.myqcloud.com/pic/273658f508d04d488414fd2b84c9f923.png", // 在视频左角上打一个logo
								video: {
									url: this.$store.state.baseHost + "/fs/download?storeKey=" + cataresPath, 
									pic: "" // 视频封面图片
									// thumbnails: "https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606462956126&di=2d87964d4faf656af55d09d938640d97&imgtype=0&src=http%3A%2F%2Fattachments.gfan.com%2Fforum%2Fattachments2%2F201310%2F10%2F150326y7dzdd8d4kpjjdsd.jpg", // 进度条上的缩略图,需要通过dplayer-thumbnails来生成
								},
								subtitle: {
									//字幕
									url: "", //字幕网址
									color: "#fff", // 字幕颜色
									fontSize: "20px",
									bottom: "40px"
								},
								contextmenu: [],
							});
							this.dp.play();
							this.lookdialogVisible = true;
							this.dp.on('play', function () {
								//禁用视频播放上下文菜单
								document.querySelector("#courseFileWrap").oncontextmenu = () => {
									document.querySelector("#courseFileWrap").querySelector(".dplayer-menu").style.display = "none";
									document.querySelector("#courseFileWrap").querySelector(".dplayer-mask").style.display = "none";
									return false;
								};
								if(courseType==1 && that.$store.state.userInfo!=null && that.$store.state.userInfo.student.studentId!=null){
									if(that.timer==null){
										//每15秒保存一次观看进度
										that.timer = setInterval(that.realTimeSave, 15000);
									}
								}
							});
							
							//私有课程记录观看视频进度
							if(courseType==1 && this.$store.state.userInfo!=null && this.$store.state.userInfo.student.studentId!=null){
								this.timer = setInterval(this.realTimeSave, 15000);
								this.dp.on('ended', function () {
									//清除保存进度定时器
									if(that.timer){
										clearInterval(that.timer);
										that.timer = null;
										that.realTimeSave(); //保存进度
									}
									if(that.lookdialogVisible){
										that.$message.success('播放完毕');
										that.$post("/api/course/addCredit",{studentId:that.$store.state.userInfo.student.studentId,cataresId:that.cataresId});
									}
								});
								//视频暂停触发事件
								this.dp.on('pause', function () {
									//清除保存进度定时器
									if(that.timer){
										clearInterval(that.timer);
										that.timer = null;
										that.realTimeSave(); //保存进度
									}
								});
								this.$post("/api/course/getStuProgress",{studentId:this.$store.state.userInfo.student.studentId,cataresId:this.cataresId}).then((res) => {
									var ongoingFirst = 1;
									var ongoingProgress = '';
									if(res.result){
										var ongoing = res.resultData.ongoing;
										var catalog = res.resultData.catalog;
										ongoingFirst = ongoing.ongoingFirst;
										ongoingProgress = ongoing.ongoingProgress;
										if(ongoing.ongoingCurrent > 0 && ongoing.ongoingCurrent != catalog.cataresDuration){
											this.currentTime = ongoing.ongoingCurrent;
											this.dp.seek(this.currentTime);
										}
									}
									//视频播放中触发事件
									this.dp.on('timeupdate', function () {
										if(that.lookdialogVisible){
											if(that.dp.video.currentTime - that.currentTime > 1 && ongoingFirst==1 && ongoingProgress!='100%'){
												that.$message.error("首次观看不允许快进！");
												that.dp.seek(that.currentTime);
											}
											that.currentTime = that.dp.video.currentTime;
										}		
									});
								})
							}
						}, 0);
					});
				}
			},
			getVideoCurrentTime(){
			 	return this.$refs.filePlayers.dp.video.currentTime;
			},
			handleClose(done) {
				// this.$refs.filePlayer.dp.pause();
				done();
			},
			handleClick(e) {
				//console.log("click", e);
			},
			titleClick(e) {
				//console.log("titleClick", e);
			},
			handleChange() {},
			test() {
				// console.log(this.$refs.player.dp.play());
				this.showVideo = true;
				this.$refs.player.dp.play();
				// 监听视频播放完毕
				let that = this
				this.$refs.player.dp.on('ended', function() {
					//console.log('player ended');
					that.$refs.player.dp.fullScreen.cancel();
					that.showVideo = false
				});
			},
			imgTagAddStyle(htmlstr) {
				// 正则匹配所有img标签
				// var regex0 = new RegExp("(i?)(\<img)([^\>]+\>)","gmi");
				// 正则匹配不含style="" 或 style='' 的img标签
				// eslint-disable-next-line no-useless-escape
				var regex1 = new RegExp(
					"(i?)(<img)(?!(.*?style=['\"](.*)['\"])[^>]+>)",
					"gmi"
				);
				// 给不含style="" 或 style='' 的img标签加上style=""
				htmlstr = htmlstr.replace(regex1, '$2 style=""$3');
				// console.log('增加style=""后的html字符串：' + htmlstr)
				// 正则匹配含有style的img标签
				// eslint-disable-next-line no-useless-escape
				var regex2 = new RegExp("(i?)(<img.*?style=['\"])([^>]+>)", "gmi");
				// 在img标签的style里面增加css样式(这里增加的样式：display:block;max-width:100%;height:auto;border:5px solid red;)
				htmlstr = htmlstr.replace(regex2, "$2max-width:100%;height:auto;$3");
				// console.log('在img标签的style里面增加样式后的html字符串：' + htmlstr)
				return htmlstr;
			},
			async getCourseDetail() {
				if (this.$route.query.courseId) {
					//console.log("课程Id" + this.$route.query.courseId);
					let courseId = this.$route.query.courseId;
					let studentId = null;
					if(this.$store.state.userInfo!=null && this.$store.state.userInfo!= '' && this.$store.state.userInfo.student.studentId!=null){
						studentId = this.$store.state.userInfo.student.studentId;
					}
					try {
						// http://127.0.0.1:8098/website/paging/course
						const res = await this.$post("/website/getCourse", {
							courseId:courseId,
							studentId: studentId
						});
						//console.log(res);
						this.courseDetailData = res.resultData;
						 if(isWeiXin() || isMobile()){ 
							 	this.xsIntroPlayerOptions.video.url =
									this.$store.state.baseHost +
									"/fs/download?storeKey=" +
									res.resultData.course.courseVideoUrl;
						 } else {
							 	// 视频封面
								this.options.video.pic =
									this.$store.state.baseHost +
									"/fs/download?storeKey=" +
									res.resultData.course.courseCoverUrl;
								// 视频地址 👇
								this.options.video.url =
									this.$store.state.baseHost +
									"/fs/download?storeKey=" +
									res.resultData.course.courseVideoUrl;
								// 测试ios网络视频地址 👍
								// this.options.video.url = 'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4'
								this.options.video.quality.forEach((item, index) => {
									if (index == 0) {
										item.url =
											this.$store.state.baseHost +
											"/fs/download?storeKey=" +
											res.resultData.course.courseVideoUrl
										// item.url = 'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4'
									}
								});
						 }
						if (this.courseDetailData.course.courseIntroduction != null) {
							let imgTagHtml = this.imgTagAddStyle(
								this.courseDetailData.course.courseIntroduction
							);
							// console.log(imgTagHtml);
							let imgSrcReg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
							let aHrefReg = /<a([\s]+|[\s]+[^<>]+[\s]+)href=(\"([^<>"\']*)\"|\'([^<>"\']*)\')[^<>]*>/gi;
							let imgArr = imgTagHtml.match(imgSrcReg); // 取到所有img标签 放到数组 s里面
							let as = imgTagHtml.toLowerCase().match(aHrefReg); // a 链接
							if (imgArr == null || imgArr == "") {
								this.courseDetailData.course.courseIntroduction = imgTagHtml;
							} else {
								for (var i = 0; i < imgArr.length; i++) {
									var srcImg = imgArr[i].replace(imgSrcReg, "$1"); //取src面的内容
									if (
										srcImg.slice(0, 4) == "http" ||
										srcImg.slice(0, 5) == "https"
									) {
										//若src前4位置或者前5位是http、https则不做任何修改
										//console.log("不做任何修改");
										this.courseDetailData.course.courseIntroduction = imgTagHtml;
									} else {
										//修改富文本字符串内容 img标签src 相对路径改为绝对路径
										imgTagHtml = imgTagHtml.replace(
											new RegExp(srcImg, "g"),
											this.$store.state.baseHost + "/" + srcImg
										);
									}
								}
							}
							if (as == null) {
								this.courseDetailData.course.courseIntroduction = imgTagHtml;
							} else {
								for (var i = 0; i < as.length; i++) {
									var ss = as[i].toLowerCase().match(aHrefReg);
									// console.log(RegExp.$3 + RegExp.$4);
									var href = RegExp.$3 + RegExp.$4;
									var str = href.substr(href.indexOf(":") + 3);
									// console.log(href);
									if (str.slice(0, 3) == "www" ||  href.slice(0, 4) == 'http' || href.slice(0, 5) == 'https') {
										//若src前4位置或者前5位是http、https则不做任何修改
										//console.log("不做任何修改");
										this.courseDetailData.course.courseIntroduction = imgTagHtml;
									} else {
										imgTagHtml = imgTagHtml.replace(
											new RegExp(href, "g"),
											this.$store.state.baseHost + "/" + str
										);
										this.courseDetailData.course.courseIntroduction = imgTagHtml;
									}
								}
							}
						}
						this.$nextTick(() => {
							if(isWeiXin() || isMobile()){
								let xsh = this.$refs.xsCourseIntroduction.offsetHeight;
								if(xsh>150){
										this.handleClickClearXsMore()
								}
							} else {
								let h = this.$refs.courseIntroduction.offsetHeight;
								//  && res.resultData.catalogList.length >= 2
								if (h > 250) { //显示展开更多
									//console.log('进来了');
									this.handleClickClearCourseIntroductionHtml()
									// this.offsetHeight = false
								} else {
									// this.handleClickShowAllCourseIntroductionHtml()
								}
							}
							
						});

						res.resultData.catalogList.forEach(item => {
							this.collapseActive.push(item.catalogId);
							this.activeNames.push(item.catalogId);
						});
						//console.log(this.collapseActive);
					} catch (err) {
						console.error(err);
					} finally {}
				} else {
					this.$message.error("缺少课程id");
				}
			},
			closeVideoDialog (){ //视频关闭
				this.lookdialogVisible = false;
				if(this.timer){
					clearInterval(this.timer);
					this.timer = null;
					this.realTimeSave();
				}
				this.getCourseDetail();
			},
			realTimeSave() {
				this.$post("/api/course/saveStuProgress",{studentId:this.$store.state.userInfo.student.studentId,cataresId:this.cataresId,secord:parseInt(this.currentTime)});
			},
			handleClickTab() {}
		},
		// 生命周期 - 创建完成（可以访问当前this实例）
		created() {
				this.getCourseDetail();
		},
		// 生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			// 取消视频插件右键上下文
			// if (this.showVideo) {
			// this.$nextTick(() => {
			// 	setTimeout(() => {
			// 		document.querySelector("#filePlayer").oncontextmenu = () => {
			// 			document.querySelector(".dplayer-menu-show").style.display = "none";
			// 			document.querySelector(".dplayer-mask").style.display = "none";
			// 			return false;
			// 		};
			// 	}, 20);
			// });
			// }
		},
		beforeCreate() {}, // 生命周期 - 创建之前
		beforeMount() {}, // 生命周期 - 挂载之前
		beforeUpdate() {}, // 生命周期 - 更新之前
		updated() {}, // 生命周期 - 更新之后
		beforeDestroy() {}, // 生命周期 - 销毁之前
		destroyed() {
			if(this.timer){
				clearInterval(this.timer);
				this.realTimeSave();
			}
		}, // 生命周期 - 销毁完成
		activated() {} // 如果页面有keep-alive缓存功能，这个函数会触发
	};

	
</script>

<style lang='less' scoped>
	.w1200 {
		width: 1200px;
		//   height: 100%;
		margin: 0 auto;
	}

	.pc_course_detail_wrapper {
		.zl_detail {
			width: 100%;
			height: 360px;
			background: #212539;
			margin-bottom: 30px;

			.crumbs {
				padding-top: 30px;

				li {
					float: left;
					font-size: 12px;
					color: #b1b1b1;

					&:last-child {
						width: 900px;
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					a {
						font-size: 12px;
						color: #b1b1b1;
						padding-left: 6px;
						padding-right: 6px;
					}
				}
			}

			.course_data {
				display: flex;
				width: 100%;

				.course_left {
					overflow: hidden;
					flex: 1;

					.cq_title {
						font-size: 36px;
						color: #fff;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-weight: 400;
					}

					.zq_courseList {
						li {
							margin-bottom: 10px;
							font-size: 14px;
							color: #a3abcc;

							p {
								display: inline-block;
								margin-left: 5px;
							}

							.mintain {
								display: inline-block;
								margin-left: 5px;

								.rating {
									display: inline-block;

									li {
										display: inline-block;
										padding: 1px;
										text-align: center;
										font-weight: 700;
										cursor: pointer;
										font-family: Tahoma, Helvetica, Arial, "\5b8b\4f53",
											sans-serif;
									}

									.start {
										color: #efaa1f !important;
									}
								}

								.rating-value {
									display: inline-block;
									margin-left: 3px;
								}
							}
						}
					}

					.start_learn {
						margin-top: 20px;

						.inf {
							font-size: 14px;
							color: #666b80;
							margin-left: 24px;
						}
					}
				}

				.course_right {
					width: 400px;
					height: 250px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.stop_pic {
						width: 100%;
						height: 100%;
						position: relative;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						.carton_button {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							filter: none;
							background-color: rgba(0, 0, 0, 0.35);
							display: inline-block;
							width: 100px;
							height: 100px;
							line-height: 100px;
							border-radius: 50%;
							filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr=#76000000, endColorStr=#76000000);
							color: #fff;
							font-size: 48px;
							text-align: center;
							text-decoration: none;
							display: flex;
							align-items: center;
							justify-content: center;
							text-shadow: 0 -1px rgba(0, 0, 0, 0.35);

							span {
								transform: translateX(4px)
							}
						}
					}
				}
			}
		}

		.detail_main {
			margin-bottom: 50px;

			.main_courseilfy {
				display: inline-block;
				width: 885px;
				// width: 1200px;
				height: auto;
				padding: 0 40px;
				background: #fff;
				border-radius: 8px;
				box-sizing: border-box;

				.tabs_wrap {
					padding-top: 30px;

					.category_title {
						padding: 0 0 10px 0;
						font-size: 18px;
						font-weight: bold;
						color: #333333;
						display: flex;
						align-items: center;
					}

					.edit_html {
						//   height: 200px;
						overflow: hidden;
						position: relative;

						// margin-bottom: 10px !important;
						// padding-bottom: 100px;
						.show_more {
							position: absolute;
							bottom: 10px;
							left: 50%;
							transform: translateX(-50%);
						}

						.clear_more {
							position: absolute;
							bottom: 30px;
							left: 50%;
							transform: translateX(-50%);
						}
					}

					.content {
						margin-bottom: 50px;

						.course_intro {
							height: 100%;
						}

						.catalog_desc {
							margin-top: 2px;
							font-size: 12px;
							color: #545c63;
							line-height: 18px;
						}

						.courseware {
							/* padding-top: 16px; */
							box-sizing: border-box;

							li {
								padding-left: 12px;
								// cursor: pointer;
								transition: all 0.8s;
								display: flex;
								align-items: center;
								box-sizing: border-box;

								&:hover {
									background: #f0f3ff;
									color: #409eff;
								}

								&:hover .iconfont {
									color: #409eff !important;
								}

								/* &:hover .start_learn_btn {
									display: inline-block;
								} */
							}

							.iconfont {
								font-size: 16px;
								color: #1c1f21;
								line-height: 48px;
								margin-right: 8px;
								transition: all 0.8s;
							}

							.courseware_type {
								font-weight: 700;
								font-size: 14px;
								margin-right: 4px;
								min-width: 45px;
							}

							.courseware_title {
								display: flex;
								justify-content: space-between;
								flex: 1;
								align-items: center;

								.cou_tit {
									flex: 1;
									width: 1px;
								}
							}
						}
					}

					.course_desc {
						background-color: #f5f8f4;
						padding: 30px 30px 20px;
						margin-bottom: 48px;
						color: #333333;
						line-height: 22px;
					}
				}
			}

			.main_teacher {
				display: inline-block;
				vertical-align: top;
				margin-left: 30px;
				width: 285px;
				height: auto;
				box-sizing: border-box;

				.panel_title {
					font-size: 18px;
					padding-left: 10px;
					border-left: 4px solid #409eff;
					color: #333;
					margin: 30px 0;
				}

				.panel_content {
					padding: 0 0 30px;

					.main_peo_intro {
						font-size: 14px;
						line-height: 25px;
						color: #7a8a99;
					}

					.peo_info {
						display: flex;
						align-items: center;
						margin-bottom: 20px;

						.main_peo_avatar {
							width: 70px;
							height: 70px;
							border-radius: 50%;
							overflow: hidden;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.people_info {
							flex: 1;
							overflow: hidden;
							color: #475766;
							margin-left: 17px;

							.names {
								overflow-wrap: break-word;
								overflow: hidden;
								text-overflow: ellipsis;
								-webkit-line-clamp: 1;
								-webkit-box-orient: vertical;
								display: -webkit-box;
								font-size: 17px;
								margin-bottom: 10px;
							}

							.zw {
								font-size: 14px;
								color: #525c66;
							}
						}
					}

					.course_item {
						display: flex;
						margin-bottom: 20px;
						cursor: pointer;

						&:last-child {
							margin-bottom: 0;
						}

						&:hover .course_item_name {
							color: #409eff;
						}

						.course_pic {
							width: 102px;
							height: 76px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.course_item_name {
							color: #333;
							font-size: 13px;
							max-height: 34px;
							line-height: 17px;
							white-space: normal;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							transition: color 0.8s;
						}

						.publish {
							font-size: 12px;
							color: #999;
							margin-top: 4px;
						}

						.hot {
							margin-top: 7px;
							font-size: 12px;
							// line-height: 14px;
							color: #999;
						}
					}
				}

				.main_people {
					border-radius: 8px;
					background: #fff;
					padding: 20px 27px;
				}

				.ohter_course {
					border-radius: 8px;
					margin-top: 40px;
					background: #fff;
					padding: 20px 27px;
				}
			}

			.total_sart_wrapper {
				display: flex;
				align-items: center;

				.total {
					// line-height: 64px;
					font-size: 48px;
					// width: 73px;
					color: #efaa1f;
					font-weight: bold;
					margin-right: 15px;
				}

				.total_comment {
					font-size: 13px;
					color: #999999;
					margin-left: 5px;
					margin-top: 10px;
				}
			}

			.my_comment_wrap {
				margin: 50px 0 0;

				.my_comment_total_sart {
					margin-top: 10px;
				}

				.my_comment_textarea_wrap {
					margin-top: 20px;
				}
			}

			.comment_list_wrap {
				padding: 46px 0 60px;

				.comment_list_item {
					display: flex;
					padding-top: 30px;

					.comment_user_avatar {
						width: 40px;
						height: 40px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.comment_body {
						flex: 1;
						margin-left: 17px;
						padding-bottom: 30px;

						.comment_user_info {
							display: flex;
							flex-wrap: wrap;
							margin-bottom: 9px;

							.user_name {
								color: #333333;
								font-size: 13px;
								margin-right: 10px;
								// word-break: break-word;
							}
						}

						.comment_content {
							color: #666666;
							font-size: 14px;
							position: relative;
							line-height: 24px;
							margin-bottom: 10px;
							word-break: break-word;
						}

						.comment_info {
							color: #999999;
							display: flex;
							justify-content: space-between;

							.comment_info_time {
								font-size: 13px;
							}

							.comment_info_action {
								cursor: pointer;
								transition: all 0.8s;

								&:hover {
									color: #409eff;
								}
							}
						}
					}
				}
			}
		}
	}

	.drawer_co_wrapper {
		display: flex;

		.course_menu_wrap {
			/* height: 100vh; */
			overflow: scroll;
			overflow-x: hidden;
			width: 25%;
			height: calc(100vh - 54px);
		}

		.course_file_wrap {
			/* width: 75%; */
			flex: 1;
			height: calc(100vh - 54px);
			background: #1c1f21;
		}
	}

	// 移动端start
	.xs_bread_nav {
		background: #212539;
		padding: .3rem .2rem;

		ul {
			display: flex;

			li {
				display: flex;
				align-items: center;

				a,
				i {
					font-size: .3rem;
					// color: #666b80;
					color: #b1b1b1;
				}

				i {
					display: inline-block;
					margin: 0 .1rem;
				}
			}
		}
	}

	.xs_class_detail_wrapper {
		.xs_introduction_box {
			.xs_video_or_img {
				box-sizing: border-box;
				width: 100%;
				height: 6rem;

				.xs_hasnt_video_wrap {
					width: 100%;
					height: 100%;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.xs_has_video_wrap {
					width: 100%;
					height: 100%;
					position: relative;

					.xs_stop_btn {
						position: absolute;
						top: 50%;
						left: 50%;
						transform:
							translate(-50%, -50%);
						// filter: none;
						background-color: rgba(0, 0, 0, 0.35);
						width: 3rem;
						height: 3rem;
						line-height: 3rem;
						border-radius: 50%;
						filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr=#76000000, endColorStr=#76000000);
						color: #fff;
						text-align: center;
						text-decoration: none;
						display: flex;
						align-items: center;
						justify-items: center;
						// text-shadow: 0 -1px rgba(0, 0, 0, 0.35);

						span {
							width: 100%;
							font-size: 1.5rem;
							// transform: translateX(1px);
						}
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.xs_project_intro {
				border-bottom: 1px solid #D8D8D8;
				padding: 25px 0;
				margin: 0 .2rem;

				.xs_tro_title {
					font-size: .55rem;
					color: #333;
					// line-height: 22px;
					font-weight: 600;
					margin-bottom: 25px;
					padding-left: 5px;
					// text-overflow: ellipsis;
					// white-space: nowrap;
					// overflow: hidden;
				}

				.xs_tro_content {
					display: flex;
					justify-content: space-between;

					.list-1 {
						&>li:first-child {
							margin-bottom: 14px;
						}
					}

					ul {
						li {
							i {
								font-size: 12px;
								color: #888;
								font-style: normal;
							}

							.xs_star_wrap {
								display: inline-block;

								.xs_star {
									display: inline-block;
									padding: 1px;
									text-align: center;
									font-weight: 700;
									font-family: Tahoma, Helvetica, Arial, "\5b8b\4f53", sans-serif;
									color: #efaa1f;
								}
							}
						}
					}
				}
			}

			.xs_tab_container {
				// padding-bottom: 40px;
				.xs_tab_content {
					/* padding-left: 0.64rem;
					padding-right: 0.64rem; */
					/* padding-top: .64rem;
					padding-bottom: .64rem; */
					background-color: #f3f5f7;

					.xs_pane {
						background-color: #fff;
						padding: .4rem;
						border-radius: 8px;
						.xs_pane_title {
							padding-left: 10px;
							border-left: 4px solid #409eff;
							font-size: 16px;
							margin-bottom: 20px;
						}

						.xs_pane_content {
							box-sizing: border-box;
							.xs_main_people_wrap {
								display: flex;
								align-items: center;
								
								.xs_main_people_left {
									width: 2rem;
									height: 2rem;
									border-radius: 50%;
									overflow: hidden;

									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}

								.xs_main_people_right {
									flex: 1;
									overflow: hidden;
									margin-left: 15px;

									.xs_main_people_name {
										font-size: 17px;
										color: #444;
										margin-bottom: 16px;
									}

									/* .xs_main_people_desc {
										font-size: 14px;
										color: #999;
										margin: 5px 0;
									} */

									.xs_main_people_zw {
										font-size: 14px;
										color: #b1b1b1;
									}
								}
							}
							.xs_course_intro_wrap {
								overflow: hidden;
								position: relative;
								.xs_show_more {
										position: absolute;
										bottom: 10px;
										left: 50%;
										transform: translateX(-50%);
								}
								.xs_clear_more {
									position: absolute;
									bottom: 10px;
									left: 50%;
									transform: translateX(-50%);
								}
							}
							.ohter_class_wrap {
								width: 45%;
								margin: 10px 0;
								box-sizing: border-box;
								.ohter_left_cover_img {
									/* width:  4.5rem;
									height: 3rem; */
									height: 3rem;
									width: 100%;
									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
								.class_title {
									margin-top: .2rem;
									font-size: .3rem;
								}
							}
						}
						.xs_coll_content_wrap {
								padding-left: 10px;
								.jie_item {
									display: flex;
									align-items: center;
									margin: 12px 0;
									&:first-child {
										margin-top: 0;
									}
									.xs_iconf {
										font-size: .4rem;
										color: #5e5e5e;
									}
									.jie_item_title {
										flex: 1;
										margin: 0 10px;
										color: #5e5e5e;
										font-size: .4rem;  
									}
									
								}
						}
					}
				}
			}
		}
	}
	.xs_popup_wrap {
		display: flex;
		flex-direction: column;
		height: 100%;
		.study_title {
			text-align: center;
			padding: 15px 30px;
			padding-right: 40px;
		}
	}
	// 移动端end

	#read-more+label {
		display: block;
		position: absolute;
		width: 100%;
		height: 150px;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom,
				rgba(255, 255, 255, 0) 0%,
				#fff 70%);
		pointer-events: none;
	}

	#read-more {
		display: none;
	}

	.detail_main /deep/ .el-tabs__item {
		font-size: 18px;
	}

	.detail_main /deep/ .el-tabs__header {
		margin-bottom: 30px;
	}

	.detail_main /deep/ .el-collapse-item__header {
		font-weight: 700;
	}

	.detail_main /deep/ .el-tabs__nav-wrap::after {
		height: 1px;
	}

	.detail_main /deep/ .el-rate__icon {
		font-size: 25px;
	}

	.detail_main /deep/ .second_rate .el-rate__icon {
		font-size: 20px !important;
	}

	.detail_main /deep/ .third_rate .el-rate__icon {
		font-size: 18px !important;
		margin-right: 0;
	}

	.detail_main /deep/ .el-tabs__header {
		position: sticky;
		top: 0;
		background: #fff;
		z-index: 99;
	}

	.video-player /deep/ .vjs-big-play-button {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		height: 3em;
		width: 3em;
		line-height: 3em;
	}

	.video-player /deep/ .vjs-icon-placeholder:before {
		/* top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); */
		font-size: 2em;
	}

	.video-player /deep/ .video-js .vjs-tech {
		object-fit: fill;
	}

	.video-player /deep/ .vjs-poster {
		background-size: cover;
	}

	.course_file_wrap /deep/ .file_layer {
		height: 100%;
	}

	.el-menu-item.is-active {
		background-color: #1890ff !important;
	}

	.el-menu-item {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.el-menu-item:hover {
		background-color: #1890ff !important;
	}

	.el-dialog__wrapper {
		display: flex;
		align-items: center;
	}

	.pc_course_detail_wrapper /deep/ .el-dialog {
		margin: 0 auto;
	}

	.pc_course_detail_wrapper /deep/ .el-dialog__body {
		padding: 0;
	}
	.xs_dialog /deep/.el-dialog__body {
		padding: 0;
	}
	.xs_dialog /deep/ .el-dialog__title {
		display: inline-block;
		padding: 0 20px;
	}
	.xs_dialog /deep/ .el-dialog__body {
			height: 40vh;
	}
	.pc_course_detail_wrapper /deep/ .el-dialog__title {
		display: inline-block;
		padding: 0 20px;
	}

	.xs_tab_container /deep/ .van-tabs__line {
		background-color: #409EFF;
	}

	.xs_tab_container /deep/ .van-tab--active {
		color: #409EFF;
	}

	.xs_tab_container /deep/ .van-tabs__wrap {		
		overflow: inherit;
	}
	.xs_tab_container /deep/ .van-tabs__nav--line {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		padding-bottom: 0;
	}
	.xs_tab_container /deep/ .van-tabs__line {
		bottom: 0;
	}
	.xs_tab_container /deep/ [class*=van-hairline]::after {
		border: 0;
	}
	// 移动端视频隐藏设置
	#xsIntroPlayer /deep/ .dplayer-setting {
		display: none !important;
	}
	#xsStudyPlayers /deep/ .dplayer-setting {
		display: none !important;
	}
</style>
