<template>
	<div>
		<div class="main" v-if="certifDetailData">
			<div class="banner">
				<img :src="this.$store.state.baseHost+'/fs/download?storeKey='+contestCoverUrl" v-if="contestCoverUrl" :onerror="defaultBannerImg"/>
				<img v-else :src="orgCover?this.$store.state.baseHost+'/fs/download?storeKey='+orgCover:require('../assets/banner.jpg')" :onerror="defaultBannerImg"/>
				<!-- <img src="../assets/banner.jpg" /> -->
			</div>
			<div class="content">
				<div class="sign-btn" @click="signUp(certifDetailData.certifId)">我要申请</div>
				<div class="introduce">
					<div class="introduce-title" id="certifName">证书名称</div>
					<div class="introduce-text">{{certifDetailData.certifName}}</div>
				</div>
				<div class="introduce">
					<div class="introduce-title" id="certifRemark">证书说明</div>
					<div class="introduce-text">{{certifDetailData.certifRemark}}</div>
				</div>
				<div class="introduce">
					<div class="introduce-title">申请须知</div>
					<div class="introduce-text" id="certifCredit">
						<p>所需学分：{{certifDetailData.certifCredit}}</p>
						<p>所需学时：{{certifDetailData.certifClassHour}}（分钟）</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	import navBar from "@/components/navBar";
	export default {
		name: "certif_detail",
		components: {
			navBar
		},
		// 定义属性
		data() {
			return {
				orgCover: null,
				defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
				certifDetailData: {},
				contestCoverUrl: null
			};
		},
		// 计算属性，会监听依赖属性值随之变化
		computed: {},
		// 监控data中的数据变化
		watch: {},
		// 方法集合
		methods: {
			//我要报名按钮
			signUp(certifId){
				if(!this.$store.state.userInfo){
					this.$router.push({name: 'login',query:{redirect: '/Certif_detail?certifId='+certifId}})
					return
				}
				if(!this.$store.state.userInfo.student){
					this.$message.error('只有学生帐号申请');
					return
				}
				this.$post("/api/certif/certifApply",{certifId:certifId, studentId:this.$store.state.userInfo.student.studentId}).then((res)=>{          
					if(res.result){
						this.$message.success(res.resultMsg);
					}else{
						this.$message.error(res.resultMsg);
					}
				}).catch((res)=>{
					console.log(res);
				})
			},
			async getcertifDetail(){
				const res = await this.$post("/website/getCertifDetail", {
					certifId:this.$route.query.certifId
				});
				this.certifDetailData = res.resultData;
			}
		},
		// 生命周期 - 创建完成（可以访问当前this实例）
		created() {
			this.getcertifDetail();
		},
		// 生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
		},
		beforeCreate() {}, // 生命周期 - 创建之前
		beforeMount() {}, // 生命周期 - 挂载之前
		beforeUpdate() {}, // 生命周期 - 更新之前
		updated() {}, // 生命周期 - 更新之后
		beforeDestroy() {}, // 生命周期 - 销毁之前
		destroyed() {
		}, // 生命周期 - 销毁完成
		activated() {} // 如果页面有keep-alive缓存功能，这个函数会触发
	};

	
</script>

<style scoped>
.main{width: 100%;height: auto;}
.sign-btn{
  border: none;
  outline: none;
  width: 300px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  font-size: 24px;
  color: #fff;
  background-color: #fbb712;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin:30px auto;
}

.introduce{width: 1132px;margin: 0 auto;padding-bottom: 20px;}

/* 短标题 */
.introduce .introduce-title{width: 134px; height: 29px;line-height: 27px;color: #fff;font-size:16px;text-indent: 37px;background: url('../assets/newsBg.png') no-repeat;background: contain;margin-bottom: 10px;}
/* 长标题 */
.introduce .introduce-title-long{width: 201px; height: 29px;line-height: 27px;color: #fff;font-size:16px;text-indent: 37px;background: url('../assets/newsBgLong.png') no-repeat;background: contain;margin-bottom: 10px;}

/* 背景介绍文本样式 */
.introduce .introduce-text{width: 1100px;height: auto;line-height: 35px;font-size:14px;color:#333333;text-indent: 32px;margin: 0 auto;}

/* 组织机构文本样式 */
.introduce p{width: 1100px;margin:0 auto;font-size:14px;color:#333333;height: auto;line-height: 35px;text-indent: 28px;}

/* 参赛对象与报名样式 */
.introduce .count-pitem{width: 1100px;height: auto;margin: 0 auto;margin-bottom: 10px;}
.introduce .count-pitem .count{width: 1060px;margin:0 auto;color:#0084e1;font-size:14px;}

.introduce .count-pitem .count span{width: 25px;height: 29px;background:url('../assets/numBg.png') no-repeat;background-size: contain;display: inline-block;color:#fff;text-align: center;line-height: 29px;margin-right: 5px;}

.introduce .count-pitem p{width:1080px;margin: unset;margin-left: 20px;}


</style>
