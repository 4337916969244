<template>
    <div>
      <nav-bar :currentId="99"></nav-bar>
		  <student-info></student-info>
      <div class="uc-wrap">
        <div class="container clearfix">
          <!-- <div class="uc-sidebar">
            <div class="uc-side-hd">
              <h3>用户中心</h3>
              <p>User center</p>
            </div>
            <ul class="uc-navbar">
              <li class="menu active"><router-link to="/student_profile"><i class="iconfont icon-gerenziliao"></i>个人资料</router-link></li>
              <li class="menu"><router-link to="/student_competition"><i class="iconfont icon-saishi"></i>我的赛事</router-link></li>
              <li class="menu"><router-link to="/student_pwd"><i class="iconfont icon-xiugaimima"></i>修改密码</router-link></li>
            </ul>
            <div class="uc-foot">
              <a href="javascript:void(0)" class="btn btn-out" @click="logout()">退出登录</a>
            </div>				   
          </div>	 -->
				  <student-sidebar :currentId="4"></student-sidebar>
          <div class="uc-panel">
            <div class="uc-heading">
              <div class="uc-tit">我的证书</div>
            </div>
            <div class="uc-ongoing" v-if="certifList.length">
              <div class="uc-ongoing-div" v-for="(item,index) in certifList" :key="index" v-on:click="toLink(item.certifId)">
                <img :src="$store.state.baseHost+'/'+item.certifPreview" :onerror="defaultImg" class="ongoing-icon" />
                <div class="ongoing-title">{{item.certifName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import studentInfo from "@/components/studentCenter/studentInfo";
import studentSidebar from "@/components/studentCenter/studentSidebar";

import { VueCropper } from "vue-cropper";
import { isWeiXin, isMobile } from '../utils/userAgent.js';


export default {
    components: {
        navBar,
        bottomBar,
        studentInfo,
		    studentSidebar,
        VueCropper,
    },
    data() {
      return {
        certifList: []
      }
    },
    methods: {
      getMyCertif(){
        if(!this.$store.state.userInfo.student){
          this.$message.error('请使用学生账号登录');
          return
        }
        this.$post("/api/certif/stuCertifList",{studentId:this.$store.state.userInfo.student.studentId}).then((res)=>{          
          if(res.result){
            this.certifList = res.resultData;
          }else{
            this.$message.error(res.resultMsg);
          }
        }).catch((res)=>{
          console.log(res);
        })
      },
      logout(){
			  this.$confirm('是否退出登录?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
        }).then(() => {
        //点击确定的操作(调用接口)
        this.$post("/api/appuser/logout").then((res) => {
          console.log(res);
          if (res.result) {
            this.$store.commit("LOG_OUT");
            this.$router.push("/login/"+this.$store.state.oid);
          }
        });
       }).catch(() => {
        //点取消的提示
        return;
      });
		},
    toLink(certifId) {
			if(isWeiXin() || isMobile()){
        this.$router.push({
          name: "certif_detail",
          query: {
            certifId: certifId
          }
        });
      } else {
        let routeUrl = this.$router.resolve({
          name: "certif_detail",
          path: "/certif_detail",
          query: {
            certifId: certifId
          }
        });
        window.open(routeUrl.href, "_self");
      }
    },
  },
	mounted () {
		this.getMyCertif();
	}
}
</script>

<style lang="less" scoped>
.container{width: 1200px;overflow: hidden;margin: 0 auto;position: relative;z-index: 2;}

.uc-wrap{
	position: relative;
	z-index: 999;
	margin-top: -46px;
	padding-bottom: 80px;
}
.uc-panel{
	float: right;
	width: 930px;
	background-color: #FFFFFF;
}
.uc-heading{
	height: 46px;
	border-bottom: 1px solid #DDDDDD;
	margin-left: 20px;
}
.uc-tit{
	position: relative;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-tit::after{
	height: 1px;
	width: 24px;
	position: absolute;
	background-color: #02354b;
	left: 0;
	bottom: -1px;
	content: ' ';
}
.uc-ongoing{position: relative; display:flex; flex-wrap:wrap; padding: 0 20px;}
.uc-ongoing-div{margin-top: 10px; width:25%; height: 180px; text-align: center;}
.ongoing-icon{width:90%; height: 150px;}
.ongoing-title{width: 100%; height: 20px; text-align: center; font-size: 15px; overflow:hidden; text-overflow:ellipsis; white-space: nowrap;}
</style>