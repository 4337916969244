<template>
    <div>
        <nav-bar :currentId="99"></nav-bar>
        <div class="baoming-wrap">
            <div class="container clearfix">
                <div class="bm-grid clearfix">
                    <div class="bm-intro">
                        <div class="bm-title" id="contestName">{{contestName}}</div>
                        <!-- <div class="bm-en">The Third Guangzhou "Vocational Education Cup" innovatio and Entrepreneurship Competition</div> -->
                        <div class="bm-time">报名时间：{{enrrolTime}}</div>
                    </div>
                    <div class="bm-people"><img src="../assets/people.png" ></div>
                </div>
                <div class="bm-panel">
                    <div class="bm-panel-hd">
                        <div class="bm-panel-tt">报名须知</div>
                    </div>
                    <div class="zx-panel-bd" v-html="contestEnrollRemark"></div>
                </div>
                    <div class="bm-panel">
                        <div class="bm-panel-hd">
                            <div class="bm-panel-tt">基本资料<span class="en">/Basic information</span></div>
                        </div>
                        <div class="bm-panel-bd">
                            <ul class="row bm-ul">
                                <li class="col-xs-5">
                                    <div class="pw-label">项目名称：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control" maxlength="50" v-model="contprojName" name="contprojName" placeholder="请输入项目名称"/>
                                    </div>
                                </li>
                                <li class="col-xs-5 col-xs-offset-2">
                                    <div class="pw-label">项目类别：</div>
                                    <div class="pw-value">
                                        <select class="form-control" @change="selectContprojIndustry($event)" v-model="contprojIndustry">
                                            <option value="">-- 请选择 --</option>
                                            <option v-for="(item,index) in contprojIndustryList" :key="index">{{item.dictValue}}</option>
                                        </select>
                                    </div>
                                </li>
                                <li class="col-xs-5">
                                    <div class="pw-label">参赛组别：</div>
                                    <div class="pw-value">
                                        <select class="form-control" @change="selectContprojGroup($event)" v-model="contprojGroup">
                                            <option value="">-- 请选择 --</option>
                                            <option v-for="(item,index) in contprojGroupList" :key="index">{{item.dictValue}}</option>
                                        </select>
                                    </div>
                                </li>
                                <li class="col-xs-5 col-xs-offset-2" v-if="needContestEnrollAuditmode">
                                    <div class="pw-label">邀请码：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control"  maxlength="50" v-model="contprojInvitecode" name="contprojInvitecode" placeholder="请输入邀请码"/>
                                    </div>
                                </li>
                                <li class="col-xs-12">
                                    <div class="pw-label">项目简介：</div>
                                    <div class="pw-value">
                                        <div class="bm-textarea">
                                            <textarea rows="" cols="" class="bm-textarea" placeholder="请输入项目简介..." v-model="contprojInstruction" name="contprojInstruction"></textarea>
                                            <div class="bm-cout">500字以内</div>
                                        </div>
                                    </div>
                                </li>
                                <li class="col-xs-5">
                                    <div class="pw-label">所属学校：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control" maxlength="50" v-model="contprojOrgName" name="contprojOrgName" placeholder="请输入所属学校"/>
                                    </div>
                                </li>
                                <li class="col-xs-5 col-xs-offset-2">
                                    <div class="pw-label">所属地区：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control" maxlength="50" v-model="contprojRegion" name="contprojRegion" placeholder="请输入所属地区"/>
                                    </div>
                                </li>
                                <li class="col-xs-5">
                                    <div class="pw-label">项目负责人：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control" maxlength="50" v-model="contprojLeader" name="contprojLeader" placeholder="请输入项目负责人"/>
                                    </div>
                                </li>
                                <li class="col-xs-5 col-xs-offset-2">
                                    <div class="pw-label">负责人手机：</div>
                                    <div class="pw-value">
                                        <input type="text" class="form-control" maxlength="50" v-model="contprojLeaderPhone" name="contprojLeaderPhone" placeholder="请输入负责人手机"/>
                                    </div>
                                </li>
                                <li class="col-xs-12">
                                    <div class="ul-sub-heading">
                                        <div class="tt">团队信息：</div>
                                        <!-- <select id="contprojTeamId" name="contprojTeamId" class="form-control" style="width:auto"></select> -->
                                        <el-select v-model="selectTeamId" @change="changeTeamsData" size="small">
                                            <el-option v-for="(item,index) in TeamSelectData" :key="index" :label="item.teamName" :value="item.teamId" ></el-option>
                                        </el-select>
                                        <el-button type="danger" @click="TeamsDelete" style="float:right;" v-if="selectTeamId">删除</el-button>
                                        <el-button  type="primary" @click="dialogTeamVisible = true"  style="float:right;margin-right:10px;">添加</el-button>
                                    </div>
                                    <div class="bm-ul-table">
                                        <el-table :data="TeamTableData" border style="width: 100%" :default-sort="{prop: 'teammemberId', order: 'asc'}">
                                            <el-table-column type="index" prop="teammemberId" label="序号" width="70px" align="center"></el-table-column>
                                            <el-table-column prop="teammemberId" label="id" align="center" v-if="false"></el-table-column>
                                            <el-table-column prop="teammemberName" label="姓名" align="center"></el-table-column>
                                            <el-table-column prop="teammemberRemark" label="专业" align="center"></el-table-column>
                                            <el-table-column prop="teammemberGender" label="性别" align="center" :formatter="teammemberGender"></el-table-column>
                                            <el-table-column prop="teammemberRole" label="团队角色" align="center"></el-table-column>
                                            <el-table-column prop="teammemberHighedu" label="学历" align="center" :formatter="teammemberHighedu"></el-table-column>
                                        </el-table>
                                    </div>
                                </li>
                                <li class="col-xs-12">
                                    <div class="ul-sub-heading">
                                        <div class="tt">指导老师：</div>
                                        <el-button type="primary" @click="addEquipment()" style="float:right;">添加</el-button>
                                    </div>
                                    <div class="bm-ul-table">
                                        <el-table :data="teachersTableData" border>
                                            <el-table-column type="index" label="序号" width="70px"  align="center"></el-table-column>
                                            <el-table-column prop="conteachName" label="姓名" width="180" align="center"></el-table-column>
                                            <el-table-column prop="conteachTitle" label="职称" width="180" align="center"></el-table-column>
                                            <el-table-column prop="conteachWorkunit" label="工作单位" align="center"></el-table-column>
                                            <el-table-column prop="conteachPhone" label="联系电话" align="center"></el-table-column>
                                            <el-table-column label="操作" align="center">
                                                <template slot-scope="scope">
                                                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, teachersTableData)">删除</el-button>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="bm-panel">
                        <div class="bm-panel-hd">
                            <div class="bm-panel-tt">附件上传<span class="en">/Attachment upload</span></div>
                        </div>
                        <div class="bm-panel-bd">
                            <div class="form-group row" v-for="(item,index) in fujianType" :key="index">
                                <label class="col-form-label text-right">{{item.attachTitle}}：</label>
                                <template v-if="item.hasFile">
                                    <div class="col-form-div" v-for="(pitem,idx) in attachList" :key="idx">
                                            <a class="adl" v-if="item.attachId==pitem.projattachSortnum" :href="$store.state.baseHost + '/fs/download?storeKey=' + pitem.projattachFileUrl">{{pitem.projattachFileName}}</a>
                                            <el-button size="mini" v-if="item.attachId==pitem.projattachSortnum" type="danger" @click="delAttachFile(pitem.projattachId,item.attachId)" ><i class="el-icon-delete-solid" style="color:#fff;"></i></el-button>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-form-div">
                                        <el-upload action="#" :ref="'file'+item.attachId" :accept="item.doneAttachType.join()" :on-change="(file, fileList) => {fujianChangeFN(file, fileList, item)}" :auto-upload="false">
                                            <el-button size="small" type="primary" class="btn-upload">选择文件</el-button>
                                            <span slot="tip" class="el-upload_tip">&nbsp;{{item.attachRemark}}（不超过{{item.attachSize==null||item.attachSize=="" ? 30:item.attachSize}}M）</span>
                                        </el-upload>
                                    </div>
                                </template>
                            </div>
                            <!-- <div class="form-group row">
                                <label class="col-form-label text-right">大赛报名表：</label>
                                <div class="col-form-div" v-if="haveSignupFile">
                                   <a class="adl" :href="$store.state.baseHost + '/fs/download?storeKey=' + signupFile_list.projattachFileUrl">{{signupFile_list.projattachFileName}}</a>
                                   <el-button size="mini" type="danger" @click="delAttachFile(signupFile_list.projattachId,1)"><i class="el-icon-delete-solid" style="color:#fff;"></i></el-button>
                                </div>
                                <div class="col-form-div" v-else>
                                    <el-upload action="11" ref="signupFileEnc" accept=".doc,.docx" :on-change="signupFileEdit" :auto-upload="false">
                                        <el-button size="small" type="primary" class="btn-upload">选择文件</el-button>
                                        <span slot="tip" class="el-upload_tip">&nbsp;当前支持以下格式：doc,docx</span>
                                    </el-upload>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label text-right">商业计划书：</label>
                                <div class="col-form-div" v-if="haveProFile">
                                   <a class="adl" :href="$store.state.baseHost + '/fs/download?storeKey=' + proFile_list.projattachFileUrl">{{proFile_list.projattachFileName}}</a>
                                   <el-button size="mini" type="danger" @click="delAttachFile(proFile_list.projattachId,2)"><i class="el-icon-delete-solid" style="color:#fff;"></i></el-button>
                                </div>
                                <div class="col-form-div" v-else>
                                    <el-upload action="11" ref="proFileEnc" accept=".doc,.docx" :on-change="proFileEdit" :auto-upload="false">
                                        <el-button size="small" type="primary" class="btn-upload">选择文件</el-button>
                                        <span slot="tip" class="el-upload_tip">&nbsp;当前支持以下格式：doc,docx</span>
                                    </el-upload>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label text-right">路演PPT：</label>
                                <div class="col-form-div" v-if="havePptFile">
                                    <a class="adl" :href="$store.state.baseHost + '/fs/download?storeKey=' + pptFile_list.projattachFileUrl">{{pptFile_list.projattachFileName}}</a>
                                    <el-button size="mini" type="danger" @click="delAttachFile(pptFile_list.projattachId,3)"><i class="el-icon-delete-solid" style="color:#fff;"></i></el-button>
                                </div>
                                <div class="col-form-div" v-else>
                                    <el-upload action="11" ref="pptFileEnc" accept=".ppt,.pptx" :on-change="pptFileEdit" :auto-upload="false">
                                        <el-button size="small" type="primary" class="btn-upload">选择文件</el-button>
                                        <span slot="tip" class="el-upload_tip">&nbsp;当前支持以下格式：ppt,pptx</span>
                                    </el-upload>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="bm-panel" v-if="hasHistoryAudit">
                        <div class="bm-panel-hd">
                            <div class="bm-panel-tt">审核记录<span class="en">/Audit record</span></div>
                        </div>
                        <div class="bm-panel-bd">
                            <el-table :data="historyAudit" border>
                                <el-table-column type="index" label="序号" width="70px"  align="center"></el-table-column>
                                <el-table-column prop="projauditStatus" label="审核状态" width="180" align="center" :formatter="projauditStatus"></el-table-column>
                                <el-table-column prop="projauditRemark" label="审核说明" width="180" align="center"></el-table-column>
                                <el-table-column prop="projauditOperator" label="审核人" align="center"></el-table-column>
                                <el-table-column prop="projauditTime" label="审核时间" align="center"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                <template v-if="contprojId!=null">
                    <div class="card-end" v-if="contprojStatus==0">
                        <span style="opacity:0.65">已报名待审核</span>
                        <span style="color: #545454;text-decoration: underline;margin-left:15px">提交时间：{{projauditCreatime}}</span>
                    </div>
                    <div class="card-end" v-else-if="contprojStatus==1">
                          <el-button type="success" disabled>审核通过</el-button>
                    </div>
                    <div class="card-end" v-else>
                        <button type="button" class="btn btn-back" @click="backto()">返回</button>
                        <button type="button" class="btn btn-primary save-btn" @click="SignUpsubmit($event)">提交报名</button>
                    </div>
                </template>
                <template v-else>
                    <div class="card-end">
                        <button type="button" class="btn btn-back" @click="backto()">返回</button>
                        <button type="button" class="btn btn-primary save-btn" @click="SignUpsubmit($event)">提交报名</button>
                    </div>
                </template>
                
            </div>
            <el-dialog title="新增团队" :visible.sync="dialogTeamVisible" :close-on-click-modal="false" width="70%">
                <el-form :model="TeamsForm" :rules="TeamsForm.TeamsRules" ref="TeamsForm">
                    <el-row type="flex" class="row-bg" justify="space-between">
                        <el-col>
                            <el-form-item label="团队名称：" prop="TeamsName" :rules="TeamsForm.TeamsRules.TeamsName">
                                <el-input v-model="TeamsForm.TeamsName" style="width:auto" autocomplete="off" placeholder="请输入团队名称" clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" style="text-align:right">
                            <el-button type="primary" @click="addTeam">添加成员</el-button>
                        </el-col>
                    </el-row>
                    <el-table :data="TeamsForm.TeamsData" :border="true">
                        <el-table-column type="index" label="序号" align="center" width="70px"></el-table-column>
                        <el-table-column label="姓名" align="center">
                            <template slot-scope="scope">
                                <el-form-item :prop="'TeamsData.' + scope.$index + '.teammemberName'" :rules="TeamsForm.TeamsRules.teammemberName">
                                    <el-input v-model="scope.row.teammemberName" autocomplete="off" placeholder="请输入姓名"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="专业" align="center" >
                            <template slot-scope="scope">
                                <el-form-item :prop="'TeamsData.' + scope.$index + '.teammemberRemark'" :rules="TeamsForm.TeamsRules.teammemberRemark">
                                    <el-input v-model="scope.row.teammemberRemark" autocomplete="off" placeholder="请输入专业"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="性别" align="center" >
                            <template slot-scope="scope">
                                <el-form-item :prop="'TeamsData.' + scope.$index + '.teammemberGender'" :rules="TeamsForm.TeamsRules.teammemberGender">
                                    <el-select v-model="scope.row.teammemberGender" placeholder="请选择性别">
                                        <el-option label="男" value="1"></el-option>
                                        <el-option label="女" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="团队角色" align="center" >
                            <template slot-scope="scope">
                                <el-form-item :prop="'TeamsData.' + scope.$index + '.teammemberRole'" :rules="TeamsForm.TeamsRules.teammemberRole">
                                    <el-input v-model="scope.row.teammemberRole" autocomplete="off" placeholder="请输入团队角色"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="学历" align="center" >
                            <template slot-scope="scope">
                                <el-form-item :prop="'TeamsData.' + scope.$index + '.teammemberHighedu'" :rules="TeamsForm.TeamsRules.teammemberHighedu">
                                    <el-select v-model="scope.row.teammemberHighedu" placeholder="请选择学历">
                                        <el-option label="博士" value="0"></el-option>
                                        <el-option label="硕士" value="1"></el-option>
                                        <el-option label="本科" value="2"></el-option>
                                        <el-option label="大专(高职)" value="3"></el-option>
                                        <el-option label="中专(中职)" value="4"></el-option>
                                        <el-option label="高中" value="5"></el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" >
                            <template slot-scope="scope">
                                <el-form-item>
                                    <span v-if="scope.$index==0">--</span>
                                    <el-button type="danger" plain v-else @click="delTeamsOne(scope.$index, scope.row)">删除</el-button>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="Teamscancel('TeamsForm')">取 消</el-button>
                    <el-button type="primary" @click="AddTeamsSubmit('TeamsForm')">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog :title="dialogStatus" :visible.sync="dialogTeacherVisible" :close-on-click-modal="false">
                <el-form :model="teacherForm" :rules="teacherRules" ref="teacherForm">
                    <el-form-item label="姓名" :label-width="formLabelWidth" prop="conteachName">
                        <el-input v-model="teacherForm.conteachName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="职称" :label-width="formLabelWidth" prop="conteachTitle">
                        <el-input v-model="teacherForm.conteachTitle" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="工作单位" :label-width="formLabelWidth" prop="conteachWorkunit">
                        <el-input v-model="teacherForm.conteachWorkunit" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="formLabelWidth" prop="conteachPhone">
                        <el-input v-model="teacherForm.conteachPhone" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="TeacherCancel('teacherForm')">取 消</el-button>
                    <el-button type="primary" @click="TeacherSubmit('teacherForm')">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <bottom-bar></bottom-bar>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import Vue from 'vue'


export default {
    components: {
        navBar,
        bottomBar,
    },
    data(){
        return{
            contestId: null,//大赛id
            contprojId: null,//已保存的项目id
            contprojStatus: null,//审核状态
            projauditCreatime: null,//报名表创建时间
            contestName: '加载中...',//大赛名称
            contestEnrollRemark: '加载中...',//大赛报名须知
            enrrolTime: '加载中...',//报名时间

            contprojIndustryList: [],//项目类别数组，用于渲染页面
            contprojGroupList: [],//参赛组别数组，用于渲染页面

            contprojName: '',//项目名称
            contprojIndustry: '',//项目类别
            contprojGroup: '',//参赛组别
            needContestEnrollAuditmode:false,//是否邀请码模式
            contprojInvitecode:'',//邀请码
            contprojInstruction: '',//项目简介
            contprojOrgName: '',//所属学校
            contprojRegion: '',//所属地区
            contprojLeader: '',//项目负责人
            contprojLeaderPhone: '',//项目负责人电话
            contprojTeamId: '',//所属团队id
            teachers: [],//指导老师
            // dataFile: null,//附件的file元素的name属性，支持多文件上传

            signupFile: [],//报名表附件
            proFile: [],//商业计划书附件
            pptFile: [],//路演ppt附件

            fujianType: [],//附件格式

            haveSignupFile: false,
            signupFile_list: null,
            haveProFile: false,
            proFile_list: null,
            havePptFile: false,
            pptFile_list: null,

            file1: [],
            file2: [],
            file3: [],
            attachList:[],//已上传的附件列表

            TeamsForm: {
                TeamsName: '',
                TeamsData: [
                    {
                        teammemberName: '',//成员姓名
                        teammemberRemark: '',//专业
                        teammemberGender: '1',//1-男；2-女；
                        teammemberRole: '负责人',//团队角色
                        teammemberHighedu: '2',//最高学历：0-博士、1-硕士、2-本科、3-大专（高职）、4-中专、5-高中（职高、高技）、6-初中、7-小学
                        teammemberLeader: 1,//0=队员，1=队长
                    }
                ],
                TeamsRules: {
                    TeamsName: [
                        { required: true, message: '团队名称不能为空', trigger: 'blur' }
                    ],
                    teammemberName: [
                        { required: true, message: '姓名不能为空', trigger: 'blur' }
                    ],
                    teammemberGender:[
                        { required: true, message: '性别不能为空', trigger: 'change' }
                    ],
                    teammemberRemark: [
                        { required: true, message: '专业不能为空', trigger: 'blur' }
                    ],
                    teammemberRole: [
                        { required: true, message: '团队角色不能为空', trigger: 'blur' }
                    ],
                    teammemberHighedu:[
                        { required: true, message: '学历不能为空', trigger: 'change' }
                    ]
                }
            },
            Teams:{
                teamName: '',
            },
            TeamTableData:[],
            TeamSelectData:[],//可选团队数据
            selectTeamId:'',//选中的团队
            dialogTeamVisible: false,
            dialogTeacherVisible: false,
            teacherForm: {
                conteachName: '',
                conteachTitle: '',
                conteachWorkunit: '',
                conteachPhone: '',
            },
            //新增和编辑弹框标题
            dialogStatus: '',
            formLabelWidth: '120px',
            teacherRules: {
                conteachName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                conteachTitle: [
                    { required: true, message: '请选择职称', trigger: 'blur' }
                ],
                conteachWorkunit: [
                    { required: true, message: '请选择工作单位', trigger: 'blur' }
                ],
                conteachPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],
            },
            changeTeacherFormIndex: null,//编辑的id
            teachersTableData: [
                // {
                //     conteachName: '王小虎',//姓名
                //     conteachTitle: '教授',//职称
                //     conteachWorkunit: '上海市普陀区金沙江路 1518 弄',//工作单位
                //     conteachPhone: '123456789'//电话
                // }
            ],
            
            hasHistoryAudit: false,//是否展示审核记录
            historyAudit:[],//审核记录
            

        }
    },
    methods: {
        //获取赛事详细信息
        getDasaiData(){
            this.$post('/api/contest/query',{contestId:this.contestId}).then((res)=>{
                // console.log(res)
                if(res.result){
                    let data = res.resultData,
                        contestName = '暂无',
                        contestEnrollRemark="该赛事暂无报名须知信息",
                        enrrolTime='待定';
                    if(data.contestName != null && data.contestName != ''){
                        contestName = data.contestName
                    } 
                    if(data.enroll.contestEnrollRemark!=null&&data.enroll.contestEnrollRemark!=''){
                        contestEnrollRemark = data.enroll.contestEnrollRemark;
                    }
                    if(data.enroll.contestEnrollStarttime!=null && data.enroll.contestEnrollStarttime!="" && data.enroll.contestEnrollEndtime!=null && data.enroll.contestEnrollEndtime!=""){
                        enrrolTime = data.enroll.contestEnrollStarttime + " 至 " + data.enroll.contestEnrollEndtime;
                    }
                    if(data.enroll.contestEnrollAuditmode==1){
                        //邀请码模式
                        this.needContestEnrollAuditmode = true
                    }
                    this.contestName = contestName;//大赛名称
                    this.contestEnrollRemark = data.enroll.contestEnrollRemark;//报名须知
                    this.enrrolTime = enrrolTime;//报名时间

                    if(data.enroll.contestEnrollAttachFormat != null && data.enroll.contestEnrollAttachFormat != ''){
                        let fujianType = JSON.parse(data.enroll.contestEnrollAttachFormat);
                        for(let i=0;i<fujianType.length;i++){
                            let doneAT = [];
                            for(let k=0;k<fujianType[i].attachType.length;k++){
                                doneAT.push('.' + fujianType[i].attachType[k]);
                            }
                            fujianType[i].doneAttachType = doneAT;
                            fujianType[i].hasFile = false;
                        }
                        this.fujianType = fujianType;
                        // console.log(this.fujianType);
                    }

                    this.getContprojIndustryData();//获取项目类别数据
                    this.getContprojGroupData();//获取参赛组别数据
                    this.getTeamList();//获取团队信息
                    this.getEditDasaiData();//获取已保存的报名信息
                    
                }else{
                    this.$message.error(res.resultMsg);
                }
            }).catch((res)=>{
                console.log(res)

            })
        },
        //获取已保存的报名信息
        getEditDasaiData(){
            this.$post('/api/contproj/query',{contestId:this.contestId,studentId:this.$store.state.userInfo.student.studentId,withAudit:1}).then((res)=>{
                console.log(res)
                if(res.result){
                    if(res.resultData.contproj){
                        let data = res.resultData.contproj;
                        if(data.contprojSource=='1'){
                            this.$router.push({name: 'njydbaoming',query:{contestId: this.contestId}})
                        }

                        this.selectTeamId = data.contprojTeamId; //所属团队ID
                        this.contprojIndustry = data.contprojIndustry; //项目类别
                        this.contprojGroup = data.contprojGroup; //参赛组别
                        this.contprojName = data.contprojName; //项目名称	
                        this.contprojInstruction = data.contprojInstruction; //项目简介
                        this.contprojInvitecode = data.contprojInvitecode; //邀请码
                        this.contprojOrgName = data.contprojOrgName; //所属学校
                        this.contprojRegion = data.contprojRegion; //所属地区
                        this.contprojLeader = data.contprojLeader; //项目负责人
                        this.contprojLeaderPhone = data.contprojLeaderPhone; //负责人电话
                        this.teachersTableData = data.teacherList; //指导老师
                        this.contprojStatus = data.contprojStatus; //审核状态
                        this.contprojId = data.contprojId;

                        this.projauditCreatime = res.resultData.currentAudit!=null?res.resultData.currentAudit.projauditCreatime:null;

                        //构建附件列表
                        // for(let i=0;i<res.resultData.contproj.attachList.length;i++){
                        //     if(res.resultData.contproj.attachList[i].projattachSortnum==1){
                        //         this.haveSignupFile = true;
                        //         this.signupFile_list = res.resultData.contproj.attachList[i];
                        //     }else if(res.resultData.contproj.attachList[i].projattachSortnum==2){
                        //         this.haveProFile = true;
                        //         this.proFile_list = res.resultData.contproj.attachList[i];
                        //     }else if(res.resultData.contproj.attachList[i].projattachSortnum==3){
                        //         this.havePptFile = true;
                        //         this.pptFile_list = res.resultData.contproj.attachList[i];
                        //     }
                        // }
                        this.attachList = res.resultData.contproj.attachList;
                        let fujianType = this.fujianType;
                        console.log(this.fujianType)

                        for(let i=0;i<fujianType.length;i++){
                            for(let k=0;k<res.resultData.contproj.attachList.length;k++){
                                if(fujianType[i].attachId == res.resultData.contproj.attachList[k].projattachSortnum){
                                    // this.fujianType[i].hasFile = true;
                                    this.$set(this.fujianType[i],'hasFile',true)
                                    console.log(this.fujianType[i])
                                }
                            }
                        }
                        console.log(this.fujianType)

                        //获取审核记录
                        this.hasHistoryAudit = true;
                        this.historyAudit = res.resultData.historyAudit;
                    }
                }else{
                    this.$message.error(res.resultMsg);
                }
            }).catch((res)=>{
                console.log(res)

            })
        },
        //审核状态渲染
        projauditStatus(row,column){
            return row.projauditStatus === -1 ? '审核未通过': (row.projauditStatus==1?'审核通过':'未审核');
        },
        //删除附件
        delAttachFile(projattachId,type){
            this.$confirm('确定要删除该附件吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '正在删除',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$post("/api/contproj/deleteAttach",{projattachId:projattachId}).then((res)=>{
                    // console.log(res)
                    loading.close();
                    if(res.result){
                        this.$message.success(res.resultMsg);
                        // this.fujianType[type].hasFile = false;
                        console.log(type);
                        for(let i=0;i<this.fujianType.length;i++){  
                            if(type==this.fujianType[i].attachId){
                                this.$set(this.fujianType[i],'hasFile',false);
                            }
                        }

                    }else{
                        this.$message.error(res.resultMsg);
                    }
                }).catch((res)=>{
                    console.log(res);
                    loading.close();
                })
                
            }).catch(()=>{
                return;
            })
        },
        //获取项目类别数据
        getContprojIndustryData(){
            this.$post("/api/common/listCategory",{orgId:this.$store.state.oid,type:'CONTPROJ_CATEGORY'}).then((res)=>{
                // console.log(res)
                if(res.result){
                    this.contprojIndustryList = res.resultData;
                }else{
                    this.$message.error(res.resultMsg);
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //选择项目类别
        selectContprojIndustry(e){
            // console.log(e.target.value)
            this.contprojIndustry = e.target.value;
        },
        //获取参赛组别数据
        getContprojGroupData(){
            this.$post("/api/common/listCategory",{orgId:this.$store.state.oid,type:'CONTPROJ_GROUP'}).then((res)=>{
                // console.log(res)
                if(res.result){
                    this.contprojGroupList = res.resultData;
                }else{
                    this.$message.error(res.resultMsg);
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //选择参赛组别
        selectContprojGroup(e){
            // console.log(e.target.value)
            this.contprojGroup = e.target.value;
        },
        //查询学生团队
        getTeamList(){
            var that = this;
            this.$post("/api/conteam/list",{studentId:this.$store.state.userInfo.student.studentId}).then((res)=>{
                // console.log(res);
                if(res.result){
                    if(res.resultData.length){
                        that.TeamSelectData = res.resultData;
                        that.TeamTableData = res.resultData[0].memberList;
                        if(this.contprojId==null || this.contprojId==''){
                            that.selectTeamId = res.resultData[0].teamId;
                        }
                    }else{
                        that.TeamSelectData = [];
                        that.TeamTableData = [];
                        that.selectTeamId = '';
                    }
                }else{
                    that.TeamTableData = [];
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //查询指定团队信息
        getTeamsData(teamId){
            var that = this;
            this.$post("/api/conteam/query",{teamId:teamId}).then((res)=>{
                // console.log(res);
                if(res.result){
                    that.TeamTableData = res.resultData.memberList;
                }else{
                    that.TeamTableData = [];
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //更换团队
        changeTeamsData(selVal){
            // console.log(selVal)
            this.getTeamsData(selVal);
        },
        // 删除团队
        TeamsDelete(){
            // console.log(this.selectTeamId)
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //点击确定的操作(处理数据)
                this.$post("/api/conteam/delete",{teamId:this.selectTeamId}).then((res)=>{
                    // console.log(res);
                    if(res.result){
                        this.$message.success(res.resultMsg);
                        this.getTeamList();
                    }else{
                        this.$message.error(res.resultMsg);
                    }
                }).catch((res)=>{
                    console.log(res);
                })
            }).catch(() => {
            //点取消的提示
                return;
            });
            
        },
        //团队信息性别渲染
        teammemberGender(row, column){
            return row.teammemberGender === 1? "男" : "女";
        },
        //团队信息学历渲染
        teammemberHighedu(row, column){
            //最高学历：0-博士、1-硕士、2-本科、3-大专（高职）、4-中专、5-高中（职高、高技）、6-初中、7-小学
            switch (row.teammemberHighedu) {
                case 0:
                    return '博士'
                    break;
                case 1:
                    return '硕士'
                    break;
                case 2:
                    return '本科'
                    break;
                case 3:
                    return '大专（高职）'
                    break;
                case 4:
                    return '中专（中职）'
                    break;
                case 5:
                    return '高中'
                    break;
                default:
                    break;
            }
        },
        //返回按钮
        backto(){
            this.$router.go(-1);
        },
        //提交报名表
        SignUpsubmit(){
            event.preventDefault();
            if(this.$store.state.oid != this.$store.state.userInfo.student.studentOrgId){
                this.$message.error('非当前机构用户，无法报名')
                return;
            }
            if(this.contestId==null || this.contestId == ''){
                this.$message.error('未找到指定大赛，无法报名');
                return
            }
            if(this.contprojName==null || this.contprojName == ''){
                this.$message.error('请填写项目名称');
                return
            }
            if(this.contprojIndustry==null || this.contprojIndustry==''){
                this.$message.error('请选择项目类别');
                return
            }
            if(this.contprojGroup==null || this.contprojGroup==''){
                this.$message.error('请选择参赛组别');
                return
            }
            if(this.needContestEnrollAuditmode && this.contprojInvitecode==''){//邀请码模式
                this.$message.error('请填写邀请码');
                return
            }
            if(this.contprojInstruction==null || this.contprojInstruction==''){
                this.$message.error('请填写项目简介');
                return
            }
            if(this.contprojOrgName==null || this.contprojOrgName==''){
                this.$message.error('请填写所属学校');
                return
            }
            if(this.contprojRegion==null || this.contprojRegion==''){
                this.$message.error('请填写所属地区');
                return
            }
            if(this.contprojLeader==null || this.contprojLeader==''){
                this.$message.error('请填写项目负责人');
                return
            }
            if(this.contprojLeaderPhone==null || this.contprojLeaderPhone==''){
                this.$message.error('请填写项目负责人电话');
                return
            }
            if(this.selectTeamId==null || this.selectTeamId==''){
                this.$message.error('请选择团队，如无请创建');
                return
            }
            if(this.teachersTableData.length<=0){
                this.$message.error('指导老师列表不能为空');
                return
            }
            // if(this.signupFile==null || this.signupFile==''){
            //     this.$message.error('请上传大赛报名表!');
            //     return
            // }
            // if(this.proFile==null || this.proFile==''){
            //     this.$message.error('请上传商业计划书!');
            //     return
            // }
            // if(this.pptFile==null || this.pptFile==''){
            //     this.$message.error('请上传路演PPT!');
            //     return
            // }
            if(this.$store.state.oid==75){
                for(let i=0;i<this.fujianType.length;i++){
                    let file = 'file' + this.fujianType[i].attachId;
                    // console.log(file,this[file]);
                    if(!this[file].name){
                        this.$message.error('请将附件补充完整再提交');
                        return
                    }
                }
            }
            
            this.$confirm('报名参赛信息填写是否正确无误，确定提交报名申请表吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '正在提交报名表，请耐心等待',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                //点击确定的操作(处理数据)
                let formData = new FormData();
                // formData.append('file1',this.signupFile);
                // formData.append('file2',this.proFile);
                // formData.append('file3',this.pptFile);
                // console.log(this.file1)
                for(let i=0;i<this.fujianType.length;i++){
                    let file = 'file' + this.fujianType[i].attachId;
                    // console.log(file,this[file]);
                    if(this[file]){
                        formData.append(file,this[file]);
                    }
                }
                formData.append('contestId',this.contestId);//赛事id 必填
                if(this.contprojId){
                    formData.append('contprojId',this.contprojId);//赛事id 必填
                }
                formData.append('contprojOrgId',this.$store.state.oid);	//number	必填	所属机构ID	
                formData.append('contprojTeamId',this.selectTeamId);//number	必填	所属团队ID	
                formData.append('contprojIndustry',this.contprojIndustry);	//string	必填	项目类别	
                formData.append('contprojGroup',this.contprojGroup);//string	必填	参赛组别	
                formData.append('contprojName',this.contprojName);	//string	必填	项目名称	
                formData.append('contprojInstruction',this.contprojInstruction);	//string	可选	项目简介	
                formData.append('contprojInvitecode',this.contprojInvitecode);//string	可选	邀请码	
                formData.append('contprojOrgName',this.contprojOrgName);//string	必填	所属学校	
                formData.append('contprojRegion', this.contprojRegion);	//string	必填	所属地区	
                formData.append('contprojLeader',this.contprojLeader);	//string	必填	项目负责人	
                formData.append('contprojLeaderPhone',this.contprojLeaderPhone);	//string	必填	负责人电话	
                formData.append('teachers',JSON.stringify(this.teachersTableData));	//string	必填	导师列表JSON字符串
                
                // formData.forEach((value, key) => {//控制台输出查看
                //     console.log("key %s: value %s", key, value);
                // })
                
                this.$axios({
                    method:'POST',
                    url: '/api/contproj/save',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'token': localStorage.SSMH_TOKEN //设置token 其中K名要和后端协调好
                    },
                    data: formData,
                    timeout: 1000 * 60 * 30
                }).then((res)=>{
                    loading.close();
                    // console.log(res)
                    if(res.data.result){
                        this.$message.success(res.data.resultMsg);
                        setTimeout(()=>{
                            this.$router.push({name: 'student_competition'});
                        },1000)
                    }else{
                        this.$message.error(res.data.resultMsg)
                    }
                }).catch((res)=>{
                    loading.close();
                    console.log(res);
                })

            }).catch(() => {
            //点取消的提示
                return;
            });
        },
        //新增保存指导教师
        TeacherSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                        if(this.changeTeacherFormIndex==null){
                            this.teachersTableData.push(this.teacherForm);
                        }else{
                            Vue.set(this.teachersTableData, this.changeTeacherFormIndex, this.teacherForm);
                            console.log(this.teachersTableData)
                        }
                    this.dialogTeacherVisible = false;
                    this.changeTeacherFormIndex = null;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //取消指导教师按钮
        TeacherCancel(formName){
            // console.log(formName)
            this.$refs[formName].resetFields();
            this.dialogTeacherVisible = false
            this.changeTeacherFormIndex = null;
        },
        //新增指导老师
        addEquipment(formName){
            //新增弹框标题
            this.dialogStatus = "新增指导老师";
            this.dialogTeacherVisible = true;
            this.teacherForm={};
        },
        //编辑指导老师
        handleEdit(index, row) {
            console.log(index, row);
            this.teacherForm=JSON.parse(JSON.stringify(row));
            this.changeTeacherFormIndex = index;
            this.dialogStatus = "编辑指导老师";
            this.dialogTeacherVisible = true
        },
        //删除单行指导老师
        handleDelete(index, row) {
            // console.log(index, row);
            this.teachersTableData.splice(index, 1);
            console.log(this.teachersTableData);
        },
        //统一上传函数处理
        fujianChangeFN(file,fileList,item){
            console.log(file,fileList,item);
            if(fileList.length>1){      //这里只获取最后一次选择的问津
                fileList.splice(0,1);
            }
            if(fileList.length>0){
                let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.')+1);
                var fileN = 'file'+item.attachId;
                if(item.attachType.indexOf(fileName)==-1){
                    this.$refs[fileN][0].clearFiles()//清空上传列表,对应ref
                    this.$notify({
                        type:'error',
                        title:'提示',
                        message:'请选择' + item.attachType.join() + '格式的文件',
                    });
                    return false;
                }
                const attachSize = item.attachSize==null||item.attachSize=="" ? 30 : item.attachSize;
                const isLtSize = fileList[0].raw.size / 1024 / 1024 < attachSize;//限制文件大小不超过设置的附件大小
                if(!isLtSize){
                    this.$refs[fileN][0].clearFiles()//清空上传列表,对应ref
                    this.$notify({
                        type:'error',
                        title:'提示',
                        message:'上传视频文件大小不能超过 '+attachSize+'MB!',
                    });
                    
                }
                // const isMP4 = fileList[0].raw.type === 'video/mp4';
                // const isLt100M = fileList[0].raw.size / 1024 / 1024 < 100;//限制视频文件大小不超过100M
                // const isLt30M = fileList[0].raw.size / 1024 / 1024 < 30;//限制除视频文件大小不超过30M
                // if(isMP4){
                //     if (!isLt100M) {
                //         this.$refs[fileN][0].clearFiles()//清空上传列表,对应ref
                //         this.$notify({
                //             type:'error',
                //             title:'提示',
                //             message:'上传视频文件大小不能超过 100MB!',
                //         });
                //     }
                // }else{
                //     if (!isLt30M) {
                //         this.$refs[fileN][0].clearFiles()//清空上传列表,对应ref
                //         this.$notify({
                //             type:'error',
                //             title:'提示',
                //             message:'上传文件大小不能超过 30MB',
                //         });
                //     }
                // }
                

                // console.log(fileList[0].raw)
                this[fileN] = fileList[0].raw;
            }
        },
        //报名表上传
        signupFileEdit(file,fileList){
            if(fileList.length>1){      //这里只获取最后一次选择的问津
                fileList.splice(0,1);
            }
            if(fileList.length>0){
                let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.')+1);
                let extension1 = fileName === 'doc';
                let extension2 = fileName === 'docx';
                if(!extension1 && !extension2){
                    this.$refs.signupFileEnc.clearFiles();//清空上传列表,对应ref
                    this.$notify({
                        type:'error',
                        title:'提示',
                        message:'请选择dox,docx格式的文件',
                    });
                    return false;
                }else{
                    console.log(fileList[0].raw)
                    this.signupFile = fileList[0].raw;
                    // this.applyInfo.enc_cert_name = fileList[0].raw;
                }
            }
        },
        //商业计划书上传
        proFileEdit(file,fileList){
            if(fileList.length>1){      //这里只获取最后一次选择的问津
                fileList.splice(0,1);
            }
            if(fileList.length>0){
                let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.')+1);
                let extension1 = fileName === 'doc';
                let extension2 = fileName === 'docx';
                if(!extension1 && !extension2){
                    this.$refs.proFileEnc.clearFiles();//清空上传列表,对应ref
                    this.$notify({
                        type:'error',
                        title:'提示',
                        message:'请选择dox,docx格式的文件',
                    });
                    return false;
                }else{
                    console.log(fileList[0].raw)
                    this.proFile = fileList[0].raw;
                    // this.applyInfo.enc_cert_name = fileList[0].raw;
                }
            }
        },
        //路演PPT上传
        pptFileEdit(file,fileList){
            if(fileList.length>1){      //这里只获取最后一次选择的问津
                fileList.splice(0,1);
            }
            if(fileList.length>0){
                let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.')+1);
                let extension1 = fileName === 'ppt';
                let extension2 = fileName === 'pptx';
                if(!extension1 && !extension2){
                    this.$refs.pptFileEnc.clearFiles();//清空上传列表,对应ref
                    this.$notify({
                        type:'error',
                        title:'提示',
                        message:'请选择ppt,pptx格式的文件',
                    });
                    return false;
                }else{
                    console.log(fileList[0].raw)
                    this.pptFile = fileList[0].raw;
                    // this.applyInfo.enc_cert_name = fileList[0].raw;
                }
            }
        },
        //新增团队成员
        addTeam() {
            // var member = this.TeamsForm.TeamsData;
            // var length = member.length;
            this.TeamsForm.TeamsData.push(
            {
                teammemberName: '',//成员姓名
                teammemberRemark: '',//专业
                teammemberGender: '1',//1-男；2-女；
                teammemberRole: '',//团队角色
                teammemberHighedu: '2',//最高学历：0-博士、1-硕士、2-本科、3-大专（高职）、4-中专、5-高中（职高、高技）、6-初中、7-小学
                teammemberLeader: 0,//0=队员，1=队长
            })
        },
        //删除团队中某个成员
        delTeamsOne(index, row) {
            console.log(index,row);
            var that = this;
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //点击确定的操作(处理数据)
                that.TeamsForm.TeamsData.splice(index, 1);
                this.$message({ message: '删除成功', duration: 2000, type: 'success' });
            }).catch(() => {
            //点取消的提示
                return;
            });
        },
        //创建团队信息，需校验团队名称，成员信息大于0
        AddTeamsSubmit(formName){
            var that = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    let memberList = JSON.parse(JSON.stringify(that.TeamsForm.TeamsData));
                    for(let i=0;i<memberList.length;i++){//转换数据类型
                        if(typeof(memberList[i].teammemberGender) == 'string'){
                            memberList[i].teammemberGender = Number(memberList[i].teammemberGender);
                        }
                        if(typeof(memberList[i].teammemberHighedu) == 'string'){
                            memberList[i].teammemberHighedu = Number(memberList[i].teammemberHighedu);
                        }
                    }
                    that.$post("/api/conteam/save",{
                        teamStudentId:that.$store.state.userInfo.student.studentId,
                        teamName: that.TeamsForm.TeamsName,
                        members: JSON.stringify(memberList)
                    }).then((res)=>{
                        // console.log(res);
                        if(res.result){
                            that.$message.success(res.resultMsg);
                            that.$refs[formName].resetFields();  //在成功或者失败回调中写
                            that.dialogTeamVisible = false;
                            that.getTeamList();
                        }else{
                            that.$message.error(res.resultMsg);
                        }
                    }).catch((res)=>{
                        console.log(res);
                        that.$message.error(res);
                    })
                    // console.log(this.TeamsForm)
                    // this.dialogTeamVisible = false;
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //新增团队信息弹窗取消按钮
        Teamscancel(formName){
            this.$refs[formName].resetFields();
            this.dialogTeamVisible = false;
        },
    },
    mounted () {
        if(this.$route.query.contestId){
            this.contestId = this.$route.query.contestId;
            this.getDasaiData();
            
        }else{
            this.$message.error('大赛ID错误')
        }
    }
}
</script>

<style scoped>
.container {
    width: 1200px;
    margin: 0 auto;
}

.baoming-wrap{
	background:#f3f5fa url(../assets/baomingbg.jpg) center top no-repeat;
}
.bm-grid{
	padding-top: 140px;
    overflow: hidden;
}
.bm-people{
	float: right;
	padding: 0 40px;
}
.bm-people img{display: block;}
.bm-intro{
	float: left;
	padding: 30px 40px;
	color: #FFFFFF;
	max-width: 650px;
    box-sizing: border-box;
}
.bm-title{
	font-size: 30px;
	margin-bottom: 25px;
}
.bm-en{
	font-size: 16px;
	margin-bottom: 45px;
}
.bm-time{
	font-size: 16px;
	color: #fdce7d;
}

.bm-panel{
	margin-bottom: 10px;
	padding: 20px;
	background-color: #FFFFFF;
}
.bm-panel-hd{
	margin-bottom: 20px;
}
.bm-panel-tt{
	position: relative;
	padding-left: 16px;
	font-size: 22px;
	color: #198cff;
}
.bm-panel-tt .en{
	color: #999;
	font-size: 16px;
}
.bm-panel-tt::after{
	width: 4px;
	height: 16px;
	background-color: #198cff;
	content: ' ';
	position: absolute;
	left: 0;
	top: 50%;
	border-radius: 5px;
	margin-top: -8px;
}
.bm-rows{
	height: 40px;
	margin-bottom: 15px;
}
.bm-rows .bm-label{
	float: left;
	width: 140px;
	line-height: 40px;
}

.bm-rows .input-group{
	float: left;
	width: 520px;
	height: 40px;
}
.bm-rows .input-group .form-control{
	border: 1px solid #cdddea;
	height: 40px;
	box-shadow: 0 0 0 transparent;
	border-right: 0;
}
.input-group-addon{
	position: relative;
	height: 40px;
	width: 100px;
    background-color: #e8f3ff;
    border: 1px solid #cdddea;
	border-left: 0;
	color: #198cff;
}
.input-group-addon input{
	position: absolute;
	opacity: 0;
	width: 100%;
	top: 0;
}
.bm-ul{
	padding: 20px 30px;
}
.bm-ul li{
	margin-bottom: 20px;
	overflow: hidden;
	float: left;

}
.bm-ul .pw-label{
	width: 90px;
	float: left;
	line-height: 40px;
}
.bm-ul li .pw-value{
	overflow: hidden
}
.bm-ul .form-control{
	border: 1px solid #cdddea;
	height: 40px;
	box-shadow: 0 0 0 transparent;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    padding: 6px 12px;
    outline: none;
}
.bm-ul .bm-textarea{
	position: relative;
}
.bm-ul .bm-textarea textarea{
	border: 1px solid #cdddea;
	height: 170px;
	border-radius: 3px;
	resize: none;
	width: 100%;
	padding: 15px;
    outline: none;
    box-sizing: border-box;
}
.bm-ul .bm-textarea .bm-cout{
	color: #999999;
	position: absolute;
	right: 20px;
	bottom: 20px;
}
.bm-ul li:last-child{
	margin-bottom: 0;
}
.zx-panel-bd{
	background-color: #edf6ff;
	padding: 20px;
}
.zx-cells{
	margin-bottom: 20px;
	line-height: 2;
}
.zx-cells:last-child{
	margin-bottom: 0;
}
.zx-cells .zx-hd{
	font-size: 16px;
	margin-bottom: 10px;
}
.zx-cells .xz-val{
	padding: 0 15px;
}
.zx-cells .xz-val a{
	color: #136bc2;
}

.card-end{
	text-align: center;
	padding: 20px;
}
.card-end button{
    width: 160px;
	height: 48px;
	font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    vertical-align: middle;
}
/* .card-end .btn-primary{
	width: 160px;
	height: 48px;
	font-size: 16px;
}
.card-end .btn-default{
	width: 100px;
	height: 48px;
	font-size: 16px;
} */
.card-end .btn-back{
	width: 160px;
	height: 48px;
	line-height: 34px;
	font-size: 16px;
	margin-left: 60px;
	background-color: #c4ccd3;
	color: #FFFFFF;
    margin-right: 10px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:hover{
	background-color: #198cff;
	border-color: #198cff;
		font-size: 16px;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
    overflow: hidden;
}

.col-xs-5 {
    width: 40%;
    position: relative;
    min-height: 1px;
    /* padding-right: 15px; */
    /* padding-left: 15px; */
}

.col-xs-offset-2 {
    margin-left: 20%;
}
.col-xs-12 {
    width: 100%;
}
.ul-sub-heading{
	margin: 15px 0;
	height: 30px;
	line-height: 30px;
}
.ul-sub-heading .tt{
	float: left;
	width: 90px;
}
.ul-sub-heading .btn-primary,
.ul-sub-heading .btn-primary:hover,
.ul-sub-heading .btn-primary:active{
	float: right;
	height: 30px;
	padding: 0 10px;
	font-size: 14px;
	line-height: 28px;
    outline: none;
    border-radius: 4px;
    color: #fff;
}
.bm-ul-table{
	padding-left:90px;
}

.form-group{
	margin-bottom: 20px;
	position: relative;
    display: flex;
    line-height: 50px;
}
.form-group .form-control{
	height: 55px;
	padding: 0 55px;
	border: 0;
	border-radius: 4px;
	box-shadow: 4px 0 10px rgba(14,79,144,.22);
}
.form-group .iconfont{
	position: absolute;
	font-size: 20px;
	color: #d8d8d8;
	line-height: 1;
	left: 20px;
	top: 18px;
}
.form-group .btn-primary,
.form-group .btn-primary:hover{
	background-color: #198cff;
	border-color: #198cff;
	box-shadow: 10px 0 20px rgba(78,131,241,.49);
	border-radius: 5px;	font-size: 16px;
}
.form-group .btn-primary{
	height: 55px;
	width: 100%;
}
.form-group .btn-code{
	width: 150px;
	position: absolute;
	right: 0;
	top: 0;
	box-shadow: 10px 0 10px rgba(78,131,241,.29);
}
.form-group.form-code{
	padding-right: 165px;
}

.col-form-label{
    min-width: 100px;
    text-align: right;
    margin-left: 100px;  
    font-weight: bold;
}
.el-row{
    margin-bottom: 20px;
}
.adl{color: #333;margin-right: 20px;}
.adl:hover{color: #777;}
</style>