<template>
    <div>
        <nav-bar :currentId="99"></nav-bar>
		<student-info></student-info>
        <div class="uc-wrap">
			<div class="container clearfix">
				<!-- <div class="uc-sidebar">
				 	<div class="uc-side-hd">
						<h3>用户中心</h3>
						<p>User center</p>
					</div>
					<ul class="uc-navbar">
						<li class="menu"><router-link to="/student_profile"><i class="iconfont icon-gerenziliao"></i>个人资料</router-link></li>
						<li class="menu"><router-link to="/student_competition"><i class="iconfont icon-saishi"></i>我的赛事</router-link></li>
						<li class="menu active"><router-link to="/student_pwd"><i class="iconfont icon-xiugaimima"></i>修改密码</router-link></li>
					</ul>
					<div class="uc-foot">
						<a href="javascript:void(0);" class="btn btn-out" @click="logout()">退出登录</a>
					</div>				   
				</div> -->
				<student-sidebar :currentId="6"></student-sidebar>
				<div class="uc-panel">
					<div class="uc-heading">
						<div class="uc-tit">修改密碼</div>
					</div>
					<div class="uc-panel-body">
						<div class="uc-profile">
							<div class="row">
								<el-form :model="form.formData" :rules="form.formRules" ref="form"  label-width="100px">
									<div class="col-lg-offset-2">
										<div class="profile-row">
											<!-- <div class="profile-label">当前密码：</div>
											<div class="val">
												
												<input type="password" placeholder="请输入当前密码" min="6" max="36" v-model="oldPassword" class="form-control" id="oldPassword" name="oldPassword">
											</div> -->
											<el-form-item label="當前密碼：" prop="oldPassword">
												<el-input v-model="form.formData.oldPassword" style="width:auto" show-password autocomplete="off" placeholder="請輸入當前密碼" clearable></el-input>
											</el-form-item>
										</div>
										<div class="profile-row">
											<!-- <div class="profile-label">新密码：</div>
											<div class="val">
												<input type="password" placeholder="请输入新密码" min="6" max="36" v-model="newPassword" class="form-control" id="newPassword" name="newPassword">
											</div> -->
											<el-form-item label="新密碼：" prop="newPassword">
												<el-input v-model="form.formData.newPassword" style="width:auto" show-password autocomplete="off" placeholder="請輸入新密碼" clearable></el-input>
											</el-form-item>
										</div>
										<div class="profile-row">
											<!-- <div class="profile-label">确认密码：</div>
											<div class="val">
												<input type="password" placeholder="请再次输入新密码" min="6" max="36" v-model="newPassword2" class="form-control" id="confirm_password" name="confirm_password">
											</div> -->
											<el-form-item label="確認密碼：" prop="newPassword2">
												<el-input v-model="form.formData.newPassword2" style="width:auto" show-password autocomplete="off" placeholder="請再次輸入新密碼" clearable></el-input>
											</el-form-item>
										</div>
									</div>
								</el-form>
							</div>
							
							<div class="info-end">
								<button type="button" class="btn btn-primary" @click="changePWD()">保存</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <bottom-bar></bottom-bar>
    </div>
</template>

<script>
import navBar from "@/components/navftBar";
import bottomBar from "@/components/bottomBar";
import studentInfo from "@/components/studentCenter/studentInfo";
import studentSidebar from "@/components/studentCenter/ftstudentSidebar";

export default {
    components: {
        navBar,
		bottomBar,
		studentInfo,
		studentSidebar
	},
	data () {
		return {
			//帐号信息
			memberId: this.$store.state.userInfo.memberId,//会员id
			memberName : this.$store.state.userInfo.memberName,//会员名称	
			memberPhone	: this.$store.state.userInfo.memberPhone,//手机号
			memberEmail : this.$store.state.userInfo.memberEmail,//电子邮箱	

			form:{
				formData:{
					oldPassword: '',//当前密码
					newPassword: '',//新密码
					newPassword2: '',//确认密码
				},
				formRules:{
					oldPassword: [
						{ required: true, message: '當前密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
                    newPassword: [
						{ required: true, message: '新密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
                    newPassword2: [
                        { required: true, message: '確認密碼不能為空', trigger: 'blur' },
                    	{ min: 6, max: 36, message: '長度在 6 到 36 個字元', trigger: 'blur' }
                    ],
				}
			}
			
		}
	},
	methods: {
		changePWD(){
			if(this.form.formData.newPassword!=this.form.formData.newPassword2){
				this.$message.error('兩次輸入的新密碼不一致，請檢查');
				return
			}
			this.$post('/api/appuser/updatePassword',
			{
				oldPassword:this.form.formData.oldPassword,
				newPassword:this.form.formData.newPassword
			}).then((res)=>{
				console.log(res);
				if(res.result){
					this.$message.success(res.resultMsg);
					//这里执行登出
					this.$post("/api/appuser/logout").then((res) => {
						console.log(res);
						if (res.result) {
							this.$store.commit("LOG_OUT");
            				this.$router.push("/ft/login/"+this.$store.state.oid);
						}
					});
				}else{
					this.$message.error(res.resultMsg);
				}
			}).catch((res)=>{
				console.log(res);
			})
		},
	}
}
</script>

<style scoped>
.container{width: 1200px;overflow: hidden;margin: 0 auto;position: relative;z-index: 2;}

.uc-wrap{
	position: relative;
	z-index: 999;
	margin-top: -46px;
	padding-bottom: 80px;
}
.uc-panel{
	float: right;
	width: 930px;
	background-color: #FFFFFF;
}
.uc-heading{
	height: 46px;
	border-bottom: 1px solid #DDDDDD;
	margin-left: 20px;
}
.uc-tit{
	position: relative;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-tit::after{
	height: 1px;
	width: 24px;
	position: absolute;
	background-color: #02354b;
	left: 0;
	bottom: -1px;
	content: ' ';
}
.uc-panel-body{
	padding: 20px 40px 20px 20px;
}
.profile-row{
	position: relative;
}
.profile-label{
	position: absolute;
	left: 0;
	top: 0;
	font-size: 16px;
	color: #888e99;
	width: 110px;
	line-height: 56px;
	text-align: right;
}
.profile-row .val{
	padding-left: 150px;
}
.profile-row .form-control{
	border: 0;
	border-radius: 0;
	box-shadow: 0 0 0 transparent;
	border-bottom: 1px solid #f1f1f1;
	height: 56px;
	padding: 0;
	width: 350px;
	font-size: 16px;
}
.profile-row textarea.form-textarea{
	height: 176px;
	width: 100%;
	border-radius: 6px;
	border: 0;
	outline: none;
	padding: 12px;
	resize: none;
	background-color: #f7f9fa;
	margin-top: 15px;
}
.profile-row .v-radios{
	padding: 15px 0;
}
.info-end{
	padding: 40px 0;
	text-align: center;
}
.info-end .btn-primary,
.info-end .btn-primary:hover{
	width: 160px;
	height: 42px;
	font-size: 16px;
	background-color: #198cff;
	border-color: #198cff;
	color: #FFFFFF;
    border-radius: 4px;
    border:none;
    outline: none;
	cursor: pointer;
}
</style>