import { render, staticRenderFns } from "./Certif_apply.vue?vue&type=template&id=7025723a&scoped=true&"
import script from "./Certif_apply.vue?vue&type=script&lang=js&"
export * from "./Certif_apply.vue?vue&type=script&lang=js&"
import style0 from "./Certif_apply.vue?vue&type=style&index=0&id=7025723a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7025723a",
  null
  
)

export default component.exports