<template>
    <div>
      <nav-bar :currentId="99"></nav-bar>
		  <student-info></student-info>
      <div class="uc-wrap">
        <div class="container clearfix">
          <!-- <div class="uc-sidebar">
            <div class="uc-side-hd">
              <h3>用户中心</h3>
              <p>User center</p>
            </div>
            <ul class="uc-navbar">
              <li class="menu active"><router-link to="/student_profile"><i class="iconfont icon-gerenziliao"></i>个人资料</router-link></li>
              <li class="menu"><router-link to="/student_competition"><i class="iconfont icon-saishi"></i>我的赛事</router-link></li>
              <li class="menu"><router-link to="/student_pwd"><i class="iconfont icon-xiugaimima"></i>修改密码</router-link></li>
            </ul>
            <div class="uc-foot">
              <a href="javascript:void(0)" class="btn btn-out" @click="logout()">退出登录</a>
            </div>				   
          </div>	 -->
				  <student-sidebar :currentId="5"></student-sidebar>
          <div class="uc-panel">
            <div class="uc-heading">
              <div class="uc-tit">我的学分</div>
              <div class="uc-total">总学分：{{ totalCredit }}</div>
            </div>
            <table class="credit-table">
              <tr class="ta-title">
                <th>序号</th>
                <th>课程名称</th>
                <th>资源名称</th>
                <th>学分</th>
                <th>获得时间</th>
              </tr>
              <tbody class="ta-body" v-if="myCreditList.length">
                <tr v-for="(item,index) in myCreditList">
                  <td>{{((currentPage-1)*pageSize)+(index+1)}}</td>
                  <td>{{item.stuCourseName}}</td>
                  <td>{{item.stuCataresName}}</td>
                  <td>{{item.stuCredit}}</td>
                  <td>{{dateFormat(item.stuCreateTime)}}</td>
                </tr>
              </tbody>
              <tbody class="ta-body" v-if="!myCreditList.length">
                <tr>
                  <td colspan="5">暂无数据</td>
                </tr>
              </tbody>
            </table>
            <div style="display: flex;justify-content: center;margin-top:30px;" v-if="!queryTotal==0">
							<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
								:total="queryTotal" :current-page="currentPage" :page-size="pageSize">
							</el-pagination>
						</div>
          </div>
        </div>
      </div>
    </div>  
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import studentInfo from "@/components/studentCenter/studentInfo";
import studentSidebar from "@/components/studentCenter/studentSidebar";

import { VueCropper } from "vue-cropper";
import { isWeiXin, isMobile } from '../utils/userAgent.js';


export default {
    components: {
        navBar,
        bottomBar,
        studentInfo,
		    studentSidebar,
        VueCropper,
    },
    data() {
      return {
        myCreditList: [],
        currentPage: 1,
				pageSize: 20,
				queryTotal: 0,
        totalCredit: 0,
      }
    },
    methods: {
      /**
			 * @param currPage 当前页码值
			 * 点击分页回调
			 */
			handleCurrentChange(currPage) {
				//console.log(currPage);
				this.currentPage = currPage;
				this.getStuCreditList();
			},
			async getStuCreditList() {
				try {
					const res = await this.$post("/api/course/listStuCredit", {
						currentPage: this.currentPage - 1,
						pageSize: this.pageSize,
						orderby: ["date", "asc"],
						studentId:this.$store.state.userInfo.student.studentId
					});
          this.myCreditList = res.resultData.page.records;
          this.queryTotal = res.resultData.page.total;
					this.totalCredit = res.resultData.totalCredit;
				} catch (err) {
					console.error(err);
				} finally {
					this.showListLoading = false;
				}
			},
      dateFormat(mis){
        var date = new Date(mis);
        return date.getFullYear()+"-"
          +this.numberFor((date.getMonth()+1))+"-"
          +this.numberFor(date.getDate())+" "
          +this.numberFor(date.getHours())+":"
          +this.numberFor(date.getMinutes())+":"
          +this.numberFor(date.getSeconds());
      },
      numberFor(num){
        if(num<10){
          return "0"+num;
        }else{
          return num;
        }
      },
      logout(){
			  this.$confirm('是否退出登录?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
        }).then(() => {
        //点击确定的操作(调用接口)
        this.$post("/api/appuser/logout").then((res) => {
          console.log(res);
          if (res.result) {
            this.$store.commit("LOG_OUT");
            this.$router.push("/login/"+this.$store.state.oid);
          }
        });
       }).catch(() => {
        //点取消的提示
        return;
      });
		},
    toLink(courseId) {
			if(isWeiXin() || isMobile()){
        this.$router.push({
          name: "kecheng_detail",
          query: {
            courseId: courseId
          }
        });
      } else {
        let routeUrl = this.$router.resolve({
          name: "kecheng_detail",
          path: "/kecheng_detail",
          query: {
            courseId: courseId
          }
        });
        window.open(routeUrl.href, "_self");
        }
    },
  },
	mounted () {
		this.getStuCreditList();
	}
}
</script>

<style lang="less" scoped>
.container{width: 1200px;overflow: hidden;margin: 0 auto;position: relative;z-index: 2;}

.uc-wrap{
	position: relative;
	z-index: 999;
	margin-top: -46px;
	padding-bottom: 80px;
}
.uc-panel{
	float: right;
	width: 930px;
	background-color: #FFFFFF;
}
.uc-heading{
	height: 46px;
	border-bottom: 1px solid #DDDDDD;
	margin-left: 20px;
}
.uc-tit{
	position: relative;
  float:left;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-total{
 
  position: relative;
  float:right;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-tit::after{
	height: 1px;
	width: 24px;
	position: absolute;
	background-color: #02354b;
	left: 0;
	bottom: -1px;
	content: ' ';
}
.credit-table{
  width: 90%;
  margin: 20px auto;
}
.ta-title th{
  text-align: center;
  font-size: 18px;
  font-weight:550;
  height: 40px;
  border: 1px solid #DDDDDD;
  //background-color: #c4c9ce;
}
.ta-body tr td{
  text-align: center;
  font-size: 16px;
  border: 1px solid #DDDDDD;
  height: 30px;
}
</style>