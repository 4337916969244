<template>
    <div>
        <div class="hidden-md-and-down">
            <nav-bar :currentId="99"></nav-bar>
            <div class="banner">
                <img :src="this.$store.state.baseHost+'/fs/download?storeKey='+orgCover" :onerror="defaultBannerImg"/>
            </div>
            <div class="main">
                <div class="ps-title">评议确认</div>
                <div class="container" id="superMap">
                    <div class="contTitle">{{ dataList.contestName }}评分结果</div>
                    <div class="mainBox">
                        <el-table :data="projList" border>
                            <!--<el-table-column type="index" label="序号" width="70px"  align="center"></el-table-column>-->
                            
                            <el-table-column prop="contprojIndustry" label="组号" width="70px"  align="center"></el-table-column>
                            <el-table-column prop="contprojName" label="成果名称"  align="center"></el-table-column>
                            <el-table-column prop="contprojLeader" label="成果第一完成人"  align="center"></el-table-column>
                            <el-table-column prop="contprojOrgName" label="成果第一完成单位"  align="center"></el-table-column>
                            <el-table-column prop="stagejudgeScore" label="项目分数" align="center">
                                <template slot-scope="{row}">
                                    {{ row.stagejudgeScore>=0?row.stagejudgeScore:'--' }}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column label="操作" align="center">
                                <template  slot-scope="scope">
                                    <el-button type="primary" size="medium" @click="goDetail(scope.$index, scope.row)" v-if="!scope.row.stagejudgeScore">评审</el-button>
                                    <el-button type="success" size="medium" @click="goDetail(scope.$index, scope.row)" v-else>查看</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!-- <el-pagination
                            style="text-align: center;margin-top:30px"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="[20, 30, 50, 100]"
                            :page-size="pagesize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                        >
                        </el-pagination> -->
                    </div>
                    <p class="p-title">签名（请确认上述打分无误，再签名。提交后不能更改分数）：</p>
                    <div class="signatureBox">
                        <!-- <canvas class="canvasId" /> -->
                        <!-- <button @click="save">保存</button> -->
                        <!-- <button @click="clear">清除</button> -->
                        <XhyAutograph :config="options" @receive="receiveQmValue" ref="xhyautograph"></XhyAutograph>
                    </div>
                    <div id="test" style="background:#fff"></div>
                </div>
                <div class="btnBox">
                    <button class="white" @click="back()">返回</button>
                    <button class="white" @click="clear">重新签名</button>
                    <button class="blue" @click="getImg">提交</button>
                </div>  
            </div>
            <bottom-bar></bottom-bar>
        </div>
        <div class="hidden-lg-and-up phone_padding">
                <phone-header :phoneCurrentId="99"/>
                <div class="xs_ps_wrap">
                    <div class="xs_ps_title">
                        <span>评议确认</span>
                    </div>
                    <div class="xs_ps_main">
                        <div id="phoneSuperMap">
                            <div style="padding: .4rem">
                                <p class="xs_queren_title">{{ dataList.contestName }}</p>
                            </div>
                            <div class="data_table">
                                <div class="mytable_header">
                                    <div class="xs">组号</div>
                                    <div class="sm">名称</div>
                                    <!-- <div class="sm">组别</div> -->
                                    <!-- <div class="sm">类别</div> -->
                                    <div class="xs">分数</div>
                                </div>
                                <div
                                    class="my_table_body"
                                    v-for="(item,index) in projList"
                                    :key="item.contprojId"
                                >
                                    <div class="xs">{{item.contprojIndustry}}</div>
                                    <div class="sm">{{item.contprojName}}</div>
                                    <!-- <div class="sm">{{item.contprojGroup}}</div> -->
                                    <!-- <div class="sm">{{item.contprojIndustry}}</div> -->
                                    <div class="xs">{{item.stagejudgeScore == null ? '--': item.stagejudgeScore}}</div>
                                </div>
                            </div>
                        </div>
                        <p style="margin:.4rem 0;padding: 0 .4rem;font-size:.4rem">签名（请确认上述打分无误，再签名。提交后不能更改分数）：</p>
                        <div class="xs_qming_img_content">
                            <div class="xs_qming_view">
                                <!-- <div class="edit_box">
                                            <van-icon name="edit" size="25" color="#1989fa"/>
                                            <span style="margin-left:.2rem;font-size:15px;color:#1989fa" @click="showPhoneQueen = true">点击签名</span>
                                            </div> -->
                                <!-- 签名图片回显区域 -->
                                <!-- <div class="back_img"></div> -->
                                <XhyAutograph
                                    showIcon
                                    showPopup
                                    :showNormalCanvas="false"
                                    w100
                                    h100
                                    wI100
                                    hI100
                                    havapadding
                                    havInsideBgc
                                    @receive="receivePhoneQmValue"
                                    :phoneDataimgUrl="qmImgData64"
                                ></XhyAutograph>
                            </div>
                        </div>
                        <div class="xs_confirm_btn_group">
                            <van-button type="info" plain @click="back">返回</van-button>
                            <van-button type="info" plain style="margin: 0 .5rem;" @click="remake">重新签名</van-button>
                            <van-button type="info" @click="handleCommit">提交</van-button>
                        </div>
                    </div>
                </div>
                <phone-footer />
        </div>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import html2canvas from "html2canvas";
import XhyAutograph from "../components/Autograph";
import { isWeiXin, isMobile } from "../utils/userAgent.js";
export default {
    components: {
        navBar,
        bottomBar,
        XhyAutograph,
    },
    data () {
        return {
            options: {
                    penColor: "black", //笔刷颜色
                    minWidth: 2, //最小宽度
                },
                qmValue: "",

            defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
            defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',

            stageId: null,//环节id
            contestId: null,//大赛id
            dataList: [],//大赛信息
            countDownTime: null,
            saishiCountDownShow: false,//是否展示倒计时
            
            showMask: false,
            showRules: false,

            showConfirmationBtn: false,//确认单按钮 默认隐藏，如果需要评审确认则在获取环节信息时处理
            showConfirmation: false,//确认单

            stagejudgeStatus: '',//评审状态
            contprojName: '',//项目名称

            projList: [],//项目数据

            currentPage: 1, //初始页
            pagesize: 50, //    每页的数据
            total: 0,//总数

            currentNav: 0,//0-全部，1-已评审，2-未评审

            fileList: [],
            showResetBtn: false,

            isCanConfirm: true,//是否可以提交确认单
            showSeeConfirm: false,//查看确认单按钮
            showConfirmList: false,//查看确认单弹层
            confirmList: [],//确认单列表

            orgCover: null,
            // 移动端签名弹出层
            showPhoneQueen: false,
            // 移动端 qmImgData64
            qmImgData64: ""
        }
    },
    methods: {
        // ------------移动端start----------------
        receivePhoneQmValue(val) {
            console.log(val);
            this.qmImgData64 = val;
        },
        remake() {
            document.querySelector("#diaoyong").click();
        },
        handleCommit() {
            if (this.qmImgData64 == "") {
                this.$toast.fail("请签名后提交");
                return;
            }
            this.$toast.loading({
                message: "正在提交...",
                forbidClick: true
            });
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
            html2canvas(document.getElementById("phoneSuperMap"), {
                backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true, //支持图片跨域
                scale: 1 //设置放大的倍数
            }).then(canvas => {
                let url = canvas.toDataURL("image/png", 1);
                console.log(url);
                this.$post("/api/stage/saveConfirmTwo", {
                stageId: this.stageId,
                judgerId: this.$store.state.userInfo.judeger.judgerId,
                imgBase64: url
                })
                .then(res => {
                    this.$toast.clear();
                    // console.log(res);
                    if (res.result) {
                    // this.$store.commit("updateJudgerConfirm");
                    this.$toast.success(res.resultMsg);
                    this.$router.push({
                        name: "pingshen_list",
                        query: { stageId: this.stageId, contestId: this.contestId }
                    });
                    } else {
                    this.$toast.fail(res.resultMsg);
                    }
                })
                .catch(res => {
                    console.log(res);
                    this.$toast.clear();
                    this.$toast.fail("提交失败");
                });
            });
        },
        // ------------移动端end----------------
        receiveQmValue(val) {
            this.qmValue = val;
            // console.log(this.qmValue);
        },  
        clear() {
            //清除画布内容
            this.$refs.xhyautograph.againSignature();
        },
        getImg() {
            if (this.qmValue == "") {
                this.$message.error('请签名后提交');
                return;
            }
            const loading = this.$loading({
                // lock: true,
                text: '正在提交',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0
            html2canvas(document.getElementById("superMap"), {
                backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                useCORS: true, //支持图片跨域
                scale: 1, //设置放大的倍数
            }).then((canvas) => {
                let url = canvas.toDataURL("image/png", 1);
                // url = url.split('data:image/png;base64,')[1];
                // console.log(url);

                //截图用img元素承装，显示在页面的上
                // let img = new Image();
                // img.src = url; // toDataURL :图片格式转成 base64
                // document.getElementById("test").appendChild(img);
                // loading.close();
                // return

                this.$post("/api/stage/saveConfirmTwo", {
                    stageId: this.stageId,
                    judgerId: this.$store.state.userInfo.judeger.judgerId,
                    imgBase64: url,
                })
                .then((res) => {
                    loading.close();
                    // console.log(res);
                    if (res.result) {
                        // this.$store.commit("updateJudgerConfirm");
                        this.$message.success('评议确认单提交成功！');
                        this.$router.push({name: 'jspingshen_list',query:{stageId: this.stageId,contestId: this.contestId}});
                    } else {
                        this.$message.error(res.resultMsg)

                    }
                })
                .catch((res) => {
                    console.log(res);
                    loading.close();
                    this.$message.error('提交失败');
                });

                //如果你需要下载截图，可以使用a标签进行下载
                // let a = document.createElement("a");
                // a.href = canvas.toDataURL("image/jpeg");
                // a.download = "test";
                // a.click();
            });
        },
        //提交确认单
        // saveConfirm(){
        //     // console.log(this.$refs.upload);
        //     let { uploadFiles } = this.$refs.upload
        //     let formData = new FormData()
        //     uploadFiles.forEach(item => {
        //         // console.log(item.raw)
        //         formData.append('dataFile', item.raw)
        //     })
        //     formData.append('stageId',this.stageId);
        //     formData.append('judgerId',this.$store.state.userInfo.judeger.judgerId);

        //     // formData.forEach((value, key) => {//控制台输出查看
        //     //     console.log("key %s: value %s", key, value);
        //     // })
        //     this.$confirm('评审确认单提交后将不能再修改，确定要提交评审确认单吗？', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         const loading = this.$loading({
        //             lock: true,
        //             text: '正在提交',
        //             spinner: 'el-icon-loading',
        //             background: 'rgba(0, 0, 0, 0.7)'
        //         });
        //         this.$axios({
        //             method:'POST',
        //             url: '/api/stage/saveConfirm',
        //             headers: {
        //                 'Content-Type': 'application/x-www-form-urlencoded',
        //                 'token': localStorage.SSMH_TOKEN //设置token 其中K名要和后端协调好
        //             },
        //             data: formData
        //         }).then((res)=>{
        //             loading.close();
        //             // console.log(res)
        //             if(res.data.result){
        //                 this.$message.success(res.data.resultMsg);
        //                 this.ShowConfirmationMask();
        //                 this.getConfirm();
        //             }else{
        //                 this.$message.error(res.data.resultMsg)
        //             }
        //         }).catch((res)=>{
        //             loading.close();
        //             console.log(res);
        //         })
        //     }).catch(()=>{
        //         return;
        //     })

        // },
        //获取评委指定赛事环节信息
        // getData(){
        //     this.$post("/api/stage/getJudgerStage",{judgerId: this.$store.state.userInfo.judeger.judgerId,stageId:this.stageId}).then((res)=>{
        //         // console.log(res);
        //         if(res.result){
        //             this.dataList = res.resultData;
        //             if(res.resultData.stageStatus != 0){
        //                 this.saishiCountDownShow = true;
        //                 if(res.resultData.stageEndtime != null){
        //                     this.countDown(res.resultData.stageEndtime)
        //                 }
        //             }
        //             if(res.resultData.contprojOkCnt < res.resultData.contprojTotalCnt){//项目未评审完
        //                 this.isCanConfirm = false;//是否可以提交确认单

        //                 //项目未评审完情况下，判断缓存里是否有rules，没有则弹出评审须知
        //                 if (sessionStorage.getItem("rules") == null && this.showSeeConfirm == false) {
        //                     sessionStorage.setItem("rules", "1");
        //                     this.ShowRulesMask();
        //                 }
        //             }
        //             if(res.resultData.stageJudgeConfirm == 1){
        //                 this.showConfirmationBtn = true
        //             }

        //             //全部项目评审完毕，未上传过确认单，即直接弹出上传确认单界面
        //             if ((res.resultData.contprojOkCnt == res.resultData.contprojTotalCnt) && (res.resultData.stageJudgeConfirm == 1) && (res.resultData.stageStatus == 1) && (!this.showSeeConfirm)) {
        //                 this.ShowConfirmationMask();
        //             }
        //         }else{
        //             this.$message.error(res.resultMsg);
        //         }
        //     }).catch((res)=>{
        //         console.log(res);
        //     })
        // },
        // 获取评委确认信息
        // getConfirm(){
        //     this.$post("/api/stage/queryConfirm",{
        //         stageId: this.stageId,//	number	必填	环节ID	
        //         judgerId: this.$store.state.userInfo.judeger.judgerId,//	number	必填	评委ID
        //     }).then((res)=>{
        //         // console.log(res);
        //         if(res.result){
        //             if (res.resultData.confirmStatus == 1) {
        //                 this.showSeeConfirm = true;
        //                 this.confirmList = res.resultData.confirmPaths;
        //             }else{
        //                 this.showSeeConfirm = false;
        //             }

        //         }else{
        //             this.showSeeConfirm = false;
        //         }
        //         this.getData();
        //     }).catch((res)=>{
        //         console.log(res);
        //     })
        // },
        //获取评委指定赛事环节信息
        getData(){
            this.$post("/api/stage/getJudgerStage",{judgerId: this.$store.state.userInfo.judeger.judgerId,stageId:this.stageId}).then((res)=>{
                // console.log(res);
                if(res.result){
                    this.dataList = res.resultData;
                }else{
                    // this.$message.error(res.resultMsg);
                    if (isWeiXin() || isMobile()) {
                        this.$toast.fail(res.resultMsg);
                    } else {
                        this.$message.error(res.resultMsg);
                    }
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //获取项目数据
        getProjData(){
            this.$post("/api/stage/listJudge",{
                contestId: this.contestId,//	number	必填	大赛ID	
                judgerId: this.$store.state.userInfo.judeger.judgerId,//	number	必填	评委ID	
                stageId: this.stageId,//	number	可选	环节ID	
                stageType: 0,//	number	可选	环节类型：0-网评；1-现场	
                stagejudgeStatus: this.stagejudgeStatus,//	number	可选	环节评审状态：空值-全部；0-未评；1-已评	
                contprojName: this.contprojName,//	string	可选	项目名称
                currentPage: this.currentPage,//	number	必填	当前页码，从1开始
                pageSize: 1000,//	number	必填	每页数量
            }).then((res)=>{
                // console.log(res);
                if(res.result){
                    console.log(res.resultData.rows);
                    this.projList = res.resultData.rows;
                    this.total = res.resultData.total;
                }else{

                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        back(){
            this.$router.go(-1);
        },
        // // 初始页currentPage、初始每页数据数pagesize和数据data
        // handleSizeChange: function (size) {
        //     this.pagesize = size;
        //     console.log(this.pagesize); //每页下拉显示数据
        //     this.getProjData();
        // },
        // //分页切换，点击第几页
        // handleCurrentChange: function (currentPage) {
        //     this.currentPage = currentPage;
        //     console.log(this.currentPage); //点击第几页
        //     this.getProjData();
        // },
        // //点击签署确认单按钮
        // ShowConfirmationMask(){
        //     if(!this.isCanConfirm){
        //         this.$message.warning('项目没有评审完毕');
        //         return;
        //     }
        //     this.showMask = !this.showMask;
        //     this.showConfirmation = !this.showConfirmation;
        //     this.showResetBtn = false;
        // },
        // //查看确认单按钮
        // showConfirmListFN(){
        //     this.showMask = !this.showMask;
        //     this.showConfirmList = !this.showConfirmList;
        // },
        // //返回评委个人中心
        // goCenter(){
        //     this.$router.push({name: 'pingshen_index'});
        // },
        // //进入打分页
        // goDetail(index, row){
        //     // console.log(index,row);
        //     this.$router.push({name: 'pingshen_detail',query:{contprojId: row.contprojId,stageId: row.stageId,stagejudgeId: row.stagejudgeId,stageJudgeConfirm: this.dataList.stageJudgeConfirm,type:this.currentNav}})
        // },
        // //upload-onchange
        // handleChange(file,fileList){
        //     if(fileList.length>0){      //文件数大于1，显示清空重传按钮
        //         this.showResetBtn = true;
        //     }else{
        //         this.showResetBtn = false;
        //     }
        //     if(fileList.length>0){
        //         for(let i=0;i<fileList.length;i++){
        //             let fileName = fileList[i].name.substring(fileList[i].name.lastIndexOf('.')+1);
        //             let extension1 = fileName === 'jpg';
        //             let extension2 = fileName === 'jpeg';
        //             let extension3 = fileName === 'png';
        //             if(!extension1 && !extension2 && !extension3){
        //                 this.$refs.upload.clearFiles();//清空上传列表,对应ref
        //                 this.showResetBtn = false;
        //                 this.$notify({
        //                     type:'error',
        //                     title:'提示',
        //                     message:'上传文件只能为jpg或jpeg或png格式的图片！',
        //                 });
        //                 return false;
        //             }
        //         }
        //     }
        // },
        // //清空重传
        // resetData(){
        //     this.$refs.upload.clearFiles();//清空上传列表,对应ref
        //     this.showResetBtn = false;
        // },
        // //删除upload中的文件
        // handleRemove(file, fileList) {
        //     console.log(file, fileList);
        //     if(fileList.length>0){      //文件数大于1，显示清空重传按钮
        //         this.showResetBtn = true;
        //     }else{
        //         this.showResetBtn = false;
        //     }
        // },
        // //upload文件限制
        // handleExceed(files, fileList) {
        //     this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        // },
    },
    mounted () {
        if(this.$route.query.stageId && this.$route.query.contestId){
            this.stageId = this.$route.query.stageId;
            this.contestId = this.$route.query.contestId;
           
            this.getData();
            this.getProjData();
            // this.getConfirm();
        }else{
            if (isWeiXin() || isMobile()) {
                this.$toast.fail("缺少环节id");
            } else {
                this.$message.error("缺少环节id");
            }
            // this.$message.error('缺少环节id')
        }
    },
    created () {
        if(this.$store.state.orgData){
            this.orgCover = this.$store.state.orgData.detail.orgCover;
        }
    }
}
</script>

<style scoped>
.main{width: 100%;height: auto;min-height: 92px;background:#e8edf3 url('../assets/pingshen-bg.png') no-repeat top center;margin: 0 auto;min-width: 1200px;padding-bottom: 30px;}
.main .ps-title{width: 1200px;height: 60px;line-height: 60px;margin:0 auto;color: #fff;font-size: 20px;}
.main .ps-title a{color: #fff;}

.container{width: 1200px;margin: 0 auto;background: #fff;height: auto;padding:38px 66px;box-sizing:border-box;}
.container .mainBox{width: 100%;margin: 0 auto;}


.container .mainBox .saishi-box{width: 100%;height: 225px;display: flex;margin-bottom: 90px;}
.container .mainBox .saishi-pic{width: 395px;height: 226px;}
.container .mainBox .saishi-pic img{width: 395px;height: 226px;}
.container .mainBox .saishi-info{margin-left: 24px;}
.container .mainBox .saishi-title{font-size: 18px;color: #000;width: 100%;height: 35px;line-height: 35px;font-weight: 700;overflow: hidden;}
.container .mainBox .saishi-title .t1{width: 510px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;}
.container .mainBox .saishi-title button{float:right;height:35px;padding:0 25px;cursor: pointer;}
.container .mainBox .saishi-type{width: 100%;height: 25px;display: flex;align-items: center;margin: 15px 0;}
.container .mainBox .saishi-type .saishi-type-blue{
    width: auto;height: 22px;line-height: 22px;text-align: center;padding: 0 10px;color: #fff;font-size: 14px;background:#1586f7;
}
.container .mainBox .saishi-type .saishi-type-orange{
    width: auto;height: 22px;line-height: 22px;text-align: center;padding: 0 10px 0 20px;color: #fff;font-size: 14px;background:#f45700;position: relative;margin-left: 20px;
}
.container .mainBox .saishi-type .saishi-type-orange.yjs{background: gray;color:#fff;}
.container .mainBox .saishi-type .saishi-type-orange.wks{background: #5cb85c;color:#fff;}


.container .mainBox .saishi-type .saishi-type-orange::before{content: "";width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 11px solid transparent;position: absolute;left: 0;top: 0;}
.container .mainBox .saishi-time{width: 100%;height: 35px;line-height: 35px;font-size: 16px;color:#4b4848;}
.container .mainBox .saishi-proccess{width: 100%;height: 35px;display: flex;align-items: center;}
.container .mainBox .saishi-proccess div{margin-left: 80px;font-size: 14px;color: #222121;}
.container .mainBox .saishi-proccess div.saishi-proccess-yp{margin-left: 0;}
.container .mainBox .saishi-btn-box{height: 55px;width: 670px;display: flex;align-items: center;margin-top: 10px;}
.container .mainBox .saishi-btn-box .saishi-countdown{width: auto;height: 32px;line-height: 32px;padding: 0 15px;color: #6e716f;font-size: 14px;border-radius: 4px;overflow: hidden; border: 2px solid #fbd17c;box-sizing: content-box;}
.container .mainBox .saishi-btn-box .saishi-countdown span{color: #050505;font-size: 18px;display: inline-block;}
.container .mainBox .saishi-btn{width: auto;height: 32px;line-height: 32px;box-sizing: content-box;color: #1586f7;border-radius: 4px;border:2px solid #1b99ed;margin-left: 22px;padding: 0 12px;cursor: pointer;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
}

.reload{width: auto;height: 30px;line-height: 30px;padding: 0 10px;border-radius: 4px;background: #f45700;color: #fff;margin-left: 10px;cursor: pointer;}

.container .mainBox .p-navbar-box{width: 100%;display: flex;justify-content: space-between;}
.container .mainBox .p-navbar{height: 42px;line-height:42px;font-size: 16px;color: #000000;display: flex;margin-bottom: 10px;display: flex;align-items: center;}
.container .mainBox .p-navbar span{display: inline-block;}
.container .mainBox .p-navbar .p-bar{flex: 1;display: flex;flex-wrap: nowrap;overflow: hidden;align-items: center;}
.container .mainBox .p-navbar .p-bar .p-bar-item{width: auto;height: 26px;line-height:26px;font-size: 14px;color: #000;text-align: center;border-radius: 4px;cursor: pointer;margin-left: 35px;padding: 0 13px;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
    }
.container .mainBox .p-navbar .p-bar .p-bar-item.active{background:#038eeb;color: #fff;;}
.container .mainBox .p-navbar .p-bar .p-bar-item:hover{background:#038eeb;color: #fff;}

.container .mainBox .p-navbar .p-bar .search-content {
    /* height: 30px; */
    display:flex;
    /* margin-left: 35px; */
}
.container .mainBox .p-navbar .p-bar .search-content .search-inp {
    flex:1;
    height: 28px;
    outline:none;
    background-color: transparent;
    border: 1px solid #e4e4e4;
    box-sizing:content-box;
    padding-left:14px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 14px;
}
.container .mainBox .p-navbar .p-bar .search-content .search-btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width:45px;
    display: flex;
    align-items: center;
    border:none;
    outline:none;
    color:#fff;
    background: #038eeb url('../assets/search-icon.png') no-repeat center;
}

/* 确认单列表 */
.confirmationList{width: 800px;height: 700px;background: #fff;position: fixed;top: 0;left: 0;right: 0;bottom: 0;margin: auto;z-index: 100;overflow: hidden;}
.confirmationList .title{width: 100%;height: 60px;line-height: 60px;text-align: center;font-size: 18px;color: #000000;}

.confirmationList .list-box{width: 750px;height: 560px;margin:0 auto;text-align: center;overflow: hidden;overflow-y: auto;display: block;}
.confirmationList .list-box img{width: 100%;height: auto;display: block;}
.confirmationList .close-small-btn{width: 25px;height: 25px;position: absolute;right: 20px;top: 20px;z-index: 101;background: url('../assets/close-2.png') no-repeat center; background-size: 50%;cursor: pointer;}

.confirmationList .close-btn{width: 198px;
    height: 34px;
    line-height: 34px;
    background: #0795f4;
    color: #fff;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    outline: none;
    border: none;
    display: block;
    cursor: pointer;
}


.pd-list{
    width: 100%;
    height: auto;
    padding-top: 40px;
    overflow: hidden;
}
.pd-list .pd-item{width: 100%;height: 100px;display: flex;align-items: center;margin-bottom: 20px;}
.pd-list .pd-item .pd-img{width: 75px;height: 75px;position: relative;}
.pd-list .pd-item .pd-img img{width: 75px;height: 75px;display: block;}
.pd-list .pd-item .pd-info{width: 650px;height: 100px;overflow: hidden;margin-left: 20px;display: flex;align-items: center;flex-direction: column;justify-content: center;}
.pd-list .pd-item .pd-info .pd-info-title{width: 100%;height: 35px;line-height:35px;font-size: 18px;color: #000000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.pd-list .pd-item .pd-info .pd-info-title .pd-info-city{width: auto;height: 20px;line-height: 20px;box-sizing: content-box;font-size: 14px;color: #000;margin-left:5px;border: 1px solid #038eeb;border-radius: 4px;padding: 0 6px;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;}
.pd-list .pd-item .pd-info .pd-info-category{width: 100%;height: 30px;line-height: 30px;font-size:14px;color:#000000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.pd-list .pd-item .pd-info-fraction-box{width: 130px;height: 100px;display: flex;align-items: center;justify-content: center;flex-direction: column;color: #f45700;}
.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction{font-size: 24px;}
.pd-list .pd-item .pd-info-fraction-box .pd-info-fraction-p{font-size: 16px;}

.pd-list .pd-item .pd-btn{display: flex;justify-content: flex-end;flex: 1;}
.pd-list .pd-item .pd-btn a{width: auto;height: 34px;line-height: 34px;padding:0 13px;background: #fff;border:1px solid #038eeb;color:#038eeb;border-radius: 4px;}



.page-pag{width: 100%;height:34px;line-height:34px;margin: 0 auto;display: flex;justify-content: flex-end;font-size:14px;}
.page-pag div{display: inline-block;}
.page-pag .count{margin-left: 20px;}
.page-pag .totals div{width: auto;height: 34px;line-height:34px;display: inline-block;text-align: center;cursor: pointer;border:1px solid #ddd;color:#3096ea;padding:0 13px;}
.page-pag .prev-btn,.page-pag .next-btn{width: auto;height: 34px;line-height:34px;display: inline-block;text-align: center;cursor: pointer;border:1px solid #ddd;color:#3096ea;padding:0 13px;}

.page-pag .totals div.active{background: #3096ea;border-color:#3096ea;color:#fff;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
}
.page-pag .totals div:hover,.page-pag .prev-btn:hover,.page-pag .next-btn:hover{background: #3096ea;border-color:#3096ea;color:#fff;}
button:focus{outline: none;}










.mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background:rgba(114, 114, 114, 0.8);z-index: 99;}


.rules{width: 720px;height: 570px;position: fixed;top: 0;left: 0;right: 0;bottom: 0;margin: auto;z-index: 100;background: #fff;}
.rules .rule-title{width: 100%;height: 70px;line-height: 70px;text-align: center;font-size: 18px;color: #000;font-weight: 600;}
.rules .rule-content{width: 620px;height: 400px;overflow: hidden;overflow-y: auto;background: #d8d8d8;margin: 0 auto;padding: 20px;box-sizing: border-box;}
.rules .rule-tips{width: 100%;height: 50px;line-height: 50px;text-align: center;font-size: 12px;color: #9ea0a1;}
.rules .rule-confirm{width: 200px;height: 32px;line-height: 32px;text-align: center;margin:0 auto;color: #fff;background: #038eeb;font-size: 14px;border-radius: 4px;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;cursor: pointer;}


.confirmation{width: 654px;height: 467px;position: fixed;top: 0;left: 0;right: 0;bottom: 0;margin:auto;z-index: 100;background: #fff;}
.confirmation .close-btn{width: 25px;height: 25px;position: absolute;right: 20px;top: 20px;z-index: 101;background: url('../assets/close-2.png') no-repeat center; background-size: 50%;cursor: pointer;}
.confirmation .confirmation-title{width: 100%;height: 60px;line-height: 60px;text-align: center;font-size: 18px;color: #000000;}
.confirmation .confirmation-content{width: 550px;height: 300px;margin: 0 auto;display: flex;justify-content: space-between;margin-top: 30px;}
.confirmation .confirmation-content .box{
    height: 230px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    max-width: 300px;
}
.confirmation .confirmation-content .box .name{font-size: 14px;color: #000000;}
.confirmation .confirmation-content .box .name .num{width: 26px;height: 26px;line-height:26px;border-radius: 50%;border:1px solid #0c92ec;color: #000;text-align: center;display: inline-block;margin-right: 10px;}
.confirmation .confirmation-content .hr{height: 82px;border-right: 1px dashed #000;margin-right: 30px;}
.confirmation .confirmation-content .box .icon1{width: 26px;height: 33px;background: url('../assets/ps-pic1.png') no-repeat; background-size: contain;margin: 0 auto;margin-top: 40px;}
.confirmation .confirmation-content .box .download-btn{width:auto;height: 30px;line-height: 30px;text-align: center;padding: 0 20px;border:1px solid  #bfc1c2;color:#000;border-radius: 4px;cursor: pointer;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;cursor: pointer;margin-top: 30px;
}
.confirmation .confirmation-content .box .icon2{width: 26px;height: 33px;background: url('../assets/ps-pic2.png') no-repeat; background-size: contain;margin: 0 auto;margin-top: 40px;}
.confirmation .confirmation-content .box .form-box{display: flex;margin-top: 30px;width: 100%;text-align: center;}
.confirmation .confirmation-content .box .form-box .reset{width:auto;height: 30px;line-height: 30px;text-align: center;padding: 0 20px;background:gray;border:1px solid  #bfc1c2;color: #fff;margin-left: 10px;border-radius: 4px;cursor: pointer;display: none;}
.confirmation .confirmation-content .box .upload-btn{display:block;position:relative;width:auto;height: 30px;line-height: 30px;text-align: center;padding: 0 20px;background:#0c92ec;border:1px solid  #bfc1c2;color:#fff;border-radius: 4px;cursor: pointer;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;cursor: pointer;
}
.confirmation .confirmation-content .box .upload-btn input{width: 100%;height: 100%;position: absolute;top: 0;left: 0;opacity: 0}

.confirmation .confirmation-btn{width: 198px;margin:0 auto;margin-top: 20px;display: block;}



.visaDetailTop p span {
  color: #fff;
  font-size: 1.2em;
}
.visaDetailTop p:first-of-type {
  float: left;
}
.visaDetailTop p:nth-of-type(2) {
  float: right;
}
.canvasBox {
  /* padding: 10px 5px; */
  box-sizing: border-box;
  flex: 1;
}
canvas {
  border: 1px solid #ccc;
  width: 95%;
}
.btnBox {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    box-sizing: border-box;
   /* height: 30px; */
    /* padding: 5px; */
  text-align: right;
     /* line-height: 30px; */
  padding-top: 10px;
  padding-right: 80px;
  padding-bottom: 30px;
}
.btnBox button{font-size: 24px;}
.btnBox button.white {
  border: 1px solid #1989fa;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  background: #fff;
  cursor: pointer;
  margin-left: 1rem;
}
.btnBox button.blue {
  border: 1px solid #1989fa;
  background: #1989fa;
  color: #fff;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.p-title{font-size: 24px;
padding: 20px 0 10px 15px;}
.signatureBox{cursor: crosshair;}
.contTitle{width: 100%;text-align: center;line-height: 50px;font-size: 30px;font-weight: bold;padding:30px 0;}
/* ---- 移动端start --- */
.xs_main {
  width: 100%;
  height: auto;
  /* padding: 0 .2rem; */
  box-sizing: border-box;
}

.xs_ps_wrap {
  background: #e8edf3;
  padding-bottom: 0.5rem;
}

.xs_ps_title {
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 0.2rem 0;
  height: 2rem;
  /* padding-bottom: .2rem; */
  background: url(../assets/pingshen-bg.png) no-repeat top;
  background-size: 100% 100%;
}

.xs_ps_main {
  background: #fff;
  /* padding: 0.3rem; */
  margin: 0 0.2rem;
  margin-top: -1rem;
  padding-bottom: 0.4rem;
}
.data_table {
  padding: 0.2rem 0.4rem;
}
.mytable_header {
  font-size: 0.4rem;
  color: #000;
  display: flex;
  text-align: center;
  font-weight: 700;
}
.mytable_header > div {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #eee;
  border-left: 1px solid #f1f1f1;
  padding: 0.2rem 0;
  font-size: 0.4rem;
}
.mytable_header > div:last-child {
  border-right: 1px solid #eee;
}
.my_table_body {
  font-size: 0.4rem;
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.my_table_body > div {
  /* margin: .2rem 0; */
  padding: 0.2rem 0;
  border-left: 1px solid #f1f1f1;
  font-size: 0.4rem;
}
.my_table_body > div:last-child {
  border-right: 1px solid #f1f1f1;
}
.xs {
  flex: 0.4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sm {
  flex: 1;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.md {
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.lg {
  flex: 3;
}
.xs_qming_img_content {
  background: #f2f2f2;
  padding: 0.4rem;
  margin: 0.4rem;
  box-sizing: border-box;
  height: 8rem;
}
.xs_qming_view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit_box {
  display: flex;
  align-items: center;
}
.xs_confirm_btn_group {
  text-align: right;
  margin: 0.5rem 0.4rem 0.2rem;
}
.xs_queren_title {
  text-align: center;
  font-weight: 700;
  font-size: 0.4rem;
  /* margin: .5rem 0 */
}
.xs_ps_main /deep/ .van-popup--center {
  z-index: 99999999999 !important;
}
/* ---- 移动端end --- */
</style>