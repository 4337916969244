<template>
  <div class="main-body" v-if="stageliveBgUrl!=null && stageliveBgUrl!=''" :style="{'background-image': 'url(' + stageliveBgUrl + ')'}">
    <div class="main" v-if="mark==0">
        <div class="serial-no-title">
          <span class="group-name">{{groupName}}</span>
          正在抽取第{{serialNo}}个出场项目
        </div>
        <div class="title-box" id="titleBox">
              <div class="project-name" id="contproj">
                  <template v-if="contprojName">
                    {{contprojName}}
                  </template>
              </div>
        </div>
        <div class="begin-btn" v-on:click="beginNum()">
            {{beginBtnName}}
        </div>
    </div>
    <div class="order-list" v-if="mark==1">
      <div class="order-title">
        <div class="div-xh">序号</div>
        <div class="div-mc">项目名称</div>
        <template v-for="(item,index) in serialData" v-if="index<18">
          <div class="content-xh">{{item.serial}}</div>
          <div class="content-mc">{{item.contprojName}}</div>
        </template>
      </div>
      <template v-if="serialData.length>18">
        <div class="order-title">
          <template v-for="(item,index) in serialData" v-if="index>=18">
            <div class="content-xh">{{item.serial}}</div>
            <div class="content-mc">{{item.contprojName}}</div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
var timer;

export default {
  data () {
    return {
      groupId: null,//分组id
      projData:[],
      contprojName: "--",
      stageliveBgUrl: this.$store.state.baseHost+"/assets/images/live-bg01.png",
      serialNo: 1,
      beginBtnName: "开始",
      projectIndex: 0,
      serialData: [],
      contestId: null,
      stageId: null,
      mark: 0,
      groupName: null
    }
  },
  methods: {
    //获取环节项目信息
    getStageLive(){
      var that = this;
      this.$post('/api/stagelive/getGroupProj',{
        groupId: this.groupId,
      }).then((res)=>{
        if(res.result){
          let data = res.resultData.list;
          console.log(data);
          this.projData = data;
          this.contestId = data[0].contestId;
          this.stageId = data[0].stageId;
          this.groupName = res.resultData.group.stagegroupName
        }else{
          this.$message.error(res.resultMsg);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    scNum(){
      var that = this;
      var scRan = Math.floor(Math.random()*that.projData.length);
      that.contprojName = that.projData[scRan].contproj.contprojName;
      that.projectIndex = scRan;
    },
    beginNum(){
      var that = this;
      
      if(that.beginBtnName=="开始"){
        clearInterval(timer),
        timer = setInterval(function () {
          that.scNum()
        }, 100)
        that.beginBtnName="停止";
      }else if(that.beginBtnName=="停止"){
        clearInterval(timer);
        that.beginBtnName="确定"
      }else if(that.beginBtnName=="确定"){
        that.serialData.push({
          serial : that.serialNo,
          contprojId : that.projData[that.projectIndex].contproj.contprojId,
          contprojName : that.projData[that.projectIndex].contproj.contprojName
        })
        that.projData.splice(that.projectIndex, 1);
        that.serialNo++;
        that.contprojName="--";
        that.beginBtnName="开始";
        if(that.projData.length==0){
          that.mark = 1;
          var contprojIds = [];
          for(var l=0;l<that.serialData.length;l++){
            contprojIds.push(that.serialData[l].contprojId)
          }
          var ids = JSON.stringify(contprojIds);
          this.$post('/api/stagelive/saveLottery',{
            contestId: that.contestId,
            stageId: that.stageId,
            groupId: that.groupId,
            contprojIds: ids.substring(1,ids.length-1)
          }).then((res)=>{
            if(res.result){
              this.$message.success(res.resultMsg);
            }else{
              this.$message.error(res.resultMsg);
            }
          }).catch((res)=>{
            console.log(res);
          })
          return;
        }
      }
    }
    
    

  },
  mounted () {
    if(this.$route.query.groupId){
      this.groupId = this.$route.query.groupId;
      this.getStageLive();
    }else{
      this.$message.error('缺少分组id');
    }

      
  }
}
</script>

<style scoped>
.main-body{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: url('../assets/touping-bg.png') no-repeat center;
    background-size: cover;
}

.main{
  max-width: 1340px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.main .serial-no-title .group-name{
  background: #faab11;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 20px;
  margin-right: 30px;
  color: white;
  font-size: 60px;
}

.main .project-name{
  background: #3f91c9;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 21px;
  color: #fff;
  font-weight: bold;
  font-size: 36px;
  display: inline-block; 
  min-width: 300px;
}

.main .serial-no-title{
  font-size:75px; 
  color: #ff9300; 
  margin-bottom: 80px;
}
.main .begin-btn{
  width: 121px; 
  height: 121px; 
  background-color: red; 
  border-radius: 60px; 
  line-height:121px; 
  margin-top: 35px; 
  font-size: 30px; 
  color:#fff; 
  cursor: pointer;
}
.order-list{
  display: flex;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  width: 100%;
  align-items: flex-start;
}
.order-list .order-title{
  margin: 50px auto;
  color: #fff;
  width: 45%;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  border-bottom: 2px solid #fff;
}
.order-list .order-title .div-xh{
  font-size: 25px;
  width: 21%;
  float: left;
  border-bottom: 1px solid #fff;
  border-right: 1.5px solid #fff;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.order-list .order-title .div-mc{
  font-size: 25px;
  width: 78.8%;
  float: right;
  border-bottom: 1px solid #fff;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.order-list .order-title .content-xh{
  font-size: 21px;
  width: 21%;
  float: left;
  border-bottom: 1px solid #fff;
  border-right: 1.5px solid #fff;
  line-height: 45px;
  height: 45px;
}
.order-list .order-title .content-mc{
  font-size: 21px;
  width: 74.8%;
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0% 2% 0 2%;
  line-height: 45px;
  height: 45px;
  border-bottom: 1px solid #fff;
}

.swiper-pagination-bullet{background: #fff!important;opacity: 1!important;}
.swiper-pagination-bullet-active{background: #007aff!important;}
.colorRed{color:red!important;}

  @-webkit-keyframes warning-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 3em 0;
    }
  }
  @-moz-keyframes warning-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 3em 0;
    }
  }
  @keyframes warning-animation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 3em 0;
    }
  }
</style>