<template>
  <div>
    <nav-bar v-bind:currentId="1"></nav-bar>
    <div class="main">
      <div class="banner">
        <img :src="this.$store.state.baseHost+'/fs/download?storeKey='+contestCoverUrl" v-if="contestCoverUrl" :onerror="defaultBannerImg"/>
        <img v-else :src="orgCover?this.$store.state.baseHost+'/fs/download?storeKey='+orgCover:require('../assets/banner.jpg')" :onerror="defaultBannerImg"/>
        <!-- <img src="../assets/banner.jpg" /> -->
      </div>
      <div class="mainBox" id="bigContent">
        <div class="tabs">
          <div class="tab" @click="idJump('#contestInstruction')">赛事介绍</div>
          <div class="tab" @click="idJump('#contestEnrollRemark')">报名须知</div>
          <div class="tab" @click="idJump('#anpai')">赛事安排</div>
          <!-- <div class="tab" @click="idJump('#contestJudgerule')">评分规则</div> -->
          <!-- <div class="tab" @click="idJump('#jiangxiangshezhi')">奖项设置</div> -->
          <div class="tab" @click="idJump('#fujian')">附件</div>
          <div class="tab">
            <el-dropdown placement="bottom" @command="handleCommand">
              <span class="el-dropdown-link">
                其他赛事<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :divided="index>0" v-for="(item,index) in dataList" :key="index" :command="item">{{item.contestName}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <div class="tab" :class="tabCurrent==0?'active':''" @click="tabChange(0)">大赛介绍</div> -->
          <!-- <div class="tab" :class="tabCurrent==1?'active':''" @click="tabChange(1)">附件</div> -->
        </div>
        <div class="content" v-if="tabCurrent==0">
          <div class="sign-btn" @click="signUp(contestId)">我要报名</div>
          <div class="introduce">
            <div class="introduce-title" id="contestName">大赛名称</div>
            <div class="introduce-text">{{contestName}}</div>
          </div>
          <div class="introduce">
            <div class="introduce-title" id="contestRemark">背景介绍</div>
            <div class="introduce-text">{{contestRemark}}</div>
          </div>
          <div class="introduce">
            <div class="introduce-title" id="contestInstruction">赛事介绍</div>
            <div class="introduce-text" v-html="contestInstruction"></div>
          </div>
          <div class="introduce">
            <div class="introduce-title" id="contestEnrollRemark">报名须知</div>
            <div class="introduce-text" v-html="contestEnrollRemark"></div>
          </div>
          <div class="introduce">
            <div class="introduce-title" id="anpai">赛事安排</div>
            <div class="introduce-text" id="signUpTime">
              <p>报名时间：{{enrrolTime}}</p>
              <p>联系方式：{{connectPhone}}</p>
              <p>邮箱：{{connectEmail}}</p>
              <p>地址：{{connectAddress}}</p>
              <p>主办单位：{{sponsorOrg}}</p>
              <p>承办单位：{{undertakeOrg}}</p>
              <p>协办单位：{{assistOrg}}</p>
            </div>
          </div>
          <!-- <div class="introduce">
            <div class="introduce-title" id="contestJudgerule">评分规则</div>
            <div id="contact-box">这里放评分规则</div>
          </div> -->
          <!-- <div class="introduce">
            <div class="introduce-title" id="jiangxiangshezhi">奖项设置</div>
            <div id="contact-box">这里放奖项设置</div>
          </div> -->
          <div class="introduce">
            <div class="introduce-title" id="fujian">赛事附件</div>
            <div class="format" v-if="fujianData.length">
              <div class="format-pitem" v-for="(item,index) in fujianData" :key="index">
                <div class="pitem-name">{{item.contattachFileName}}</div>
                <a :href="item.contattachFileUrl" class="pitem-btn">立即下载</a>
              </div>
            </div>
            <div v-else class="introduce-text">该赛事暂无相关附件信息</div>
          </div>
        </div>

        <!-- 附件 -->
        <!-- <div class="content" v-if="tabCurrent==1">
          <div class="format" id="fujian" style="text-align: center">
            该赛事暂无相关附件信息
          </div>
        </div> -->

      </div>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";


export default {
  components: {
    navBar,
    bottomBar
  },
  data(){
    return{
      orgCover: null,
      defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',

      tabCurrent:0,

      contestId: null,//大赛id
      contestCoverUrl: null,//赛事封面
      contestName: '加载中...',//大赛名称
      contestRemark:'加载中...',//背景介绍
      contestInstruction:'加载中...',//赛事介绍
      contestEnrollRemark: '加载中...',//报名须知
      enrrolTime:'加载中...',//报名时间
      sponsorOrg:'加载中...',//主办单位
      undertakeOrg:"加载中...",//承办单位
      assistOrg:'加载中...',//协办单位

      //信息处理
      connectPhone:'加载中',//电话
      connectEmail:'加载中',//邮箱
      connectAddress:'加载中',//地址

      fujianList: '加载中...',//附件
      fujianData: [],//附件数据

      dasaiDataList: [],//其他赛事总数据
      dataList: [],//其他赛事渲染数据
    }
  },
  methods:{
    //我要报名按钮
    signUp(contestId){
      if(!this.$store.state.userInfo){
        this.$router.push({name: 'login',query:{redirect: '/baoming?contestId='+contestId}})
        return
      }
      if(!this.$store.state.userInfo.student){
        this.$message.error('只有学生帐号允许报名');
        return
      }
      this.$router.push({name: 'baoming',query:{contestId: contestId}})
    },
    tabChange(idx){
      this.tabCurrent = idx;
    },
    // 锚点
    idJump(id){
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      })
    },
    //其他赛事按钮
    handleCommand(command){
      // console.log(command)
      this.getDasaiDetail(command.contestId);
      this.getFujianData(command.contestId);
    },
    //获取机构下的大赛列表
    getDasaiList(){
      this.$post('/api/contest/list',{orgId:this.$store.state.oid,contestStatus:1}).then((res)=>{
        // console.log(res);
        if(res.result){
          if(res.resultData.length>0){
            if(this.$route.params.contestId){//router.params 判断是否从首页的大赛列表状态点击进入，如果是，则使用一次，按刷新则恢复默认
              // console.log('id='+this.$route.params.contestId)
              this.getDasaiDetail(this.$route.params.contestId);
              this.getFujianData(this.$route.params.contestId);
            }else{
              this.getDasaiDetail(res.resultData[0].contestId);
              this.getFujianData(res.resultData[0].contestId);
            }
            this.dasaiDataList = res.resultData;
          }else{
            this.$message.error('该机构下暂无大赛')
          }
        }else{
          this.$message.error(res.resultMsg)
        }
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    },
    //获取指定大赛详情
    getDasaiDetail(contestId){
      this.$post('/api/contest/query',{contestId:contestId}).then((res)=>{
        // console.log('指定大赛详细数据')
        // console.log(res);
        if(res.result){
          let data = res.resultData,
              contestCoverUrl = null,
              contestName = '暂无',
              contestRemark = '该赛事暂无背景介绍',
              contestInstruction="该赛事暂无赛事详情",
              contestEnrollRemark="该赛事暂无报名须知信息",
              enrrolTime='待定',
              sponsorOrg='暂无',
              undertakeOrg="暂无",
              assistOrg = '暂无';
          if(data.contestCoverUrl != null && data.contestCoverUrl != ''){
            contestCoverUrl = data.contestCoverUrl
          }
          if(data.contestName != null && data.contestName != ''){
            contestName = data.contestName
          } 
          if(data.contestRemark != null && data.contestRemark != ''){
            contestRemark = data.contestRemark
          }
          if(data.contestInstruction!=null&&data.contestInstruction!=''){
            contestInstruction = data.contestInstruction;
          }
          if(data.enroll.contestEnrollRemark!=null&&data.enroll.contestEnrollRemark!=''){
            contestEnrollRemark = data.enroll.contestEnrollRemark;
          }
          if(data.enroll.contestEnrollStarttime!=null && data.enroll.contestEnrollStarttime!="" && data.enroll.contestEnrollEndtime!=null && data.enroll.contestEnrollEndtime!=""){
            enrrolTime = data.enroll.contestEnrollStarttime + " 至 " + data.enroll.contestEnrollEndtime;
          }
          if(data.items.sponsorOrg != null && data.items.sponsorOrg !=''){
            sponsorOrg = data.items.sponsorOrg;
          }
          if(data.items.undertakeOrg != null && data.items.undertakeOrg !=''){
            undertakeOrg = data.items.undertakeOrg;
          }
          if(data.items.assistOrg != null && data.items.assistOrg !=''){
            assistOrg = data.items.assistOrg;
          }
          //信息处理
          let connectPhone = '--', connectEmail = '--', connectAddress = '--';
          if(data.contestPhone!=null && data.contestPhone!="") {
            connectPhone = data.contestPhone;
          }
          if(data.contestEmail!=null && data.contestEmail!="") {
            connectEmail = data.contestEmail;
          }
          if(data.contestAddress!=null && data.contestAddress!="") {
            connectAddress = data.contestAddress;
          }
          this.connectPhone = connectPhone;//电话
          this.connectEmail = connectEmail;//邮箱
          this.connectAddress = connectAddress;//地址
          
          this.contestId = data.contestId//大赛id
          this.contestCoverUrl = contestCoverUrl;
          this.contestName = contestName;//大赛名称
          this.contestRemark = contestRemark;//背景介绍
          this.contestInstruction = contestInstruction;//赛事介绍
          this.contestEnrollRemark = data.enroll.contestEnrollRemark;//报名须知
          this.enrrolTime = enrrolTime;//报名时间
          this.sponsorOrg = sponsorOrg;//主办单位
          this.undertakeOrg = undertakeOrg;//承办单位
          this.assistOrg = assistOrg;//协办单位

          this.dataList = [];
          for(let i=0;i<this.dasaiDataList.length;i++){
            if(data.contestId != this.dasaiDataList[i].contestId){
              this.dataList.push(this.dasaiDataList[i])
            }
          }
        }else{
          this.$message.error(res.resultMsg);
        }
        
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    },
    //获取附件数据
    getFujianData(contestId){
      this.$post('/api/contest/listAttach',{contestId:contestId,category:1}).then((res)=>{
        // console.log('指定大赛附件数据')
        // console.log(res);
        if(res.result){
          if(res.resultData.length==0) {
            this.fujianData = [];
            return;
          }
          for(var i=0; i<res.resultData.length;i++) {
            var contattachFileName = '', contattachFileUrl = '';
            if(res.resultData[i].contattachFileName!=null && res.resultData[i].contattachFileName!="") {
              contattachFileName = res.resultData[i].contattachFileName;
            }
            if(res.resultData[i].contattachFileUrl!=null && res.resultData[i].contattachFileUrl!="") {
              contattachFileUrl = this.$store.state.baseHost+"/fs/download?storeKey="+res.resultData[i].contattachFileUrl;
            }
            this.fujianData.push({
              contattachFileName: contattachFileName,
              contattachFileUrl: contattachFileUrl
            })
          }
        }else{
          this.$message.error(res.resultMsg);
        }
        
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    }
  },
  mounted () {
    this.getDasaiList();
    if(this.$store.state.orgData){
      this.orgCover = this.$store.state.orgData.detail.orgCover;
    }

  }
};
</script>

<style scoped>
.main{width: 100%;height: auto;}

.mainBox{width: 1200px;height: auto;margin:0 auto;margin-top: 13px;background:#fff;padding:0 0 20px 0;}
.title{width:1132px;margin: 0 auto;height: 29px;border-bottom:1px solid #c9c9c9;overflow: hidden;display: flex;justify-items: center;justify-content: space-between;}
.title .title-text{font-size:18px;color:#3ebdf6;border-bottom:2px solid #3ebdf6;float: left;height: 27px;line-height: 27px;margin-left: 6px;}
.title .more{text-decoration: none;color:#636363;font-size:14px;height: 29px;line-height: 29px;}


.mainBox .tabs{width: 1200px;height: 70px;line-height: 70px;display: flex;justify-content: center;}
.mainBox .tabs .tab{color: #636363;font-size:18px;margin: 0 20px;cursor: pointer;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
      user-select:none;padding: 0 30px;border-bottom: 1px solid #ccc;}
.mainBox .tabs .tab.active{color:#3ebdf6;border-bottom: 3px solid #0084e1;}

.mainBox .tabs .tab >>> .el-dropdown{
  font-size: 18px;
}

.mainBox .content{width:1200px;height: auto;margin:0 auto;margin-top: 40px;}

.sign-btn{
  border: none;
  outline: none;
  width: 300px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  font-size: 24px;
  color: #fff;
  background-color: #fbb712;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin:0 auto;
}

.introduce{width: 1132px;margin: 0 auto;padding-bottom: 20px;}

/* 短标题 */
.introduce .introduce-title{width: 134px; height: 29px;line-height: 27px;color: #fff;font-size:16px;text-indent: 37px;background: url('../assets/newsBg.png') no-repeat;background: contain;margin-bottom: 10px;}
/* 长标题 */
.introduce .introduce-title-long{width: 201px; height: 29px;line-height: 27px;color: #fff;font-size:16px;text-indent: 37px;background: url('../assets/newsBgLong.png') no-repeat;background: contain;margin-bottom: 10px;}

/* 背景介绍文本样式 */
.introduce .introduce-text{width: 1100px;height: auto;line-height: 35px;font-size:14px;color:#333333;text-indent: 32px;margin: 0 auto;}

/* 组织机构文本样式 */
.introduce p{width: 1100px;margin:0 auto;font-size:14px;color:#333333;height: auto;line-height: 35px;text-indent: 28px;}

/* 参赛对象与报名样式 */
.introduce .count-pitem{width: 1100px;height: auto;margin: 0 auto;margin-bottom: 10px;}
.introduce .count-pitem .count{width: 1060px;margin:0 auto;color:#0084e1;font-size:14px;}

.introduce .count-pitem .count span{width: 25px;height: 29px;background:url('../assets/numBg.png') no-repeat;background-size: contain;display: inline-block;color:#fff;text-align: center;line-height: 29px;margin-right: 5px;}

.introduce .count-pitem p{width:1080px;margin: unset;margin-left: 20px;}


.format{width: 1050px;height: auto;min-height:250px;margin: 0 auto;}
.format .format-title{width: 100%;height: 40px;line-height: 40px;text-align: center;color:#000;font-size: 16px;}
.format .format-pitem{width: 100%;height: 67px;line-height: 67px;display: flex;justify-content: space-between;color: #333333;margin: 0 auto;align-items: center;border-bottom: 1px dashed #dcdcdc;}
.format .format-pitem .pitem-name{width: 900px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;font-size: 18px;}
.format .format-pitem .pitem-btn{width: 100px;height: 32px;line-height:32px;border-radius: 4px;background: #0084e1;color: #fff;text-align: center;font-size: 18px;}


</style>