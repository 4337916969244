<template>
    <div class="uc-grid">
        <div class="uc-avatar">
            <img id="userPhoto" :src="this.$store.state.userInfo.memberPhotourl?this.$store.state.baseHost+'/fs/download?storeKey='+this.$store.state.userInfo.memberPhotourl:require('../../assets/photo0.jpg')" :onerror="defaultImg"/>
            <p id="userName">{{memberName?memberName:'--'}}</p>
        </div>
        <ul class="uc-info">
            <li><i class="iconfont icon-xueyuan"></i><span id="orgName">{{orgName?orgName:'--'}}</span></li><li class="line"></li>
            <li><i class="iconfont icon-shouji54"></i><span id="phone">{{memberPhone?memberPhone:'--'}}</span></li><li class="line"></li>
            <li><i class="iconfont icon-youxiang"></i><span id="email">{{memberEmail?memberEmail:'--'}}</span></li>
        </ul>
    </div>
</template>

<script>
export default {
    data () {
        return {
            //帐号信息
            memberId: this.$store.state.userInfo.memberId,//会员id
            orgName: this.$store.state.userInfo.student.orgName,//机构名称
            memberName : this.$store.state.userInfo.memberName,//会员名称
            memberPhone: this.$store.state.userInfo.memberPhone,//手机号
            memberEmail : this.$store.state.userInfo.memberEmail,//电子邮箱
            defaultImg: 'this.src="' + require("../../assets/image404.gif") + '"',
        }
    }
}
</script>

<style lang="less" scoped>
.uc-grid{position: relative;background: url(../../assets/ucbg.png) center top no-repeat;height: 430px;padding-top: 140px;min-width:1200px;
    .uc-avatar{text-align: center;
        img{width: 90px;height: 90px;border-radius: 90px;}
        p{font-size: 18px;margin-top: 15px;color: #FFFFFF;}
    }
    .uc-info{text-align: center;margin-top: 40px;
        li{display: inline-block;vertical-align: middle;color: #FFF;text-align: center;font-size: 16px;
            &.line{background-color: #FFFFFF;width: 1px!important;height: 15px;margin: 0 50px;}
            .iconfont{padding-right: 10px;}
        }
    }
}
</style>