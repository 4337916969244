import { render, staticRenderFns } from "./Ft_Student_profile.vue?vue&type=template&id=34e51bd5&scoped=true&"
import script from "./Ft_Student_profile.vue?vue&type=script&lang=js&"
export * from "./Ft_Student_profile.vue?vue&type=script&lang=js&"
import style0 from "./Ft_Student_profile.vue?vue&type=style&index=0&id=34e51bd5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e51bd5",
  null
  
)

export default component.exports