<template>
    <div>
      <nav-bar v-bind:currentId="99"></nav-bar>
		  <student-info></student-info>
      <div class="uc-wrap">
        <div class="container clearfix">
				  <student-sidebar :currentId="0"></student-sidebar>

          <div class="uc-panel">
            <div class="uc-heading">
              <div class="uc-tit">個人資料</div>
            </div>
            <div class="uc-panel-body">
              <div class="uc-profile">
                
                <div class="profile-row">
                  <div class="profile-label">姓名：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="memberName" v-model="memberName" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">郵箱：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="memberEmail" v-model="memberEmail" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">手機號碼：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="memberPhone" v-model="memberPhone" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">身份證號碼：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="memberIdcard" v-model="memberIdcard" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label" style="padding-top:12px;">出生日期：</div>
                  <div class="val" style="padding: 20px 0 20px 150px;">
                    <el-date-picker v-model="memberBirthday" type="date" placeholder="選擇日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">性別：</div>
                  <div class="val">
                    <div class="v-radios">
                      <label class="radio-inline">
                        <input type="radio" name="memberGender" value="1" v-model="memberGender"> <span>男</span>
                      </label>
                      <label class="radio-inline">
                        <input type="radio" name="memberGender" value="2" v-model="memberGender"> <span>女</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">個人介紹：</div>
                  <div class="val">
                    <textarea cols="10" rows="4" class="form-textarea" v-model="memberIntroduction"></textarea>
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">所屬學校：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="orgName" v-model="orgName" class="form-control" readonly style="background:#fff;">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">學院：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="studentCollege" v-model="studentCollege" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">專業：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="studentMajor" v-model="studentMajor" class="form-control">
                  </div>
                </div>
                <div class="profile-row">
                  <div class="profile-label">班級：</div>
                  <div class="val">
                    <input type="text" placeholder="" name="studentClass" v-model="studentClass" class="form-control">
                  </div>
                </div>
                <div class="profile-photo">
                  <div class="photo-box">
                    <input class="avatar-data" id="avatar_data" name="avatar_data" type="hidden">
                    <input type="file" name="dataFile" id="upload-logo" accept="image/jpeg,image/jpg,image/png" style="display:none;">
                    <img id="pic-logo" :src="this.$store.state.userInfo.memberPhotourl?this.$store.state.baseHost+'/fs/download?storeKey='+this.$store.state.userInfo.memberPhotourl:require('../assets/photo0.jpg')" >
                  </div>
                  <div class="photo-link">
                      <!-- <button type="button" class="upload-btn btn btn-default">更换图像</button> -->
                      <label class="upload-btn btn btn-default" for="uploads" style="display:inline-block;width: 70px;padding: 0;text-align: center;line-height: 28px;">更換影像</label>
                      <input type="file" name="dataFile" id="uploads" :value="imgFile" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg($event, 1)">
                  </div>
                </div>
                <div class="info-end">
                  <button type="button" class="btn btn-primary" @click="updateInfo()">保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
		
		<!-- 上传图片 -->
	    <div class="u-mask" v-if="showMask">
	        <div class="u-wrapper">
	            <div class="head">
	                <span>上傳</span>
	                <a id="u-close" href="javascript:void(0)" @click="cancal()"></a>
	            </div>
	            
	            <div class="content clearfix">
	                <div class="left">
	                    <div class="clipArea">
	                        <div class="cropper">
                                <vueCropper
                                ref="cropper"
                                :img="option.img"
                                :outputSize="option.size"
                                :outputType="option.outputType"
                                :info="true"
                                :full="option.full"
                                :canMove="option.canMove"
                                :canMoveBox="option.canMoveBox"
                                :original="option.original"
                                :centerBox="option.centerBox"
                                :autoCrop="option.autoCrop"
                                :autoCropWidth="option.autoCropWidth"
                                :autoCropHeight="option.autoCropHeight"
                                :fixedBox="option.fixedBox"
                                @realTime="realTime"
                                @imgLoad="imgLoad"
                                ></vueCropper>
                            </div>
	                    </div>
	
	                    <div class="tools clearfix">
                             <!-- <label class="btn btn-orange" for="uploads" style="display:inline-block;width: 70px;padding: 0;text-align: center;line-height: 28px;">选择图片</label>
                            <input type="file" id="uploads" :value="imgFile" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg($event, 1)"> -->
                            <!-- <input type="button" class="oper" style="height:20px;width:23px;font-size:20px;margin:3px 5px;" value="+" title="放大" @click="changeScale(1)">
                            <input type="button" class="oper" style="height:20px;width:23px;font-size:20px;margin:3px 5px;" value="-" title="缩小" @click="changeScale(-1)">
                            <input type="button" class="oper" style="height:20px;width:23px;font-size:20px;margin:3px 5px;" value="↺" title="左旋转" @click="rotateLeft"> -->
                            <!-- <input type="button" class="oper" style="height:20px;width:23px;font-size:20px;margin:3px 5px;" value="↻" title="右旋转" @click="rotateRight"> -->
                            <!-- <input type="button" class="oper" style="height:20px;width:23px;font-size:20px;margin:3px 5px;" value="↓" title="下载" @click="down('blob')"> -->
	                        <span class="pull-right">
	                        	<img src="../assets/zoom.png" alt="" class="zoom" @click="changeScale(1)">
	                            <img src="../assets/narrow.png" alt="" class="narrow" @click="changeScale(-1)">
	                            <img src="../assets/rotate.png" alt="" class="rotate" @click="rotateLeft">
	                        </span>
	                    </div>
	                </div>
	                <div class="right">
	                    <div class="small-pic">
                            <div :style="previews.div" class="preview">
                                <img :src="previews.url" :style="previews.img">
                            </div>
                        </div>
	                    <p>預覽</p>
	                </div>
	            </div>
	            <div class="footer">
	                <button id="u-cancel" class="btn" @click="cancal()">取消</button>
	                <button id="u-confirm" class="btn" @click="finish('blob')">確定</button>
	            </div>
	        </div>
		</div>
        <bottom-bar></bottom-bar>
    </div>  
</template>

<script>
import navBar from "@/components/navftBar";
import bottomBar from "@/components/bottomftBar";
import studentInfo from "@/components/studentCenter/studentInfo";
import studentSidebar from "@/components/studentCenter/ftstudentSidebar";

import { VueCropper } from "vue-cropper";


export default {
    components: {
        navBar,
        bottomBar,
        studentInfo,
		    studentSidebar,
        VueCropper,
    },
    data() {
      return {
        //帐号信息
        orgName:this.$store.state.userInfo.student.orgName,//机构名称
        memberId: this.$store.state.userInfo.memberId,//会员id
        memberName : this.$store.state.userInfo.memberName,//会员名称	
        memberPhone	: this.$store.state.userInfo.memberPhone,//手机号
        memberEmail : this.$store.state.userInfo.memberEmail,//电子邮箱	
        memberIdcard : this.$store.state.userInfo.memberIdcard,//身份证号	
        memberBirthday : this.$store.state.userInfo.memberBirthday,//出生日期	
        memberGender : this.$store.state.userInfo.memberGender,	//性别：1-男；2-女	
        memberIntroduction : this.$store.state.userInfo.memberIntroduction,	//简介

        //学生信息，id与会员id不同
        studentId : this.$store.state.userInfo.student.studentId,//学生id

        //学院信息
        studentCollege:this.$store.state.userInfo.student.studentCollege,//学院	
        studentMajor :this.$store.state.userInfo.student.studentMajor,//专业	
        studentClass :this.$store.state.userInfo.student.studentClass,//班级

        showMask: false,//头像裁剪弹窗
        headImg:'',
        //剪切图片上传
        crap: false,
        previews: {},
        option: {
          img: '',
          outputSize:1, //剪切后的图片质量（0.1-1）
          full: false,//输出原图比例截图 props名full
          outputType: 'png',
          canMove: true, 
          original: false, 
          canMoveBox: false, 
          autoCrop: true, 
          autoCropWidth: 200, 
          autoCropHeight: 200, 
          fixedBox: true,
          centerBox:true,
        }, 
        fileName:'',  //本机文件地址
        downImg: '#',
        imgFile:'',
        uploadImgRelaPath:'', //上传后的图片的地址（不带服务器域名）
      }
    },
    methods: {
      //更新个人资料
      updateInfo(){
        let member = {
          memberId: this.memberId,
          memberName: this.memberName,
          memberPhone: this.memberPhone,
          memberEmail: this.memberEmail,
          memberIdcard: this.memberIdcard,
          memberBirthday: this.memberBirthday,
          memberGender: this.memberGender,
          memberIntroduction: this.memberIntroduction,
          memberType: 0,
          student: {
            studentId: this.studentId,
            studentRealname: this.memberName,
            studentPhone: this.memberPhone,
            studentEmail: this.memberEmail,
            studentIdcard: this.memberIdcard,
            studentBirthday: this.memberBirthday,
            studentGender: this.memberGender,
            studentIntroduction: this.memberIntroduction,
            studentCollege: this.studentCollege,
            studentMajor: this.studentMajor,
            studentClass: this.studentClass,
          }
        }
        // console.log(JSON.stringify(member))

        this.$axios({
          method:'POST',
            url: '/api/appuser/updateInfo',
            headers: {
                'Content-Type': 'application/json',//设置请求头请求格式为JSON
                'token': localStorage.SSMH_TOKEN //设置token 其中K名要和后端协调好
            },
            data:JSON.stringify(member)
          }).then((res)=>{
          // console.log(res.data);
          if(res.data.result){
					  this.$store.commit("updateUserInfo", res.data.resultData);
            this.$message({
              showClose: true,
              message: res.data.resultMsg,
              type: 'success'
            });
          }else{
            this.$message({
              showClose: true,
              message: res.data.resultMsg,
              type: 'error'
            });
          }
        }).catch((res)=>{
          console.log(res);
        })
      },
      logout(){
			this.$confirm('是否登出?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
        }).then(() => {
        //点击确定的操作(调用接口)
        this.$post("/api/appuser/logout").then((res) => {
          console.log(res);
          if (res.result) {
            this.$store.commit("LOG_OUT");
            this.$router.push("/login/"+this.$store.state.oid);
          }
        });
      }).catch(() => {
      //点取消的提示
          return;
      });
		},
      //放大/缩小
      changeScale(num) { 
        console.log('changeScale')
        num = num || 1; 
        this.$refs.cropper.changeScale(num); 
      }, 
      //坐旋转
      rotateLeft() { 
        console.log('rotateLeft')
        this.$refs.cropper.rotateLeft(); 
      }, 
      //右旋转
      rotateRight() { 
        console.log('rotateRight')
        this.$refs.cropper.rotateRight(); 
      }, 
      //上传图片（点击上传按钮）
      finish(type) { 
        console.log('finish')
        let _this = this;
        let formData = new FormData();
        // 输出 
        if (type === 'blob') { 
          this.$refs.cropper.getCropBlob((data) => { 
            let img = window.URL.createObjectURL(data)
            this.model = true; 
            this.modelSrc = img; 
            formData.append('dataFile',data,this.fileName);
            this.$axios({
                method:'POST',
                url: '/api/appuser/updatePhoto',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',//设置请求头请求格式为JSON
                    'token': localStorage.SSMH_TOKEN //设置token 其中K名要和后端协调好
                },
                data: formData
              }).then((res)=>{
              if(res.data.result){
                this.$message({
                  showClose: true,
                  message: res.data.resultMsg,
                  type: 'success'
                });
                // let photoUrl = this.$store.state.baseHost + '/fs/download?storeKey=' + res.data.resultData;
					      this.$store.commit("updateUserInfoPhotoUrl", res.data.resultData);
                this.showMask = false;
              }else{
                this.$message({
                  showClose: true,
                  message: res.data.resultMsg,
                  type: 'error'
                });
              }
            }).catch((res)=>{
              console.log(res);
            })
            
          }) 
        } else { 
          this.$refs.cropper.getCropData((data) => { 
            this.model = true; 
            this.modelSrc = data; 
          }) 
        } 
      }, 
    //   取消按钮
    cancal(){
        this.showMask = false
    },
    // 实时预览函数 
    realTime(data) { 
      console.log('realTime')
      this.previews = data 
    }, 
    //下载图片
    down(type) { 
      console.log('down')
      var aLink = document.createElement('a') 
      aLink.download = 'author-img' 
      if (type === 'blob') { 
        this.$refs.cropper.getCropBlob((data) => { 
          this.downImg = window.URL.createObjectURL(data)
          aLink.href = window.URL.createObjectURL(data) 
          aLink.click() 
        }) 
      } else { 
        this.$refs.cropper.getCropData((data) => { 
          this.downImg = data;
          aLink.href = data; 
          aLink.click() 
        }) 
      } 
    }, 
    //选择本地图片
    uploadImg(e, num) { 
      console.log('uploadImg');
      var _this = this;
      //上传图片 
      var file = e.target.files[0];
      console.log(file)
      _this.fileName = file.name;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) { 
        alert('圖片類型必須是.gif,jpeg,jpg,png,bmp中的一種') 
        return false 
      } 
      var reader = new FileReader(); 
      reader.onload =(e) => { 
        let data; 
        if (typeof e.target.result === 'object') { 
          // 把Array Buffer转化为blob 如果是base64不需要 
          data = window.URL.createObjectURL(new Blob([e.target.result])) 
        } 
        else { 
          data = e.target.result 
        }
        if (num === 1) { 
          _this.option.img = data 
        } else if (num === 2) { 
          _this.example2.img = data 
        } 
      } 
      // 转化为base64 
      // reader.readAsDataURL(file) 
      // 转化为blob 
      reader.readAsArrayBuffer(file);
      setTimeout(()=>{
        this.showMask = true;
      },100)
      
    }, 
    imgLoad (msg) { 
      console.log('imgLoad')
      console.log(msg) 
    }
  }, 
}
</script>

<style lang="less" scoped>
.container{width: 1200px;overflow: hidden;margin: 0 auto;position: relative;z-index: 2;}

.uc-wrap{
	position: relative;
	z-index: 999;
	margin-top: -46px;
	padding-bottom: 80px;
}
.uc-panel{
	float: right;
	width: 930px;
	background-color: #FFFFFF;
}
.uc-heading{
	height: 46px;
	border-bottom: 1px solid #DDDDDD;
	margin-left: 20px;
}
.uc-tit{
	position: relative;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-tit::after{
	height: 1px;
	width: 24px;
	position: absolute;
	background-color: #02354b;
	left: 0;
	bottom: -1px;
	content: ' ';
}
.uc-panel-body{
	padding: 20px 40px 20px 20px;
}

.gm-empty{
	text-align: center;
	height: 334px;
	padding: 80px 0;
}
.gm-empty p{
	text-align: center;
	color: #999999;
	font-size: 16px;
	margin-top: 20px;
}

.profile-row{
	position: relative;
}
.profile-label{
	position: absolute;
	left: 0;
	top: 0;
	font-size: 16px;
	color: #888e99;
	width: 110px;
	line-height: 56px;
	text-align: right;
}
.profile-row .val{
	padding-left: 150px;
}
.profile-row .form-control{
	border: 0;
	border-radius: 0;
	box-shadow: 0 0 0 transparent;
	border-bottom: 1px solid #f1f1f1;
	height: 56px;
	padding: 0;
	width: 100%;
	font-size: 16px;
}
.profile-row textarea.form-textarea{
	height: 176px;
	width: 100%;
	border-radius: 6px;
	border: 0;
	outline: none;
	padding: 12px;
	resize: none;
	background-color: #f7f9fa;
  margin-top: 15px;
  box-sizing: border-box;
}
.profile-row .v-radios{
	padding: 15px 0;
}
.uc-profile{
	position: relative;
}
.uc-profile .profile-photo{
	position: absolute;
	right: 0;
	top: 0;
}
.photo-box img{
	width: 180px;
	height: 180px;
	border-radius: 10px;
}
.photo-link{
	text-align: center;
	color: #198cff;
	font-size: 14px;
	margin-top: 10px;
}
.info-end{
	padding: 40px 0;
	text-align: center;
}
.info-end .btn-primary,
.info-end .btn-primary:hover{
	width: 160px;
	height: 42px;
	font-size: 16px;
	background-color: #198cff;
	border-color: #198cff;
	color: #FFFFFF;
    border-radius: 4px;
    border:none;
    outline: none;
    cursor: pointer;
}
.v-radios{
	padding: 10px 0;
}
.radio-inline input[type=radio]{
	width: 16px;
	height: 16px;
	border: 0;
	outline: 0;
	-webkit-appearance: none;
	background-color: transparent;
	cursor: pointer;
}
.radio-inline input[type=radio]::before{
	display: block;
	width: 16px;
	height: 16px;
	content: ' ';
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../assets/radio.png);
}
.radio-inline input[type=radio]:checked::before{
	background-image: url(../assets/radioed.png);
}
.radio-inline *{
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}
.v-radios .radio-inline{
	margin-right: 20px;
}

/* 裁剪头像 */
.u-mask { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 9999; }
.u-mask .u-wrapper { background-color: #ffffff; position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); border-radius: 4px; overflow: hidden; width: 500px; }
.u-mask .u-wrapper .head { line-height: 48px; border-bottom: 1px solid #e1e1e1; font-size: 18px; padding: 0 20px; position: relative; }
.u-mask .u-wrapper .head #u-close { background-image: url("../assets/close-2.png"); background-position: center; background-repeat: no-repeat; background-size: 100% 100%; width: 16px; height: 16px; position: absolute; right: 20px; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); }
.u-mask .u-wrapper .content { padding: 20px; overflow: hidden;}
.u-mask .u-wrapper .content > div { float: left; }
.u-mask .u-wrapper .content .left { width: 300px; margin-right: 20px; }
.u-mask .u-wrapper .content .left .clipArea { width: 300px; height: 300px; background: #F7F7F7; }
.u-mask .u-wrapper .content .left .clipArea img { max-width: 100%; }
.u-mask .u-wrapper .content .left .tools { line-height: 30px; }
.u-mask .u-wrapper .content .left .tools span { display: inline-block; font-size: 0; }
.u-mask .u-wrapper .content .left .tools span img { cursor: pointer; }
.u-mask .u-wrapper .content .left .tools span img:not(:last-child) { margin-right: 20px; }
.u-mask .u-wrapper .content .right .small-pic { width: 140px; height: 140px; border-radius: 50%; overflow: hidden; }
.u-mask .u-wrapper .content .right p { text-align: center; margin-top: 20px; font-size: 18px; }
.u-mask .u-wrapper .footer { text-align: right; padding: 16px 20px; font-size: 0; background-color: #F7F7F7; }
.u-mask .u-wrapper .footer #u-confirm { border: none; border-radius: 4px; background-color: #38C1EE; color: #ffffff; line-height: 36px; padding: 0; width: 120px; text-align: center; font-size: 16px; margin-left: 10px; border-radius: 2px;outline: none; }
.u-mask .u-wrapper .footer #u-confirm:hover { background-color: #33b2dc; }
.u-mask .u-wrapper .footer #u-cancel { border: none; border-radius: 4px; background-color: #E7E7E7; color: #333333; line-height: 36px; padding: 0; width: 68px; text-align: center; font-size: 16px; border-radius: 2px; outline: none;}
.u-mask .u-wrapper .footer #u-cancel:hover { color: #f1403c; }

.info {
    width: 720px;
    margin: 0 auto;
    .oper-dv {
      height:20px;
      text-align:right;
      margin-right:100px;
      a {
        font-weight: 500;
        &:last-child {
          margin-left: 30px;
        }
      }
    }
    .info-item {
      margin-top: 15px;
      label {
        display: inline-block;
        width: 100px;
        text-align: right;
      }
      .sel-img-dv {
        position: relative;
        .sel-file {
          position: absolute;
          width: 90px;
          height: 30px;
          opacity: 0;
          cursor: pointer;
          z-index: 2;
        }
        .sel-btn {
          position: absolute;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
.clipArea{
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    .cropper{
      width: 300px;
      height: 300px;
    }
    .show-preview{
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;
      .preview{
        overflow: hidden;
        border-radius: 50%;
        border:1px solid #cccccc;
        background: #cccccc;
        margin-left: 40px;
      }
    }
  }
  .clipArea .show-preview .preview {margin-left: 0;}
</style>