<template>
	<div>
		<!-- pc端 -->
		<div class="hidden-md-and-down">
			<navft-bar v-bind:currentId="5"></navft-bar>
			<div class="main">
				<div class="filter-cells">
					<div class="container">
						<div class="filter-row cleafix">
							<div class="filter-body">
								<div class="filter-list-first category_wrap">
									<span class="filter-name">課程分類：</span>
									<ul class="category">
										<li v-for="item in tabList" :key="item.id"
											:class="{'active': currentID==item.id}" @click="handleClickTab(item.id)">
											<a href="javascript:void(0)">{{item.names}}</a>
										</li>
									</ul>
									<span class="filter-person">觀&nbsp;看&nbsp;人&nbsp;數：{{personNum}}</span>
								</div>
								
							</div>
						</div>
					</div>
				</div>
				<div class="inner-wrap">
					<div class="container" style="position: relative;">
						<div class="" v-if="showListLoading"
							style="display: flex;justify-content: center;min-height:400px;align-items: center;">
							<a-spin size="large" tip="正在玩命加載中..." />
						</div>
						<div v-if="showPcEmpty" slot="empty">
							<van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
								description="暫無課程~" />
						</div>
						<div class="row ke-rows" v-if="!showListLoading&&courseListArr.length">
							<div class="col-md-3" v-on:click="toLink(item.courseId)"
								v-for="(item,index) in courseListArr" style="cursor: pointer;" :key="index">
								<div class="les-cell">
									<div class="les-photo">
										<a href="javascript:void(0);" class="img_wrap">
											<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + item.courseCoverUrl"
												:onerror="defaultCourseCoverImg" class="cover_pic"></a>
										<div class="les-downtime">{{item.coursePeriod}}課時</div>
									</div>
									<div class="les-grid">
										<div class="les-avatar">
											<img src="../assets/tou1.jpg">
										</div>
										<a href="javascript:void;" class="les-header">
											
											<el-tag effect="light">{{item.categoryName}}</el-tag>
											<span class="title" :title="item.title"
												style="font-size:16px;">{{item.courseName}}</span>
											<div class="desc">
												{{item.courseAbstract}}
											</div>
											<div class="a_intro">
												<template v-if="item.courseLevel == 1">
													<span>入門</span>
												</template>
												<template v-else-if="item.courseLevel == 2">
													<span>基礎</span>
												</template>
												<template v-else-if="item.courseLevel == 3">
													<span>進階</span>
												</template>
												<template v-else-if="item.courseLevel == 4">
													<span>高級</span>
												</template>
												<template v-else-if="item.courseLevel == 5">
													<span>專家</span>
												</template>
												<template v-else>
													<span></span>
												</template>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex;justify-content: center;margin-top:30px;" v-if="!queryTotal==0">
							<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
								:total="queryTotal" :current-page="currentPage" :page-size="pageSize">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
			<bottomft-bar></bottomft-bar>
		</div>
		<!-- 移动端 -->
		<div class="hidden-lg-and-up phone_padding"   style="overflow: inherit;">
			<phoneft-header :phoneCurrentId="5" />
			<div class="xs_screen_wrap">
				<van-dropdown-menu active-color="#1989fa">
					<van-dropdown-item title="課程分類" @close="close1">
						<div class="screen_section">
							<div class="xs_screen_item_wrap">
								<cube-checker v-model="checkerDirectionValue" :options="checkerDirectionOptions" type="radio" />
							</div>
							<div class="screen_btn_group">
								<van-button type="default" @click="reset1">重置</van-button>
								<van-button type="info" @click="confirm1">確定</van-button>
							</div>
						</div>
					</van-dropdown-item>
				</van-dropdown-menu>
				<div class="person-yd">觀看人數：{{personNum}}</div>
			</div>
			<div class="xs_kc_main">
				<div class="infinite-list-wrapper">
					<van-pull-refresh v-model="refreshing"  @refresh="onRefresh">
					<van-list 
						v-model="loading"
						:finished="finished"
						:finished-text="finishedText"
						:error.sync="error"
						error-text="請求失敗，點擊重新加載"
						@load="onLoad"
						
					>
						  <ul class="list">
							<li v-for="item in list" class="list-item" @click="toLink(item.courseId)" :key="item.courseId">
								<p class="list-item_left">
									<img :src=" $store.state.baseHost + '/fs/download?storeKey=' + item.courseCoverUrl" :onerror="defaultCourseCoverImg"/>
								</p>
								<div class="list-item_right">
									<p class="list-item_right_title">
										{{item.courseName}}
									</p>
									
									<p class="list-item_right_info">
										<template v-if="item.courseLevel == 1">
											<span>入門</span>
										</template>
										<template v-else-if="item.courseLevel == 2">
											<span>基礎</span>
										</template>
										<template v-else-if="item.courseLevel == 3">
											<span>進階</span>
										</template>
										<template v-else-if="item.courseLevel == 4">
											<span>高級</span>
										</template>
										<template v-else-if="item.courseLevel == 5">
											<span>專家</span>
										</template>
										<template v-else>
											<span></span>
										</template>
										<span>{{item.categoryName}}</span>
									</p>
								</div>
							</li>
						  </ul>
					</van-list>
					</van-pull-refresh>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navftBar from "@/components/navftBar";
	import bottomftBar from "@/components/bottomftBar";
	import { isWeiXin, isMobile } from '../utils/userAgent.js'
import { constants } from 'zlib';
	export default {
		components: {
			navftBar,
			bottomftBar
		},
		data() {
			return {
				searchValue: '',
				// 移动端课程list列表
				list: [],
				// 移动端列表loading
				loading: false,
				// 移动端列表是否全部加载完
				finished: false,
				// 下拉刷新
				refreshing: false,
				error: false,
				// 移动端当前页码
				phoneCurrentPage: 1,
				phonepageSize: 10,
				phoneTotal: 0,
				finishedText: '没有更多了',
				// 方向
				checkerDirectionValue: '',
				// 筛选弹出层单选项配置
				checkerDirectionOptions: [{
						value: '',
						text: '全部'
					},
				],
				// 分类
				checkerClassificationValue: 1,
				// 难度
				checkerDifficultyValue:1,
				// currentID: 0,
				currentID: "",
				currentID2: "",
				currentID3: "",
				defaultCourseCoverImg: 'this.src="' + require("../assets/image404.gif") + '"',
				// 控制loading的显隐
				showListLoading: false,
				//  课程
				courseListArr: [],
				showPcEmpty: false,
				tabList: [{
						id: "",
						names: "全部"
					},
				],
				currentPage: 1,
				pageSize: 20,
				queryTotal: 0,
				personNum: 358
			};
		},
		methods: {
			search(value){
				alert(value)
			},
			/**
			 * 方向筛选确定
			 */
			confirm1(){
					//console.log('方向value',this.checkerDirectionValue);
					this.phoneCurrendId = this.checkerDirectionValue
					this.cancel()
					this.getList()
					// 关闭下拉
					document.querySelector('.van-dropdown-item .van-overlay').click()
			},
			close1(){
				this.confirm1()
			},
			/**
			 * 分类筛选确定
			 */
			confirm2(){

			},
			/**
			 * 难度筛选确定
			 */
			confirm3(){

			},
			/**
			 * 方向筛选重置
			 */
			reset1(){
				this.checkerDirectionValue = ''
			},
			/**
			 * 分类筛选重置
			 */
			reset2(){
				this.checkerClassificationValue = 1
			},
			/**
			 * 难度筛选重置
			 */
			reset3(){
				this.checkerDifficultyValue = 1
			},
			/**
			 * 移动端获取课程列表数据（首次加载）
			 */
			 async getList() {
				this.phoneCurrentPage = 1;
				 try {
				 	// http://127.0.0.1:8098/website/paging/course
				 	const res = await this.$post("/website/paging/ftcourse", {
				 		currentPage: this.phoneCurrentPage,
				 		pageSize: this.phonepageSize,
				 		orderby: ["date", "asc"],
						courseCategory: this.phoneCurrendId,
						courseOrgId: this.$store.state.oid,
						courseMajor: this.$store.state.kcMajor
				 	});
					if(res.result){
						if (this.refreshing) {
							this.refreshing = false;
						}
						this.loading = false
						this.list = res.resultData.rows
						this.getCourseCategory();
						//console.log(this.list);
					} else {
						this.$toast.fail(res.resultMsg);
					}
				 } catch (err) {
				 	console.error(err);
					this.error = true;
				 } finally {
				 	
				 }
			},
			/**
			 * 下拉加载
			 */
			async getListMore() {
				this.phoneCurrentPage++
				try {
					// http://127.0.0.1:8098/website/paging/course
					const res = await this.$post("/website/paging/ftcourse", {
						currentPage: this.phoneCurrentPage,
						pageSize: this.phonepageSize,
						orderby: ["date", "asc"],
						courseCategory: this.phoneCurrendId,
						courseOrgId: this.$store.state.oid,
						courseMajor: this.$store.state.kcMajor
					});
					if(res.result){
						this.loading = false
						this.list = [...this.list,...res.resultData.rows]
						if(this.phoneCurrentPage*this.phonepageSize>=res.resultData.total){
							this.finished = true
							this.finishedText = "沒有更多了"
						}
					} else {
						this.$toast.fail(res.resultMsg);
					}
				} catch (err) {
					console.error(err);
					this.error = true;
				} finally {
					
				}
			},
			onRefresh() {
				// 清空列表数据
				this.list = [];
				this.phoneCurrentPage = 1
				this.finished = false;
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				this.getList();
			},
			onLoad() {
				//console.log('触底了');
			 	this.getListMore()
			},
			resetScreenOption() {
				this.checkerDirectionValue = 1
				this.checkerClassificationValue = 1
				this.checkerDifficultyValue = 1
			},
			// 清空列表数据
			cancel(){
				this.list = [],
				this.loading = true
				this.finished = false
				this.phoneCurrentPage = 1
			},
			tabs(id) {
				this.currentID = id;
			},
			toLink(courseId) {
				
				 if(isWeiXin() || isMobile()){
					this.$router.push({
						name: "kechengft_detail",
						query: {
							courseId: courseId
						}
				 	});
				 } else {
					let routeUrl = this.$router.resolve({
						name: "kechengft_detail",
						path: "/kechengft_detail",
						query: {
							courseId: courseId
						}
					});
					window.open(routeUrl.href, "_self");
				 }
			},
			handleClickTab(id) {
				this.currentID = id;
				this.currentPage = 1;
				this.getCourseList();
				this.getWatchNum();

			},
			handleClickTab2(id) {
				this.currentID2 = id;
			},
			handleClickTab3(id) {
				this.currentID3 = id;
			},
			/**
			 * @param currPage 当前页码值
			 * 点击分页回调
			 */
			handleCurrentChange(currPage) {
				//console.log(currPage);
				this.currentPage = currPage;
				this.getCourseList();
			},
			async getCourseList() {
				this.showListLoading = true;
				try {
					// http://127.0.0.1:8098/website/paging/course
					const res = await this.$post("/website/paging/ftcourse", {
						currentPage: this.currentPage - 1,
						pageSize: this.pageSize,
						orderby: ["date", "asc"],
						courseCategory: this.currentID,
						courseOrgId: this.$store.state.oid,
						courseMajor: this.$store.state.kcMajor
					});
					//console.log(res);
					this.courseListArr = res.resultData.rows;
					this.queryTotal = res.resultData.total;
					if(this.queryTotal==0){
						this.showPcEmpty = true
					} else {
						this.showPcEmpty = false
					}
					//console.log(this.queryTotal);
				} catch (err) {
					console.error(err);
				} finally {
					this.showListLoading = false;
				}
			},
			async getWatchNum() {
				try {
					// http://127.0.0.1:8098/website/paging/course
					const res = await this.$post("/website/paging/getWatchNum", {
						courseMajor: this.$store.state.kcMajor
					});
					this.personNum = res.resultData;
				} catch (err) {
					console.error(err);
				} finally {
					this.showListLoading = false;
				}
			},
			/**
			 * 获取分类
			 */
			async getCourseCategory(){
				try {
					const res = await this.$post("/website/paging/getKcCategory",{
						category: 'COURSE_CATEGORY',
						orgId: this.$store.state.oid,
						major: this.$store.state.kcMajor
					})
					// console.log(res);
					this.tabList = [{
						id: "",
						names: "全部"
					}];
					this.checkerDirectionOptions = [{
						value: "",
						text: "全部"
					}];
					res.resultData.forEach(item=>{
						// pc端分类筛选数据
						this.tabList.push({
							id: item.dictCode,
							names: item.dictValue
						})
						// 移动端分类筛选数据
						this.checkerDirectionOptions.push({
							value: item.dictCode,
							text: item.dictValue
						})
					})
				} catch(err){

				}
			}
		},
		created(){
		},
		mounted() {
			if(isWeiXin() || isMobile()){
				window.addEventListener('scroll', () => {
					this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
				}) 
				this.cancel()
				let timer = setInterval(() => {
					if(this.$store.state.kcMajor!=''){
			 			this.getList()
						clearInterval(timer);
					}
				}, 50);
			} else {
				// 延迟1秒执行
				this.showListLoading = true;
				let timer = setInterval(() => {
					if(this.$store.state.kcMajor!=''){
						this.getCourseList();
						this.getCourseCategory();
						clearInterval(timer);
					}
				}, 50);
				
			}
		},
		watch:{
			$route(to, from) {
				if (to.params.code !== from.params.code) {
					this.$store.commit('updateMajor', to.params.code);
					if(isWeiXin() || isMobile()){
						this.getList()
					}else{
						this.getCourseCategory();
						this.getCourseList();
						this.getWatchNum();
					}

				}
			}
		}
	};
</script>

<style scoped lang="less">
	.main {
		/* overflow: hidden; */
	}

	.inner-wrap {
		padding: 50px 0;
		background-color: #f5faff;
	}

	.container {
		width: 1200px;
		margin: 0 auto;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}

	.col-md-3 {
	}

	.filter-row {
		display: flex;
	}

	.search {
		width: 450px;
	}

	.filter-cells {
		width: 100%;
		margin: 0 auto;
		box-shadow: 0 4px 8px 0 rgba(28, 31, 33, .1);
		position: relative;
		z-index: 9;
		padding: 30px 0;
		background: #fff;
		border-top: 1px solid #eee;
	}

	.filter-hd {
		float: left;
		width: 170px;
		line-height: 30px;
		font-size: 26px;
	}

	.filter-body {
		width: 100%;
		box-sizing: border-box;
	}

	.category_wrap {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		line-height: 32px;
	}

	.filter-name {
		font-size: 14px;
		font-weight: 600;
		color: #2d2d2d;
		margin-right: 20px;
	}

	.category {
		overflow: hidden;
		flex: 1;
		display: flex;
	}

	.category li {
		margin-right: 20px;
	}

	.category li a {
		display: inline-block;
		color: #666666;
		padding: 0 14px;
		border-radius: 30px;
	}

	.category li a:hover {
		color: #198cff;
	}

	.category li.active a {
		background-color: #198cff;
		color: #fff;
		border-radius: 17px;
	}

	.ke-rows {
		margin: 0 -12px;
	}

	.ke-rows>div {
		margin: 0 12px;
		margin-bottom: 25px;
	}

	.les-cell {
		background-color: #fff;
		border-radius: 6px;
		overflow: hidden;
		transition: 0.4s all;
		width: 280px;
		box-shadow: 0 12px 20px 0 rgba(95 ,101 ,105,.1);
	}

	.cover_pic {
		transition: all 0.5s;
	}

	.les-cell:hover {
		transform: translateY(-10px);
	}

	.les-cell:hover .cover_pic {
		transform: scale(1.3);
	}

	.les-photo {
		position: relative;
	}

	.img_wrap {
		width: 280px;
		height: 157px;
		display: block;
		overflow: hidden;
	}

	.les-photo img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.les-photo .les-downtime {
		line-height: 18px;
		display: block;
		position: absolute;
		right: 10px;
		bottom: 10px;
		background-color: rgba(0, 0, 0, 0.4);
		color: #fff;
		font-size: 12px;
		padding: 0 5px;
	}

	.les-grid {
		position: relative;
		padding: 50px 15px 15px 15px;
	}

	.les-foot {
		line-height: 20px;
		height: 20px;
	}

	.les-foot .les-time {
		float: left;
		font-size: 12px;
		color: #999999;
		line-height: 24px;
	}

	.les-foot .les-price {
		float: right;
		font-size: 18px;
		color: #e02727;
	}

	.les-foot .les-price .sm {
		font-size: 12px;
	}

	.les-header {
		display: block;
		margin-bottom: 12px;
		color: #333333;
	}

	.les-header span {
		display: inline-block;
		vertical-align: middle;
	}

	.les-header .tag {
		background-color: #ddeeff;
		border-radius: 3px;
		color: #198cff;
		padding: 0 6px;
		font-size: 13px;
		margin-right: 5px;
	}

	.desc {
		color: #999;
		margin: 7px 0;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		min-height: 38px;
	}

	.les-header .title {
		display: block;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		color: #545c63;
	}

	.a_intro {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		color: #9199a1;
	}

	.les-avatar {
		position: absolute;
		top: -30px;
		left: 0;
		right: 0;
		padding: 0 15px;
		vertical-align: bottom;
		line-height: 1;
	}

	.les-avatar img {
		width: 66px;
		height: 66px;
		border-radius: 55px;
		border: 3px solid #ffffff;
	}

	.les-avatar .v-name {
		display: inline-block;
		color: #999999;
		vertical-align: bottom;
		padding: 10px;
	}

	.tel {
		float: right !important;
		font-size: 19px;
		margin-top: 5px;
	}

	.tel i {
		color: #4f90d1;
		margin-right: 5px;
	}

	.filter-cells /deep/ .ant-input-wrapper {
		height: 100%;
	}

	.filter-cells /deep/ .ant-input:placeholder-shown {
		height: 100%;
	}
	.filter-person{
		color: red;
		font-size: 18px;
	}
	/* 移动端 */
	.clear_box {
	}
	.xs_screen_wrap {
	}
	.xs_screen_item_wrap {
		padding: 0 .4rem 0.26666667rem;
		background: #fff;
		border-bottom: 1px solid #eee;
	}
	.screen_section {

	}
	.screen_btn_group {
		padding: .2rem .4rem;
		display: flex;
		justify-content: space-around;
		button {
			width: 45%;
		}
	}
	.screen_item {
		padding-bottom: 0.58666667rem;
	}
	.screen_item_label {
		display: block;
		width: 100%;
		font-size: .42666667rem;
		color: #2B333B;
	}

	.cube_checker_wrap {
		padding: .3rem 0;
	}

	.xs_screen_item_wrap /deep/.cube-checker-item {
		// border: 1px solid #B7BBBF;
		border-radius: 0.10666667rem;
		margin-right: 1.2%;
		margin-left: 1.2%;
		box-sizing: border-box;
		margin-top: 0.26666667rem;
		color: #5e5e5e;
		background: #f3f5f7;
	}

	.xs_screen_item_wrap /deep/ .cube-checker-item_active {
		border: 1px solid #1989fa;
		color: #fff;
		background: #1989fa;
	}

	.xs_screen_item_wrap /deep/ .cube-checker-item_active:after {
		border: 0;
	}

	.xs_kc_main {

		.infinite-list-wrapper {
			padding-top: 0.42666667rem;
			padding-bottom: 0.42666667rem;
			padding-left: 0.42666667rem;
			padding-right: 0.42666667rem;

			.list {
				li {
					margin-bottom: 0.53333333rem;
				}

				.list-item {
					display: flex;
					border-bottom: 1px solid #f1f1f1;
					padding-bottom: 0.5rem;
					// align-items: center;
					.list-item_left {
						width: 2.88rem;
						height: 1.92rem;
						border-radius: 0.10666667rem;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.list-item_right {
						margin-left: 0.42666667rem;
						flex: 1;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
					}

					.list-item_right_title {
						color: #2B333B;
						line-height: .56rem;
						margin-top: 0.18666667rem;
						margin-bottom: 0.13333333rem;
						font-weight: 600;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						word-break: break-all;
						word-wrap: break-word;
					}

					.list-item_right_info {
						display: flex;
						justify-content: space-between;

						span {
							color: #71777D;
							font-size: .3rem;
						}
					}
				}
			}
		}
	}
	.search_container {
		width: 100vw;
		height: 100vh;
	}
	.van-list{
		margin-top: 20px;
	}
	.person-yd{
		position: absolute;
		color: red;
		right: 10px;
		margin-top: 10px;
	}
	/* 移动端 */
</style>
