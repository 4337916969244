<template>
  <div
    class="xhy-autograph-box"
    :style="{'width': w100 ? '100%': '','height': h100 ? '100%': ''}"
  >
    <div
      class="autograph-box"
      :style="{'width': wI100 ? '100%': '','height': hI100 ? '100%': '','padding':havapadding?'0px':''}"
    >
      <div
        class="inside"
        id="autograph-box"
        :style="{'height': havInsideBgc? '100%': ''}"
      >
        <div
          v-if="showIcon"
          style="display:flex;width:100%;height:100%;justify-content: center;align-items: center;"
           @click="fullScreenShow"
          id="diaoyong"
        >
            <div v-if="phoneDataimgUrl" style="width:100%;height:100%;overflow: hidden;">
                <img :src="phoneDataimgUrl" alt="" style="width: 100%;height:100%;">
            </div>
            <div v-else style="display:flex;width:100%;height:100%;justify-content: center;align-items: center;">
                <van-icon
                name="edit"
                color="#1989fa"
                size="25"
              />
              <p style="margin-left:10px;font-size:15px;color:rgb(25, 137, 250);">点击签名</p>
            </div>
          </div>
          <canvas
            class="xhy-canvas"
            v-if="showNormalCanvas"
          />
          <span
            @click="againSignature"
            v-if="showReset"
          >重新签名</span>
            </div>
            </div>
            <van-popup
              v-model="isShowFull"
              @close="closeFull"
              :close-on-click-overlay="false"
              close-on-popstate
              safe-area-inset-bottom
              position="left"
              :style="{ height: '100%', width: '100%' }"
              v-if="showPopup"
            >
              <div class="popup-box">
                <div class="popup-fix-header">
                  <van-nav-bar  title="签名"  left-text="< 返回"  @click-left="onClickLeft"/>
                </div>
                <canvas class="xhy-canvasFull" />
                <div class="panel-full" style="text-align:center;padding: .2rem 0">
                  <van-button
                    class="btn-again"
                    type="info"
                    plain 
                    @click="againFull"
                  >重新签名</van-button>
                    <van-button
                      class="btn-confirm"
                      
                      type="info"
                      style="margin-left:.6rem"
                      @click="confirmFull"
                    >确认签名</van-button>
                </div>
              </div>
              </van-popup>
              </div>
</template>

<script>
import SignaturePad from "signature_pad";
export default {
  name: "xhy-autograph",
  props: {
    config: {
      type: Object,
      default: function(){
        return {
          penColor: "black", //笔刷颜色
          minWidth: 1, //最小宽度
        }
      }
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    },
     showPopup: {
      type: Boolean,
      default: false
    },
    showNormalCanvas: {
      type: Boolean,
      default: true
    },
    w100: {
      type: Boolean,
      default: false
    },
    h100: {
      type: Boolean,
      default: false
    },
    wI100: {
      type: Boolean,
      default: false
    },
    hI100: {
      type: Boolean,
      default: false
    },
    havapadding: {
      type: Boolean,
      default: false
    },
    havInsideBgc: {
      type: Boolean,
      default: false
    },
    // 回显图片
    phoneDataimgUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowFull: false, // 是否横屏显示
      signaturePad: null, // 存放竖屏SignaturePad对象
      signaturePadFull: null, // 存放横屏SignaturePad对象
      value: "",
      fullValue: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.signaturePad) {
        let canvas = document.querySelector(".xhy-canvas");
        this.signaturePad = new SignaturePad(canvas, this.config);
        this.signaturePad.onEnd = () => {
          this.$emit("receive", this.signaturePad.toDataURL()); //通知父组件改变。
        };
        canvas.height = document.body.clientHeight / 3;
        canvas.width = document.getElementById("autograph-box").offsetWidth;
      }
    },
    initFull() {
      if (!this.signaturePadFull) {
        let canvas = document.querySelector(".xhy-canvasFull");
        this.signaturePadFull = new SignaturePad(canvas, this.config);
        canvas.height = document.body.clientHeight * 0.82;
        canvas.width = document.body.clientWidth;
      }
    },
    closeFull() {},
    againFull() {
      this.signaturePadFull.clear();
    },
    confirmFull() {
      try {
        let _flag = this.signaturePadFull.isEmpty();
        this.signaturePad.clear();
        if (!_flag) {
          this.fullValue = this.signaturePadFull.toDataURL();
          this.rotateBase64Img(this.fullValue, 0, (res) => {
            let _option = {
              width: document.body.clientWidth - 30,
              height: document.body.clientHeight / 3,
            };
            this.signaturePad.fromDataURL(res, _option);
            this.$emit("receive", res); //通知父组件改变。
            this.isShowFull = false;
          });
        }
      } catch (error) {
        console.log(error);
        this.isShowFull = false;
      }
    },
    fullScreenShow() {
      this.isShowFull = true;
      setTimeout(() => {
        this.initFull();
        let _flag = this.signaturePad.isEmpty();
        this.signaturePadFull.clear();
        if (!_flag) {
          this.value = this.signaturePad.toDataURL();
          this.rotateBase64Img(this.value, 0, (res) => {
            let _option = {
              width: document.body.clientWidth,
              height: document.body.clientHeight * 0.82,
            };
            this.signaturePadFull.fromDataURL(res, _option);
          });
        }
      }, 100);
    },
    againSignature() {
      this.signaturePad.clear();
      this.$emit("receive", ""); //通知父组件改变。
    },
    // base64图片旋转方法
    rotateBase64Img(src, edg, callback) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0,
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function () {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL());
      };
    },
    onClickLeft(){
      this.isShowFull = false
    }
  },
};
</script>

<style lang="less" scoped>
.xhy-autograph-box {
  .autograph-box {
    padding: 1rem;
    // width: 100%;
    height: auto;
    background-color: #fff;
    .inside {
      display: flex;
      position: relative;
      background-color: rgb(242, 242, 242);
      .icon-full {
        position: absolute;
        right: 0;
        top: 0;
        padding: 6px;
      }
      span {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 6px;
      }
    }
  }
  .popup-box {
    background-color: rgb(242, 242, 242);
    // padding-top: 40px;
    .popup-fix-header {
      height: 40px;
    }
    .xhy-canvasFull {
      user-select: none;
      border-bottom: 1px solid #ccc;
    }
  }
}
.van-popup--left {
  z-index: 9999999999 !important;
}
</style>