<template>
  <div>
    <!-- 中小屏幕隐藏 -->
    <div class="form-wrap hidden-md-and-down">
      <div class="org-info" v-if="$store.state.orgData">
        <p>{{ this.$store.state.orgData.orgName }}</p>
      </div>
      <div class="container">
        <div class="row form-rows">
          <div class="col-lg-6" style="width: 55%">
            <div class="form-pic" style="margin-top: 60px">
              <img src="../assets/bg-left.png" />
            </div>
          </div>
          <div class="col-lg-6" style="width: 45%; position: relative">
            <div class="text-box">多專業教學資源庫</div>
            <div class="form-card">
              <div class="form-main" style="height: 480px">
                <ul class="form-tabs" role="tablist">
                  <li role="presentation" class="active">
                    <a href="javascript:void(0)">登錄</a>
                  </li>
                  <li role="presentation">
                    <router-link to="/ft/register">注冊</router-link>
                  </li>
                </ul>
                <div class="tab-content">
                  <div role="tabpanel" class="tab-pane fade in active" id="tt1">
                    <div class="form-inner">
                      <div class="form-row">
                        <div class="v-label">賬號</div>
                        <div class="v-control">
                          <input
                            type="text"
                            class="form-control"
                            id="userNameInp"
                            placeholder="請輸入賬號"
                            v-model="username"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="v-label">密碼</div>
                        <div class="v-control">
                          <input
                            type="password"
                            class="form-control"
                            id="passWordInp"
                            placeholder="請輸入密碼"
                            v-model="password"
                          />
                        </div>
                      </div>
                      <div class="form-row form-code-row">
                        <div class="v-label">驗證碼</div>
                        <div class="v-control">
                          <input
                            type="text"
                            class="form-control"
                            id="testingCodeInp"
                            v-model="vcode"
                            placeholder="請輸入驗證碼"
                          />
                        </div>
                        <a href="javascript:void(0);" class="v-code">
                          <img
                            class="verificationCode"
                            :src="validCodeImg"
                            v-on:click="changeValidCodeImg()"
                          />
                        </a>
                      </div>
                      <div class="form-button">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="login()"
                          :disabled="isAble"
                        >
                          登錄
                        </button>
                        <div
                          id="error-box"
                          style="color: red; padding-top: 5px"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-link">
                  <router-link :to="'/home/' + this.$store.state.oid"
                    >首頁></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大屏隐藏 -->
    <div class="xs_login_form_wrap hidden-lg-and-up">
      <div class="mobile_login_wrap">
          <div class="mobile_top_box">
              <van-icon name="cross" color="#fff" class="mobile_close" size="20px" @click="goHome"/>
              <div class="mobile_top_box_title">多專業教學資源庫</div>
          </div>
          <div class="mobile_bottom_box">
             <div class="mobile_login_form">
                <div class="mobile_tab_title">
                     <div class="mobile_tab_title_item" :class="[type==1?'mobile_tab_title_active':'']" @click="mobileHandleClickTab(1)">
                          登錄 
                     </div>
                     <div class="mobile_tab_title_item" :class="[type==2?'mobile_tab_title_active':'']" @click="mobileHandleClickTab(2)">
                          注冊 
                     </div>
                </div>
                <div class="mobile_tab_content">
                    <template v-if="type==1">
                      <van-form @submit="xsOnSubmit">
                        <van-field
                          v-model="xsUsername"
                          name="xsUsername"
                          label="用戶名"
                          placeholder="用戶名"
                          :rules="[{ required: true, message: '請填寫用戶名' }]"
                        />
                        <van-field
                          v-model="xsPassword"
                          type="password"
                          name="xsPassword"
                          label="密碼"
                          placeholder="密碼"
                          :rules="[{ required: true, message: '請填寫密碼' }]"
                        />
                        <div class="mobile_code">
                          <div style="flex:1">
                                <van-field
                              v-model="xsCyptoCode"
                              type="text"
                              name="xsCyptoCode"
                              placeholder="驗證碼"
                              :rules="[{ required: true, message: '請填寫驗證碼' }]"
                            />
                          </div>
                          <div style="width:130px">
                              <img
                              :src="validCodeImg"
                              @click="changeValidCodeImg"
                            />
                          </div>
                        </div>
                        <div style="margin: 16px;">
                          <van-button round block type="info" native-type="submit" :disabled="mobileSubmitDisabledBtnStatus" 
                          :loading="loginBtnLoadingStatus" loading-text="登錄中...">登錄</van-button>
                        </div>
                      </van-form>
                      <div style="text-align:right;">
                        <router-link :to="'/home/' + this.$store.state.oid" style="font-size: 14px;color: #06f;"
                          >返回首頁</router-link
                        >
                      </div>
                    </template>
                    <template v-if="type==2">
                          <van-form @submit="xsOnRegister">
                        <van-field
                          v-model="xsRegisterUsername"
                          name="xsRegisterUsername"
                          label="賬號"
                          placeholder="請輸入手機號"
                          :rules="[{ required: true, message: '請輸入手機號' },{pattern:/^1[3456789]\d{9}$/,message:'請輸入正確的手機號',trigger: 'onBlur'}]"
                        />
                        <van-field
                          v-model="xsRegisterPassword"
                          type="password"
                          name="xsRegisterPassword"
                          label="密碼"
                          placeholder="請輸入密碼"
                          :rules="[{ required: true, message: '請輸入密碼' }]"
                        />
                        <van-field
                          v-model="xsRegisterConfirmPassword"
                          type="password"
                          name="xsRegisterConfirmPassword"
                          label="確認密碼"
                          placeholder="請輸入確認密碼"
                          :rules="[{ required: true, message: '請輸入確認密碼' }]"
                        />
                        <div class="mobile_code">
                          <div style="flex:1">
                                <van-field
                              v-model="xsRegisterCyptoCode"
                              type="text"
                              name="xsRegisterCyptoCode"
                              placeholder="驗證碼"
                              :rules="[{ required: true, message: '請輸入驗證碼' }]"
                            />
                          </div>
                          <div style="width:130px">
                              <img
                              :src="validCodeImg"
                              @click="changeValidCodeImg"
                            />
                          </div>
                        </div>
                        <div style="margin: 16px;">
                          <van-button round block type="info" native-type="submit" :disabled="mobileRegisterDisabledBtnStatus" 
                          :loading="registerBtnLoadingStatus" loading-text="注冊中...">注冊</van-button>
                        </div>
                      </van-form>
                      <div class="mobile_register_to_login">
                        已有帳號？<span @click="type=1">去登錄</span>
                      </div>
                    </template>  
                </div>
             </div>
          </div>
      </div>
      <p style="font-size:18px;position:fixed;bottom: 30px;left: 50%;transform: translateX(-50%);">{{ this.$store.state.orgData.orgName }}</p>
    </div>
  </div>
</template>

<script>
import { isWeiXin, isMobile } from '../utils/userAgent.js'
export default {
  data() {
    return {
      username: "",
      password: "",
      vcode: "",
      validCodeImg: this.$store.state.baseHost + "/api/appuser/captcha",
      isAble: false,
      // 移动端
      xsUsername: "",
      xsPassword: "",
      xsCyptoCode: "",
      // 移动端登录按钮loading状态
      loginBtnLoadingStatus: false,
      // 移动端登录按钮禁用状态
      mobileSubmitDisabledBtnStatus: false,
      type: 1, // tab选中值
      xsRegisterUsername: '',
      xsRegisterPassword: '',
      xsRegisterConfirmPassword: '',
      xsRegisterCyptoCode: '',
      mobileRegisterDisabledBtnStatus: false,
      registerBtnLoadingStatus: false,
      corgId: this.$store.state.oid
    };
  },
  methods: {
    changeValidCodeImg() {
      this.$get("/api/appuser/captcha").then((res) => {
        // console.log(res);
        this.$store.commit("updateKey", res.key);
        this.validCodeImg = res.image;
      });
    },
    login() {
      if (this.username == "") {
        this.$message({
          showClose: true,
          message: "賬號不能爲空",
          type: "error",
        });
        return;
      }
      if (this.password == "") {
        this.$message({
          showClose: true,
          message: "密碼不能爲空",
          type: "error",
        });
        return;
      }
      if (this.vcode == "") {
        this.$message({
          showClose: true,
          message: "驗證碼不能爲空",
          type: "error",
        });
        return;
      }
      this.isAble = true;
      this.$post("/api/appuser/login", {
        username: this.username,
        password: this.password,
        vcode: this.vcode,
        vkey: this.$store.state.key,
        orgId: this.$store.state.oid,
      })
        .then((res) => {
          // console.log(res);
          if (res.result) {
            this.$message.success(res.resultMsg);
            this.$store.commit("LOG_IN", res.resultData);
            if (this.$route.query.redirect) {
              this.$router.go(-1); //如果携带跳回页面路径
            } else {
              if (res.resultData.user.memberType == 0) {
                //学生登录
                // this.$router.push({name:"index",query:{oid: this.$store.state.oid}});
                this.$router.push({ name: "ft_student_profile" });
              } else {
                //评委登录
                this.$router.push({ name: "pingshen_index" });
              }
            }
          } else {
            this.isAble = false;
            this.changeValidCodeImg();
            this.$message({
              showClose: true,
              message: res.resultMsg,
              type: "error",
            });
          }
          this.isAble = false;
        })
        .catch((res) => {
          console.log(res);
          this.isAble = false;
        });
    },
    /**
     * 移动端tab点击事件
     */
    mobileHandleClickTab(number){
        this.type = number
    },
    /**
     * 移动端登录
     */
    xsOnSubmit(value) {
       console.log(value);
      if(isWeiXin() || isMobile()){
      this.loginBtnLoadingStatus = true;
      this.mobileSubmitDisabledBtnStatus = true
      let { xsUsername, xsPassword, xsCyptoCode } = value;
      this.$post("/api/appuser/login", {
        username: xsUsername,
        password: xsPassword,
        vcode: xsCyptoCode,
        vkey: this.$store.state.key,
        orgId: this.$store.state.oid,
      })
        .then((res) => {
          // console.log(res);
          this.loginBtnLoadingStatus = false;
          this.mobileSubmitDisabledBtnStatus = false;
          if (res.result) {
            this.$toast.success(res.resultMsg);
            this.$store.commit("LOG_IN", res.resultData);
            if (this.$route.query.redirect) {
              this.$router.go(-1); //如果携带跳回页面路径
            } else {
              if (res.resultData.user.memberType == 0) {
                //学生登录
                // this.$router.push({name:"index",query:{oid: this.$store.state.oid}});
                setTimeout(() => {
                  //this.$router.push({ name: "ft_student_profile" });
                  this.$router.push('/home/' + this.$store.state.oid);
                }, 1000);
              } else {
                //评委登录
                setTimeout(() => {
                  this.$router.push({ name: "pingshen_index" });
                }, 1000);
              }
            }
          } else {
            this.xsCyptoCode = "";
            this.changeValidCodeImg();
            this.$toast.fail(res.resultMsg);
          }
        })
        .catch((res) => {
          console.log(res);
          this.loginBtnLoadingStatus = false;
          this.mobileSubmitDisabledBtnStatus = false;
          this.$toast.fail("請求失敗");
        });
      }
    },
    /**
     * 移动端注册
     */
    xsOnRegister(){
      if(this.xsRegisterPassword.length<6){
          return this.$toast.fail("密碼不能少於6位")
      }
      if (this.xsRegisterPassword != this.xsRegisterConfirmPassword) {
          return this.$toast.fail("兩次密碼輸入不一致")
      }
      this.registerBtnLoadingStatus = true
      this.mobileRegisterDisabledBtnStatus = true
      this.$post("/api/appuser/register", {
          orgId: this.$store.state.oid,
          memberAccount: this.xsRegisterUsername,
          memberPassword: this.xsRegisterPassword,
          vcode: this.xsRegisterCyptoCode,
          vkey: this.$store.state.key,
      }).then((res)=>{
        this.registerBtnLoadingStatus = false
        this.mobileRegisterDisabledBtnStatus = false
        if (res.result) {
            this.$toast.success(res.resultMsg);
            setTimeout(() => {
              this.type = 1
            }, 1000);
          } else {
            this.xsRegisterCyptoCode = "";
            this.changeValidCodeImg();
            this.$toast.fail(res.resultMsg);
          }
      }).catch((err)=>{
        this.registerBtnLoadingStatus = false
        this.mobileRegisterDisabledBtnStatus = false
        this.$toast.fail("請求失敗");
      })
    },
    goHome(){
       this.$router.push('/home/'+ this.$store.state.oid)
    }
  },
  created() {
    this.changeValidCodeImg();
    let oid = this.$route.params.oid;
    if (oid.indexOf("64") != -1) {
      oid = 64;
    }
    if (oid) {
      this.$store.commit("updateOid", oid);
      this.$post("/api/common/getOrg", { orgId: oid })
        .then((res) => {
          // console.log(res);
          if (res.result) {
            this.$store.commit("updateOrg", res.resultData);
          }
        })
        .catch((res) => {
          console.log(res);
          // this.$message.error(res);
        });
    }
  },
  mounted() {
    var that = this;
    if(isWeiXin() || isMobile()){
      console.log('移动端');
    } else {
      console.log('pc端');
      window.onkeydown = function () {
        if (event.keyCode === 13) {
          //dao车键的du键值zhi为13
          that.login(); //模拟按下daosubmit确认按钮
        }
      };
    }
    if (this.$store.state.orgData) {
      console.log(this.$store.state.orgData);
    }
  },beforeDestroy () {
    window.onkeydown = null;
  }
};
</script>

<style scoped>
/* 登录，注册 */
.form-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url(../assets/bg.jpg) no-repeat center;
  background-size: cover;
}

.form-wrap .container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -600px;
  transform: translateY(-50%);
}
.form-card {
  float: right;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 25px;
  border-radius: 12px;
}
.form-main {
  position: relative;
  width: 500px;
  padding: 15px;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: border-box;
}
.form-link {
  position: absolute;
  width: 126px;
  height: 70px;
  background: url(../assets/sqbg.png) no-repeat;
  background-size: 100% 100%;
  right: -5px;
  top: 0;
  padding-top: 15px;
  padding-left: 70px;
  box-sizing: border-box;
}
.form-link a {
  color: #ffffff;
}
.form-inner {
  padding: 40px 40px 10px 40px;
}
.form-rows {
  display: flex;
  width: 1200px;
}
.form-pic img {
  width: 100%;
}
.form-row {
  position: relative;
  height: 44px;
  margin-bottom: 26px;
  display: flex;
}
.form-row .form-control {
  box-shadow: 0 0 0 transparent;
  border: 1px solid #ced1d5;
  height: 44px;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}
.form-row .v-label {
  line-height: 44px;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}
.form-row .v-control {
  margin-left: 80px;
  flex: 1;
}
.form-button {
  text-align: center;
}
.form-button .btn-primary {
  width: 360px;
  border-color: #2458a3;
  background-color: #2458a3;
  height: 44px;
  font-size: 16px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  outline: none;
  /* border: none; */
}
.form-code-row .v-code {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #ced1d5;
  border-radius: 4px;
  overflow: hidden;
}
.form-code-row .v-code img {
  width: 110px;
  height: 42px;
  display: block;
}
.form-code-row .v-control {
  padding-right: 130px;
}
.form-end {
  text-align: right;
  margin-top: 20px;
}
.form-end a {
  color: #198cff;
}
.form-tabs {
  height: 80px;
  border-bottom: 2px solid #f2f5f8;
}
.form-tabs li {
  width: 40%;
  float: left;
}
.form-tabs li a {
  position: relative;
  display: block;
  line-height: 80px;
  text-align: center;
  font-size: 22px;
  color: #999999;
}
.form-tabs li.active a {
  color: #333333;
}

.form-tabs li.active a::after {
  background-color: #2458a3;
  content: " ";
  position: absolute;
  bottom: -1px;
  height: 5px;
  width: 50px;
  left: 50%;
  margin-left: -25px;
}

.form-tt {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
}
.form-regist .form-inner {
  padding-top: 20px;
}
.v-radios {
  padding: 10px 0;
}
.radio-inline input[type="radio"] {
  width: 16px;
  height: 16px;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  background-color: transparent;
  cursor: pointer;
}
.radio-inline input[type="radio"]::before {
  display: block;
  width: 16px;
  height: 16px;
  content: " ";
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../assets/radio.png);
}
.radio-inline input[type="radio"]:checked::before {
  background-image: url(../assets/radioed.png);
}
.radio-inline * {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}
.v-radios .radio-inline {
  margin-right: 20px;
}

.text-box {
  position: absolute;
  top: -75px;
  right: 10px;
  text-align: center;
  width: 100%;
  font-size: 50px;
  color: #fff;
  font-weight: bold;
}
.text-boxgz {
  position: absolute;
  top: -55px;
  right: 10px;
  text-align: center;
  width: 100%;
  font-size: 35px;
  color: #fff;
  font-weight: bold;
}
.org-info {
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
}
/* .org-info .org-logo{height: 80px;} */
/* .org-info .org-logo img{height: 100%;} */
.org-info p {
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
/* ----------------移动端样式 start---------------- */
.mobile_login_wrap {

}
.mobile_top_box {
  height: 108px;
  background: #06f;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}
.mobile_close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.mobile_top_box_title {
  font-size: 25px;
  color: #fff;
}
.mobile_bottom_box {

}
.mobile_login_form {
  padding: 20px 16px;
}
.mobile_tab_title {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 3px solid #ececec;
  box-sizing: border-box;
}
.mobile_tab_title_item {
  font-size: 18px;
  color:#1f1f1f;
  /* padding: 2% 12%; */
  padding: 10px 50px;
  position: relative;
  top: 2px;
  box-sizing: border-box;
  border-bottom: 2px solid #ececec;
}
.mobile_tab_title_active {
  border-color: #06f
}
.mobile_tab_content {
  padding: 20px 16px;
}
.mobile_code {
  display: flex;
  align-items: center
}
.mobile_register_to_login {
  text-align: right;
  font-size: 12px;
  margin-top: 20px;
  color: #8590a6;
}
.mobile_register_to_login span {
  color: #06f;
}
/* ----------------移动端样式 end---------------- */
</style>