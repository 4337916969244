<template>
    <div>
        <nav-bar></nav-bar>
		<student-info></student-info>

        <div class="uc-wrap">
            <div class="container clearfix">
                <!-- <div class="uc-sidebar">
                    <div class="uc-side-hd">
                        <h3>用户中心</h3>
                        <p>User center</p>
                    </div>
                    <ul class="uc-navbar">
                        <li class="menu" id="myInfo"><router-link to="/student_profile"><i class="iconfont icon-gerenziliao"></i>个人资料</router-link></li>
						<li class="menu active" id="myGame" ><router-link to="/student_competition"><i class="iconfont icon-saishi"></i>我的赛事</router-link></li>
						<li class="menu" id="myPwd" ><router-link to="/student_pwd"><i class="iconfont icon-xiugaimima"></i>修改密码</router-link></li>
                    </ul>
                    <div class="uc-foot">
                        <a href="#" class="btn btn-out">退出登录</a>
                    </div>				   
                </div>	 -->
				<student-sidebar :currentId="1"></student-sidebar>
                <div class="uc-panel">
                    <div class="uc-heading">
                        <div class="uc-tit">我的赛事</div>
                    </div>
                    <div class="gm-empty" v-if="mySaishiList.length==0">
                        <img src="../assets/empty.png" >
                        <p>暂无数据~</p>
                    </div>
                    <ul class="game-list" v-if="mySaishiList.length">
                        <li v-for="(item,index) in mySaishiList" :key="index">
                            <div class="gm-photo">
                                <a href="javascript:void(0);"><img :src="$store.state.baseHost+'/fs/download?storeKey='+item.contestCoverUrl" :onerror="defaultImg"></a>
                            </div>
                            <div class="gm-body">
                                <div class="gm-title">
                                    {{item.contestName}}
                                    <img src="../assets/ing.png" class="gm-icon" v-if="item.contestStatus==1">
                                    <img src="../assets/over.png" class="gm-icon" v-if="item.contestStatus==-1">
                                </div>
                                <div class="gm-desc">{{item.contestRemark}}</div>
                                <div class="gm-status">
                                    <span>报名状态：</span>
									<template v-if="item.contprojStatus==-1">
										<span class="badge badge-red">审核不通过</span>
									</template>
									<template v-if="item.contprojStatus==0">
										<span class="badge badge-blue">已报名待审核</span>
									</template>
									<template v-if="item.contprojStatus==1">
										<span class="badge badge-green">审核通过</span>
									</template>
                                    <a href="javascript:void(0);" class="btn-pm" @click="toEditContproj(item.contestId,item.contprojId,item.contprojSource)">报名申请表</a>
                                </div>
                                <div class="gm-attr">
                                    <div class="gm-item"><i class="el-icon-office-building" style="font-size:15px;margin-right:5px"></i>{{item.contprojName}}</div>
                                    <div class="gm-item"><i class="el-icon-time" style="font-size:15px;margin-right:5px"></i>{{item.contestStarttime.split(" ")[0]}} ~ {{item.contestEndtime.split(" ")[0]}}</div>
                                </div>
                                <div class="gm-steps">
									<el-steps :active="item.num+1" style="width:100%">
										<el-step v-for="(step,ind) in item.steps" :key="ind" :title="step.title" icon="el-icon-location-information"></el-step>
									</el-steps>
								</div>
                                <div class="gm-status">
                                    <span>我的参赛状态：</span>
									<template v-if="item.lastStageId==null">
										<a href="javascript:void(0);" class="btn-primary">报名</a>
									</template>
									<template v-else>
										<template v-for="(kk,idx) in item.stageList">
											<a href="javascript:void(0);" class="btn-primary"  v-if="kk.stageId==item.lastStageId" :key="idx">{{kk.stageName}}</a>
										</template>
									</template>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <bottom-bar></bottom-bar>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import studentInfo from "@/components/studentCenter/studentInfo";
import studentSidebar from "@/components/studentCenter/studentSidebar";

export default {
    components: {
        navBar,
		bottomBar,
		studentInfo,
		studentSidebar,
	},
	data () {
		return {
			//帐号信息
			memberId: this.$store.state.userInfo.memberId,//会员id
			memberName : this.$store.state.userInfo.memberName,//会员名称	
			memberPhone	: this.$store.state.userInfo.memberPhone,//手机号
			memberEmail : this.$store.state.userInfo.memberEmail,//电子邮箱

			mySaishiList: [],
            defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',

			// steps: [],//总环节数据
			// num: 0,//当前环节下标
		}
	},
	methods: {
		getMySaishi(){
			this.$post("/api/contproj/list",{studentId:this.$store.state.userInfo.student.studentId}).then((res)=>{
				// console.log(res);
				if(res.result){
					for(var i=0;i<res.resultData.length;i++){
						//处理步骤
						var num = 0,isAllStageDone = false, d2 = [{ title: "报名", description: "" }];
						for(var t=0;t<res.resultData[i].stageList.length;t++) {
							d2.push({ title: res.resultData[i].stageList[t].stageName, description: "" });
							if(res.resultData[i].stageList[t].stageStatus!=0){
								num = t + 1;
							}
							if(t==res.resultData[i].stageList.length-1 && res.resultData[i].stageList[t].stageStatus==-1) {
								isAllStageDone = true;
							}
						}
						d2.push({ title: "颁奖", description: "" });
						// 若全部环节完成直接进入最后颁奖阶段
						if(isAllStageDone) num = res.resultData[i].stageList.length+1;
						res.resultData[i].steps = d2;
						res.resultData[i].num = num;
						// console.log(res.resultData[i].steps,res.resultData[i].num);
					}
					this.mySaishiList = res.resultData;

					// console.log(this.mySaishiList)

				}else{
					this.$message.error(res.resultMsg);
				}
			}).catch((res)=>{
				console.log(res);
			})
		},
		//查看报名表
		toEditContproj(contestId,contprojId,contprojSource){
			if(this.$store.state.oid=='5' && contprojSource=='1'){
				this.$router.push({name: 'njydbaoming',query:{contestId: contestId}})
			}else{
				this.$router.push({name: 'baoming',query:{contestId: contestId}})
			}
      		
		},
	},
	mounted () {
		this.getMySaishi();
	}
}
</script>

<style scoped>
.container{width: 1200px;overflow: hidden;margin: 0 auto;position: relative;z-index: 2;}

.uc-wrap{
	position: relative;
	z-index: 999;
	margin-top: -46px;
	padding-bottom: 80px;
}

.uc-panel{
	float: right;
	width: 930px;
	background-color: #FFFFFF;
}
.uc-heading{
	height: 46px;
	border-bottom: 1px solid #DDDDDD;
	margin-left: 20px;
}
.uc-tit{
	position: relative;
	height: 45px;
	line-height: 45px;
	font-size: 18px;
}
.uc-tit::after{
	height: 1px;
	width: 24px;
	position: absolute;
	background-color: #02354b;
	left: 0;
	bottom: -1px;
	content: ' ';
}
.uc-panel-body{
	padding: 20px 40px 20px 20px;
}

.gm-empty{
	text-align: center;
	height: 334px;
	padding: 80px 0;
}
.gm-empty p{
	text-align: center;
	color: #999999;
	font-size: 16px;
	margin-top: 20px;
}

.game-list{
	padding: 0 20px;
}
.game-list li{
	padding: 40px 0;
	overflow: hidden;
	border-bottom: 1px solid #eeeeee;
}
.game-list li:last-child{
	border-bottom: 0;
}
.gm-photo{
	float: left;
	width: 190px;
	margin-right: 20px;
}
.gm-photo img{
	width: 190px;
	height: 140px;
	border-radius: 10px;
}
.gm-body{
	overflow: hidden;
}
.gm-status{
	color: #666666;
	font-size: 16px;
	margin-bottom: 10px;
}
.gm-desc{
	color: #666666;
	font-size: 16px;
	margin-bottom: 10px;
}
.text-org{
	color: #f35530;
}
.gm-status .btn-pm{
	display: inline-block;
	margin-left: 20px;
	line-height: 30px;
	border-radius: 4px;
	background-color: #e8f3ff;
	color: #198cff;
	padding: 0 10px;
}
.gm-title{
	font-size: 18px;
	margin-bottom: 10px;
}
.gm-icon{vertical-align: bottom;}
.gm-attr{
	overflow: hidden;
	margin-bottom: 10px;
}
.gm-attr .gm-item{
	float: left;
	font-size: 14px;
	color: #999999;
	margin-right: 40px;
}
.gm-attr .gm-item .iconfont{
	padding-right: 10px;
}
.gm-status .btn-primary{
	background-color: #198cff;
	border-color: #198cff;
	padding: 0 10px;
	font-size: 14px;
	display: inline-block;
	vertical-align: middle;
	height: 25px;
	line-height: 25px;
	border-radius: 4px;
	color: #fff;
}
.gm-steps{
	position: relative;
}
/* .gm-steps::after{
	height: 3px;
	width: 100%;
	background-color: #f5f7f8;
	top: 26px;
	content: ' ';
	position: absolute;
	left: 0;
} */
.gm-steps .step-item{
	position: relative;
	z-index: 9;
}

.gm-steps {
	position: relative;
	height: 76px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	margin-bottom: 10px;
	overflow: hidden;
}
.badge {font-weight: 500;vertical-align: 2px;color: #fff;background-color: #007bff;display: inline-block;padding: .25em .6em;font-size: 75%;font-weight: 300;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: .215rem;}
.badge-blue {color: #fff;background-color: #007bff;}
.badge-green {color: #fff;background-color: #28a745;}
.badge-red {color: #fff;background-color: #dc3545;}
.badge-sky {color: #fff;background-color: #17a2b8;}
.badge-dark {color: #fff;background-color: #526069;}

</style>