<template>
  <div class="main-body">
     <!--过渡屏-->
     <div class="main" v-if="voteShowg!=null && voteShowg==1" :style="{'background-image': 'url(' + showgImgUrl + ')'}"></div>
     <!--示范屏-->
     <div class="main" v-if="voteShowg!=null && voteShowg==2" :style="{'background-image': 'url(' + conVoteliveBgUrl + ')'}">
       <div class="wap"> 
          <div class="thr-ly"> 
             <div class="sw" @click="getCode">
               项目展示倒计时 
             </div> 
             <div class="time" v-if="!shierCorlorRed">
               {{shierStr}}
             </div> 
             <div class="timeRed" v-if="shierCorlorRed">
               {{shierStr}}
             </div>  
           </div>
       </div>
     </div>
     <!--主屏-->
     <div class="main" v-if="voteShowg!=null && voteShowg==0" :style="{'background-image': 'url(' + bgUrl + ')'}"> 
         <div class="wap"> 
           <!-- 公共头部start  -ly：路演，-pw：评委 -->  
             <div class="second-ly" v-if="partNumber==5 && partNumber>0"> 
 
               <div class="sm"  v-if="!projLengthToTt"> 
                 <span class="city" v-if="cityHtml" style="display: none;">
                   {{cityHtml}}
                 </span> 
                 <template v-if="contprojName">
                     {{contprojName}}
                 </template> 
               </div> 
               <div class="sm15"  v-if="projLengthToTt"> 
                 <span class="city15" v-if="cityHtml" style="display: none;">
                   {{cityHtml}}
                 </span> 
                 <template v-if="contprojName">
                     {{contprojName}}
                 </template> 
               </div> 
             </div> 
 
 
             <div class="top-right" style="display: none;" v-if="partNumber==4 && !colorRed">{{TimeCountDown}}</div> 
             <div class="top-rightRed" style="display: none;" v-if="partNumber==4 && colorRed">{{TimeCountDown}}</div> 
 
             <div class="second-pw" v-if="partNumber==4"> 
               <div class="sm" v-if="!projLengthToTt"> 
                 <span class="city" v-if="cityHtml" style="display: none;">
                   {{cityHtml}}
                 </span> 
                 <template v-if="contprojName">
                     {{contprojName}}
                 </template> 
               </div> 
               <div class="sm15" v-if="projLengthToTt"> 
                 <span class="city15" v-if="cityHtml" style="display: none;">
                   {{cityHtml}}
                 </span> 
                 <template v-if="contprojName">
                     {{contprojName}}
                 </template> 
               </div>
 
             </div> 
 
             <div class="second-pw" v-if="partNumber==0"> 
             </div> 
 
           <!-- 公共头部end -->  
 
 
           <!-- 阶段标题展示+倒计时start -ly：路演，-pw：评委 -->  
 
           <div class="thr-zc" v-if="partNumber==0" style="display: none;"> 
             <div class="time">
               转场请稍候
             </div> 
           </div>
 
           <div class="thr-ly" v-if="partNumber==1" style="display: none;"> 
             <div class="sw">
               项目展示倒计时 
             </div> 
             <div class="time" v-if="!colorRed">
               {{TimeCountDown}} 
             </div>  
             <div class="timeRed" v-if="colorRed">
               {{TimeCountDown}} 
             </div> 
           </div>
 
           <div class="thr-ly" v-if="partNumber==2" style="display: none;"> 
             <div class="sw">
               问答互动倒计时 
             </div>
             <div class="time" v-if="!colorRed">
               {{TimeCountDown}} 
             </div>  
             <div class="timeRed" v-if="colorRed">
               {{TimeCountDown}} 
             </div> 
           </div>
 
           <div class="thr-ly" v-if="partNumber==3" style="display: none;"> 
             <div class="sw">
               现场拉票倒计时 
             </div> 
             <div class="time" v-if="!colorRed">
               {{TimeCountDown}} 
             </div>  
             <div class="timeRed" v-if="colorRed">
               {{TimeCountDown}} 
             </div>  
           </div>
 
           <div class="thr-ly" v-if="partNumber==5"> 
             <div class="sw">
               最终得分
             </div> 
             <div class="time">
               {{finalScore}}
             </div>   
           </div>
 
 
           <!-- 打分评委列表strat -->
            <div class="zhuanj" v-if="partNumber==4"> 
                 <ul> 
                    <li v-for="(item,index) in judgerList" :key="index"> 
                      <a href="javascript:void(0);"> 
                        <img v-if="item.judgeStatus!=1" src="../assets/ping-1.png" class="item-content" />
                        <img v-if="item.judgeStatus==1" src="../assets/ping-2.png" class="item-content" />
                      </a>
                      <div class="item-dec">
                        <a> </a>
                        <a href="javascript:void(0);"> <h4 class="item-dec">{{item.judgerName}}</h4> </a> 
                      </div> 
                    </li>
                 </ul> 
           </div>
           <div class="lyfive" v-if="partNumber==4"> 
             <div class="progress-box">
                           <div class="wclleft" id="wclDesc">打分进度</div>
                           <div class="progress">
                               <div class="progress-bar" id="progressBar" :style="{'width': progressBarWidth}">
                                   <div class="ywc" id="ywc">{{progressBarWidth}}</div>
                               </div>
                               <!-- <div class="wwc" id="wwc">40%</div> -->
                           </div>
                           <div class="wcl" id="wcl">{{wclHTML}}</div>      
               </div>
           </div>
           <!-- 打分评委列表strat -->
       </div> 
     </div> 
   </div>
 </template>
 
 <script>
 var timer;
 
 var isPlay = false;
 
 
 export default {
   data () {
     return {
       contvoteId: null,//现场id
       projData:[],
       cityHtml:null, 
       contprojName: null,
 
       partNumber: null,
       nowProj: [],
 
       TimeCountDown: null,
       colorRed: false,
       projLengthToTt:false,
 
       pwList: [],
 
       judgerList: [],
 
       progressBarWidth: 0,
       wclHTML: '',
 
       finalScore: null,
 
       conVoteliveBgUrl: null, //各阶段背景图
 
       showgImgUrl:null,
       voteShowg:0,
 
       show: true,
       count: '',
       tmptimer: null,
       shierStr:'00:12',
       
       timestop:false,
       shierCorlorRed:false,
 
       contvoteTpUrl:null, //打分阶段背景图
       
       bgUrl: null, //背景图
 
       djSec: 0,
       djTime: null,
     }
   },
   methods: {
     
     //获取指定大赛现场信息
     getStageLive(){
       var that = this;
       this.$post('/api/votelive/get',{
         contvoteId: this.contvoteId,
       }).then((res)=>{
         // console.log(res);
         if(res.result){
           let data = res.resultData,
           projectList = res.resultData.contprojList;
 
           this.projData = res.resultData;
           this.contvoteTpUrl = res.resultData.contvoteTpUrl;
           if(data.conVoteliveBgUrl){
               this.conVoteliveBgUrl = this.$store.state.baseHost+"/"+data.conVoteliveBgUrl;
           }
           this.bgUrl = data.conVoteliveBgUrl;
           //过渡屏背景图
           if(data.conVoteliveCoverUrl){
              this.showgImgUrl = this.$store.state.baseHost+"/"+data.conVoteliveCoverUrl;
           }
 
           if(data.voteShowg){
              this.voteShowg = data.voteShowg;
              
           }
 
           if (data.contprojList.length) {
             if(data.contprojList[0].contprojRegion!=null&&data.contprojList[0].contprojRegion!=''){
               this.cityHtml = data.contprojList[0].contprojRegion;
             }
             this.contprojName = data.contprojList[0].contprojName;
 
           }
 
           if(!this.$route.query.stop){
               clearInterval(timer);
               timer = setInterval(function () {
                   that.getCurProjPart(data, projectList)
               }, 1000)
           }else{
               clearInterval(timer);
               timer = setTimeout(function () {
                   that.getCurProjPart(data, projectList)
               }, 1000)
           }
 
           if(!this.$route.query.player){
              isPlay = true;
           }
 
 
           
         }else{
           this.$message.error(res.resultMsg);
         }
       }).catch((res)=>{
         console.log(res);
       })
     },
 
 
     //获取当前阶段
     getCurProjPart(stageliveData,projectList){
       this.$post('/api/votelive/getCurProjPart',{
         contvoteId: this.contvoteId
       }).then((res)=>{
         // console.log(res);
         if(res.result){
           let data = res.resultData, nowProj;
           for (let i = 0; i < projectList.length; i++) {
             if (projectList[i].contprojId == data.contprojId) {
               nowProj = projectList[i];
               // console.log(nowProj)
               break;
             }
           }
           this.nowProj = nowProj;
           this.partNumber = data.partNumber;
 
           //切换到12秒倒计时，重新初始化对应参数
           if(data.voteShowg!=this.voteShowg && data.voteShowg==2){
              this.shierStr = "00:12";
              this.timestop = false;
              this.shierCorlorRed = false;
              // this.show=true;
              // this.count= '';
              //this.tmptimer = null;
           }
 
           if(data.voteShowg>=0){
              this.voteShowg = data.voteShowg;
           }
 
           if(data.voteShowg==2){
               if(this.show){
                 this.getCode();
               }
           }
           
 
           if(nowProj.contprojRegion!=null&&nowProj.contprojRegion!=''){
             this.cityHtml = nowProj.contprojRegion;
             this.contprojName = nowProj.contprojName;
 
             if(nowProj.contprojName.length<=20){
               this.projLengthToTt = true;
             }else{
               this.projLengthToTt = false;
             }
           }
           
           if(data.partNumber==0){//转场等待
 
           }else if(data.partNumber==1){//路演阶段
             //this.countdown(data.partNumber,stageliveData,data);
 
           }else if(data.partNumber==2){//答辩阶段
             //this.countdown(data.partNumber,stageliveData,data);
 
           }else if(data.partNumber==3){//答辩阶段
             //this.countdown(data.partNumber,stageliveData,data);
 
           }else if(data.partNumber==4){//打分阶段
             //this.countdown(data.partNumber,stageliveData,data);
 
             // 评委Begin
             let list = [],//用于渲染
                 size=10;//一页10条数据
             const pwdata = res.resultData.judgerList;
 
             this.judgerList = res.resultData.judgerList;
 
             const page = Math.ceil(pwdata.length / size);
             for (let i = 0; i < page; i++) {
                 const start = i * size;
                 const end = i * size + size;
                 const items = pwdata.slice(start, end);
                 list.push(items);
             }
             console.log(list)
             this.pwList = list;
 
             let ywcLengt=0;
             pwdata.forEach((item,i)=>{
                 if(item.judgeStatus==1){//已评
                     ywcLengt++;
                 }
             })
             this.progressBarWidth = this.toPercent(ywcLengt,pwdata.length);
             this.wclHTML = ywcLengt+'/'+pwdata.length;
 
           }else if(data.partNumber==5){//公示阶段
             this.finalScore = data.finalScore;
           }
 
           if(data.partNumber==4 && this.contvoteTpUrl!='' && this.contvoteTpUrl!=null){
             this.bgUrl = this.$store.state.baseHost+"/"+this.contvoteTpUrl;
           }else{
             this.bgUrl = this.conVoteliveBgUrl;
           }
           
         }else{
           this.$message.error(res.resultMsg);
         }
       }).catch((res)=>{
         console.log(res);
       })
     },
     // 倒计时
     countdown(){
       if(this.djSec<0){ //倒计时不能小于零
         this.djSec=0;
       }
       this.TimeCountDown = this.timestampToDate(this.djSec);
       if(this.djSec<=10){
         this.colorRed=true; //讲倒计时字体设置为红色
         if(this.djSec>0 && this.djSec==10){ //如果倒计时刚好剩10秒，则开启倒计时声音
           var mp3 = new Audio(require('../audio/countdown.mp3'));
           mp3.play();
         }
       }else{
         this.colorRed=false;
       }
 
     },
     // 百分比
     toPercent(num, total) { 
         return (Math.round(num / total * 100) + "%");// 小数点后两位百分比
     },
     //秒数换算时间
     timestampToDate(ctime) {
         let returnStr = '';
 
         // 天数位
         // let day = Math.floor(ctime / 3600 / 24);
         // let dayStr = day.toString();
         // if (dayStr.length == 1) dayStr = '0' + dayStr;
 
         // // 小时位
         // let hr = Math.floor(ctime / 3600);
         // let hrStr = hr.toString();
         // if (hrStr.length == 1) hrStr = '0' + hrStr;
         // returnStr = hrStr;
 
         // 分钟位
         let min = Math.floor(ctime / 60 % 60);
         let minStr = min.toString();
         if (minStr.length == 1) minStr = '0' + minStr;
         returnStr += minStr;
 
         // 秒位
         let sec = Math.floor(ctime % 60);
         let secStr = sec.toString();
         if (secStr.length == 1) secStr = '0' + secStr;
         returnStr += ':' + secStr;
 
         return returnStr;
     },
 
     getCode(){
      
      const TIME_COUNT = 13;
      if (!this.tmptimer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.tmptimer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT && !this.timestop) {
           this.count--;
           if(this.count>=10){
             this.shierCorlorRed = false;
             if(this.count==10){
                 this.shierCorlorRed = true;
                 var semp3 = new Audio(require('../audio/countdown.mp3'));
                 semp3.play();
             }
             this.shierStr = "00:"+this.count;
           }else{
             this.shierCorlorRed = true;
             if(this.count==0){
               this.timestop = true;
             }
             this.shierStr = "00:0"+this.count;
           }
         } else {
           this.show = true;
           clearInterval(this.tmptimer);
           this.tmptimer = null;
         }
        }, 1000)
        
       }
    }  
 
   },
 
   created () {
       
   },
   mounted () {
     if(this.$route.query.contvoteId){
       this.contvoteId = this.$route.query.contvoteId;
       this.getStageLive();
     }else{
       this.$message.error('缺少现场id');
     }
 
     //this.getCode();
       
   }
 }
 </script>
 
 <style scoped>
 .main-body {
   font-family: "Microsoft Yahei";
   font-size: 16px;
   padding: 0;
   margin: 0;
 }
 
 a:hover {
   color: #faab11;
   text-decoration: none
 }
 
 a {
   color: #fff;
   text-decoration: none
 }
 
 img {
   border: none
 }
 
 ul,ol,li,p,dl,dt,dd {
   margin: 0;
   padding: 0;
   list-style: none;
 }
 
 .main {
   width: 100%;
   background: url('../assets/touping-title.png');
   height: 100%;
   overflow: hidden;
   position: fixed;
   top: 0;
   left: 0;
   background-size: cover;
   -webkit-background-size: cover;
   -o-background-size: cover;
   background-position: center 0;
 
   /*max-width: 1340px;
   height: 100%;
   display: flex;
   align-items: center;
   margin: 0 auto;
   text-align: center;
   flex-direction: column;
   justify-content: center;*/
 
 
 
 }
 
 .wap {
   width: 100%;
   height: 100%;
 }
 
 .top {
   width: 50%;
   height: auto;
   font-size: 2em;
   color: #fff;
   margin: 3% auto;
   font-weight: 700;
   text-align: center;
 }
 
 .top-right {
   width: 96.5%;
   height: 0.5vw;
   font-size:7em;
   color: #fff;
   font-weight: 200;
   text-align:right;
   line-height: 0.8vw;
   letter-spacing: 8%;
   margin-top: 4.9%;
   margin-right:2%;
   letter-spacing:6px;
 }
 
 .top-rightRed {
   width: 96.5%;
   height: 0.5vw;
   font-size:7em;
   color: red;
   font-weight: 200;
   text-align:right;
   line-height: 0.8vw;
   letter-spacing: 8%;
   margin-top: 4.9%;
   margin-right:2%;
   letter-spacing:6px;
 }
 
 .top .tl {
   font-size: 1.5em;
   color: #fff;
   font-weight: 700;
   width: 100%;
   line-height: 1.5em;
   text-align: center;
 }
 
 /*路演阶段大赛名称样式*/
 .second-ly {
   text-align: center;
   margin: 2% auto;
   height: 3vw;
 }
 
 .second-ly .sl {
   font-size: 2em;
   color: #fae111;
   font-weight: 700;
   width: 100%;
   text-align: center;
 }
 
 .second-ly .sm {
   background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.6% 3%;
   color: #fff;
   font-weight: 300;
   font-size: 3.2em;
   align-items: center;
   margin-top: 6.9%;
   text-align: center;
   display: inline-block;
   max-width: 49.5%;
   white-space: nowrap; /* 禁止换行 */
   overflow: hidden; /* 隐藏溢出部分 */
   text-overflow: ellipsis; /* 显示省略号 */
   /* font-family:"楷体","KaiTi_GB2312"; */
 }
 
 .second-ly .sm .city {
   background: #F29718;
   border: 2px solid #fff;
   border-radius: 45px;
   display: inline-block;
   white-space: nowrap;
   padding-left:20px;
   padding-right:20px;
 }
 
 .second-ly .sm .dec {
   white-space: nowrap;
   text-overflow: ellipsis;
   padding-left: 0.5%;
   text-align: left;
   text-indent: 1%;
   margin-top: 1.5%;
   text-align:center;
   white-space: nowrap;
   display: inline-block;
 }
 
 
 
 .second-ly .sm15 {
  background: #00a2eb;
  border: 2px solid #fff;
  border-radius: 45px;
  overflow: hidden;
  padding:0.6% 3%;
  color: #fff;
  font-weight: 300;
  font-size: 4em;
  align-items: center;
  margin-top: 6.9%;
  text-align: center;
  display: inline-block;
  max-width: 65%;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
  font-weight: 600;
   /* font-family:"楷体","KaiTi_GB2312"; */
    /* background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.9% 1%;
   color: #fff;
   font-weight: bold;
   font-size: 2.5em;
   align-items: center;
   margin-top: 13.5%;
   text-align: center;
   display: inline-block;
   width:46%;
   font-family:"楷体","KaiTi_GB2312"; */
   
 }
 
 .second-ly .sm15 .city15 {
   background: #F29718;
   border: 2px solid #fff;
   border-radius: 45px;
   display: inline-block;
   white-space: nowrap;
   font-size: 36px;
   padding-left:20px;
   padding-right:20px;
 
 }
 
 .second-ly15 .sm15 .dec15 {
   white-space: nowrap;
   text-overflow: ellipsis;
   padding-left: 0.5%;
   text-align: left;
   text-indent: 1%;
   margin-top: 1.5%;
   text-align:center;
   white-space: nowrap;
   
 }
 
 
 
 
 /*转场样式*/
 .thr-zc {
   margin:16.5% auto;
   text-align:center;
   
 }
 
 .thr-zc .time {
   font-size:12em;
   color: #fff;
   text-align: center;
   margin:0 auto;
   background: #224091;
   border: 8px solid #00A2E9;
   align-items: center;
   border-radius: 80px;
   white-space: nowrap;
   overflow:hidden;
   display: inline-block;
   width:61%;
   letter-spacing:10px;
   padding:83px 5px;
 }
 
 
 /*路演阶段倒计时样式*/
 .thr-ly {
   /* margin:16.8% auto; */
   text-align:center;
 }
 
 .thr-ly .sw {
  font-size: 6.5em;
	color: #fff;
	font-weight: 100;
	text-align: center;
  margin-top:12.6%;
	height: 2vw;
  font-weight: 600;
 }
 
 .thr-ly .time {
   font-size:14em;
   color: #fff;
   font-weight: 600;
   text-align: center;
   margin:0 auto;
   /* width:30%; */
   background: #224091;
   border: 8px solid #00A2E9;
   letter-spacing: 6px;
   border-radius: 65px;
   white-space: nowrap;
   overflow:hidden;
   align-items: center;
   overflow:hidden;
   margin-top:6.3%;
   width:35%;
   padding:9px 5px;
 }
 
 
 .thr-ly .timeRed {
   font-size:11em;
   color: red;
   font-weight: 100;
   text-align: center;
   margin:0 auto;
   /* width:30%; */
   background: #224091;
   border: 8px solid #00A2E9;
   letter-spacing: 6px;
   border-radius: 65px;
   white-space: nowrap;
   overflow:hidden;
   align-items: center;
   overflow:hidden;
   margin-top:7.3%;
   width:35%;
   padding:9px 5px;
   /* font-size:15em;
   color: red;
   font-weight: 100;
   text-align: center;
   margin:0 auto;
   width:30%;
   background: #224091;
   border: 8px solid #00A2E9;
   letter-spacing:6px;
   border-radius: 65px;
   white-space: nowrap;
   overflow:hidden;
   align-items: center;
   overflow:hidden;
   margin-top:4.4%;
   width:45%;
   padding:9px 5px; */
 }
 
 /*倒计时red样式
 /*.thr-ly .timeRed {
   font-size: 5em;
   color: #red;
   font-weight: 700;
   text-align: left;
   width: 45%;float: left;
 }*/
 
 
 
 
 /*评委阶段大赛名称样式*/
 .second-pw {
   text-align: center;
   margin: 0 auto;
   height: 3vw;
 }
 
 .second-pw .sl {
   font-size: 2em;
   color: #fae111;
   font-weight: 700;
   width: 100%;
   text-align: center;
 }
 
 .second-pw .sm {
   /* background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.9% 1%;
   color: #fff;
   font-weight: bold;
   font-size: 2.5em;
   align-items: center;
   margin-top: 10.1%;
   text-align: center;
   display: inline-block;
   font-family:"楷体","KaiTi_GB2312"; */
   background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.6% 3%;
   color: #fff;
   font-weight: bold;
   font-size: 3.2em;
   align-items: center;
   margin-top: 8%;
   text-align: center;
   display: inline-block;
   max-width: 49.5%;
   white-space: nowrap; /* 禁止换行 */
   overflow: hidden; /* 隐藏溢出部分 */
   text-overflow: ellipsis; /* 显示省略号 */
 
 }
 
 .second-pw .sm .city {
   background: #F29718;
   border: 2px solid #fff;
   border-radius: 45px;
   display: inline-block;
   white-space: nowrap;
   padding-left:20px;
   padding-right:20px;
 
 }
 
 
 .second-pw .sm15 {
    /* background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.9% 1%;
   color: #fff;
   font-weight: bold;
   font-size: 2.5em;
   align-items: center;
   margin-top: 10.1%;
   text-align: center;
   display: inline-block;
   width:46%;
   font-family:"楷体","KaiTi_GB2312"; */
   background: #00a2eb;
   border: 2px solid #fff;
   border-radius: 45px;
   overflow: hidden;
   padding:0.6% 3%;
   color: #fff;
   font-weight: bold;
   font-size: 3.2em;
   align-items: center;
   margin-top: 8%;
   text-align: center;
   display: inline-block;
   max-width: 49.5%;
   white-space: nowrap; /* 禁止换行 */
   overflow: hidden; /* 隐藏溢出部分 */
   text-overflow: ellipsis; /* 显示省略号 */
 }
 
 .second-pw .sm15 .city15 {
   background: #F29718;
   border: 2px solid #fff;
   border-radius: 45px;
   display: inline-block;
   white-space: nowrap;
   padding-left:20px;
   padding-right:20px;
 
 }
 
 
 
 
 
 
 .second-pw .sm .dec {
   white-space: nowrap;
   text-overflow: ellipsis;
   padding-left: 0.5%;
   text-align: left;
   text-indent: 1%;
   margin-top: 1.5%;
   text-align:center;
   white-space: nowrap;
   display: inline-block;
 }
 
 
 /*评委阶段倒计时样式*/
 .thr-pw {
   width: 100%;
   margin-left: 27%;
 }
 
 .thr-pw .sw {
   font-size: 2em;
   color: #fff;
   font-weight: 100;
   width: 50%;
   margin-top: 1%;
   text-align: center;
   height: 2vw;
 }
 
 .thr-pw .time {
   font-size: 5em;
   color: #fff;
   font-weight: 200;
   width: 50%;
   text-align: center;
   height: 1vw;
   float: left;
 }
 
 .thr-pw .timeRed {
   font-size: 5em;
   color: #fff;
   font-weight: 200;
   width: 50%;
   text-align: center;
   height: 1vw;
   float: left;
 }
 
 /*倒计时red样式*/
 /*.thr .time {
   font-size: 5em;
   color: #red;
   font-weight: 700;
   text-align: left;
   width: 45%;float: left;
 }*/ 
 
 /* .biaoti {
   font-size: 3em;
   color: #fff;
   font-weight: 700;
   letter-spacing: 200px;
   margin: 25px 0  20px 0px;
   text-align: center
 }
 */ 
 
 /*专家公用部分*/
 .zhuanj {
   width: 100%;
   margin: 12.6% 0 0.5% 1%;
   display: flex;
   display: -webkit-flex;
  /* Safari */
   justify-content: center
 }
 /*人头居中开始*/
 .zhuanj ul {
   margin-top: 0.15%;
 }
 /*.zhuanj ul li {
   width: 3%;
   padding: 0.5%;
   float: left;
   margin-left: 0.3%;
   height: auto;
   margin-top: .5%;
   margin-right: 0.5%;
   text-align: center
 }*/
 
 /*人头左对齐开始*/
 
 
 .zhuanj ul li {
   width: 4%;
   float: left;
   margin-left: 0.3%;
   height: auto;
   margin-top: 0.02%;
   margin-left: 2.4%;
   text-align: center
 }
 .zhuanj ul li a {
   background: #faab11;
 }
 
 .zhuanj ul li a:hover {
   background: #faab11
 }
 
 .zhuanj ul li img {
   display: block;
   width: 100%;
   border: 0px solid ;
   border-radius: 50%;
 }
 
 .zhuanj ul li .item-dec {
   margin-top: 1%;
   color: #fff;
 }
 
 .zhuanj ul li .item-dec .teacher-name {
   font-size: 1em;
   line-height: 1.5em;
   color: #FFFFFF;
   text-align: center
         
 }
 
 .zhuanj ul:nth-child(10n+0) {
   
 }
 
 .zhuanj ul:nth-child(10n+1) {
   
 }
 
 .zhuanj ul:nth-child(10n+2) {
   
 }
 
 .zhuanj ul:nth-child(10n+3) {
   
 }
 
 .zhuanj ul:nth-child(10n+4) {
   
 }
 .zhuanj ul:nth-child(10n+5) {
   
 }
 .zhuanj ul:nth-child(10n+6) {
   
 }
 .zhuanj ul:nth-child(10n+7) {
   
 }
 .zhuanj ul:nth-child(10n+8) {
   
 }
 .zhuanj ul:nth-child(10n+9) {
   
 }
 .zhuanj ul:nth-child(10n) {
   display: flex;
   justify-content: center;
  /* 水平居中 */
   align-items: center;
 }
 
 
 
 
 /*进度条样式*/
 .lyfive {
   width: 76.8%;
   height: 3vm;
   margin: 2.4% auto;
 }
 
 .jinw {
   width: 35%;
   font-size: 2em;
   color: #FFFFFF;
   text-align: center;
   float: left;
   background: #fff100;
 }
 
 .reviews-container {
   width: 35%;
   font-size: 2em;
   color: #FFFFFF;
   text-align: center;
   float: left;
   background: #00ffff;
   margin-left: 8%;
 }
 
 
 .progress-box{display: flex;}
 .progress-box .wcl{font-size: 30px;color: #fff;height: 35px;line-height: 35px;margin-left: 20px;}
 .progress-box .wclleft{font-size: 30px;color: #fff;height: 35px;line-height: 35px;margin-right: 20px;}
 .progress {
     background: #00a2eb;
     /*#007ad0*/
     border-radius: 50px;
     height: 35px;
     margin-bottom:3%;
     -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
     box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
     overflow: hidden;
     border: 2px solid #77cbfa;
     display: flex;
     flex: 1;
   }
   
 .progress-bar {
     position: relative;
     height: 35px;
     border-radius: 50px;
     /* -webkit-transition: 0.4s linear;
     -moz-transition: 0.4s linear;
     -o-transition: 0.4s linear;
     transition: 0.4s linear;
     -webkit-transition-property: width, background-color;
     -moz-transition-property: width, background-color;
     -o-transition-property: width, background-color;
     transition-property: width, background-color; */
     width: 75%;
     background-size: 3em 3em;
     /*background-image: linear-gradient(-45deg, #0097fc 0em, #0097fc 0.8em, #00e4fd 0.9em, #00e4fd 2.1em, #0097fc 2.1em, #0097fc 2.9em, #00e4fd 3.1em);*/
     background-color: red;
     -webkit-animation: warning-animation 750ms infinite linear;
     -moz-animation: warning-animation 750ms infinite linear;
     animation: warning-animation 750ms infinite linear;
     overflow: hidden;
 }
 .progress-bar::after {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
 }
 .progress-bar::after {
     z-index: 2;
     bottom: 45%;
     border-radius: 50px;
     background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
     background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
     background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
 }
 .progress .ywc{color: #fff;position: absolute;top: 0;left: 0;font-size: 22px;height: 48px;line-height: 35px;text-align: center;width: 100%;}
 .progress .wwc{color: #fff;flex: 1;height: 48px;line-height: 35px;text-align: center;font-size: 22px;}
 
 
 .project-name{background: #3f91c9;border: 1px solid #fff;border-radius: 4px;overflow: hidden;padding: 15px;color: #fff;font-weight: bold;font-size: 36px;display: inline-block;}
 .project-name .city{background: #faab11;border-radius: 15px;display: inline-block;padding: 5px 20px;margin-right: 30px;}
   
 </style>