<template>
  <div class="main-body" v-if="stageliveBgUrl!=null && stageliveBgUrl!=''" :style="{'background-image': 'url(' + stageliveBgUrl + ')'}">
    <!--<div class="main-body">-->
     <div class="main">
        <div class="title-box" id="titleBox" v-if="partNumber!=0">
          <div class="title" v-if="projData.contestName"></div>
          <!-- {{projData.contestName}}-->
          <div class="huanjie" v-if="projData.stageName"></div>
          <!-- {{projData.stageName}} -->
          <div class="huanjie" v-if="projData.stageliveShowName==1">{{projData.contestName}}</div>
          
            <div class="dfb-box">
                <div class="dfb-title">实时得分榜
                  <span class="dfb-title-tips">40秒更新一次</span>
                </div>
                <div class="dfb-head">
                  <div>排名</div>
                  <div>项目名</div>
                  <div>得分</div>
                </div>
                <div class="dfb-table-box">
                  <dv-scroll-board
                    :config="this.config"
                    style="width:1000px;height:500px;color:black;font-size:30 !important"
                  />
                </div>
            </div>
        </div>

      </div>
  </div>
</template>

<script>
var timer;

export default {
  data() {
    return {
      stageliveId: null, //现场id
      projData: [],
      cityHtml: null,
      contprojName: null,

      partNumber: 1,
      nowProj: [],

      TimeCountDown: null,
      colorRed: false,

      pwList: [],

      progressBarWidth: 0,
      wclHTML: "",

      finalScore: null,

      stageliveBgUrl: null,

      projScoreList: [],
      config: {
        data: [
  
         ]
      }
    };
  },
  methods: {
    //获取指定大赛现场信息
    getStageLive() {
      var that = this;
      this.$post("/api/stagelive/get", {
        stageliveId: this.stageliveId
      })
        .then(res => {
          // console.log(res);
          if (res.result) {
            let data = res.resultData,
              projectList = res.resultData.contprojList;

            this.projData = res.resultData;
            console.log(data.stageliveBgUrl);
            console.log(this.$store.state.baseHost);
            if (data.stageliveBgUrl) {
              this.stageliveBgUrl =
                this.$store.state.baseHost + "/" + data.stageliveBgUrl;
            }

            if (data.contprojList.length) {
              if (
                data.contprojList[0].contprojRegion != null &&
                data.contprojList[0].contprojRegion != ""
              ) {
                this.cityHtml = data.contprojList[0].contprojRegion;
              }
              this.contprojName = data.contprojList[0].contprojName;
            }

            that.getProjScore();

            clearInterval(timer);
            timer = setInterval(function() {
              that.getProjScore();
            }, 40000);
          } else {
            this.$message.error(res.resultMsg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    },
    //获取分数
    getProjScore() {
      this.$post("/api/stagelive/listLiveProjScores", {
        stageliveId: this.stageliveId
      })
        .then(res => {
          console.log(res);
          if (res.result) {
            this.projScoreList = res.resultData;
            this.getData(this.projScoreList);
            // console.log(this.projScoreList);
          } else {
            this.projScoreList = [];
          }
        })
        .catch(res => {
          console.log(res);
        });
    },
    getData(data) {
      var result = [];
      for (let i = 0; i < data.length; i++) {
        var arr = [];        
        arr.push('<span style="font-size:22px;  font-family: '+'Times New Roman'+', Times, serif">'+'No.'+(i + 1)+'</span>' );
        arr.push('<span style="font-size:22px;margin: 0;padding: 0;">'+data[i].contprojName+'</span>');
        if(data[i].score == -1){
          arr.push('<span style="font-size:22px;color: #ed481a">--</span>');
        }else{
          arr.push('<span style="font-size:27px;color: #ed481a";  font-weight: bold;>'+data[i].score+'</span>');
        }
        
        // console.log(arr)
        result.push(arr);
        
      }
      console.log(result)
      this.config = {
        data: result,
        oddRowBGC: '#eaeff7',
        evenRowBGC: '#d2deef',
        waitTime:3000,
        columnWidth: [150,700,150]
      };
      // console.log(this.config)
    }
  },

  mounted() {
    if (this.$route.query.stageliveId) {
      this.stageliveId = this.$route.query.stageliveId;
      this.getStageLive();
    } else {
      this.$message.error("缺少现场id");
    }
  }
};
</script>

<style scoped>

.main-body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: url("../assets/touping-bg1.png") no-repeat center;
  background-size: cover;
}

.main {
  max-width: 1340px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.main .title-box {
  margin-bottom: auto;
  margin-top: 290px;

}
.main .title {
  font-size: 69px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 149px;
  font-family: KaiTi;
}
.main .huanjie {
  font-size: 46px;
  color: #fae111;
  font-weight: bold;
  margin-bottom: 37px;
  margin-top: -283px;
}
.main .project-name {
  background: #3f91c9;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 36px;
  display: inline-block;
}
.main .project-name .city {
  background: #faab11;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 20px;
  margin-right: 30px;
}
.main .content {
  width: 100%;
}

/* dfb-box是数据展示总高度，修改后，也要修改dfb-table-box的值，dfb-table-box = dfb-box的高度 减去 dfb-title的高度 减去 dfb-head的高度 */
.main .dfb-box {
  width: 1000px;
  height: 640px;
  background: #fff;
  margin: 0 auto;
  overflow: hidden;
}
.main .dfb-box .dfb-title {
  background: #5b6ad5;
  height: 70px;
  line-height: 70px;
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 35px;
  font-weight: bold;
  color: #fff;
}
.main .dfb-box .dfb-title .dfb-title-tips {
  font-size: 17px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 0px;
}
.main .dfb-box .dfb-head {
  /* width: 50px; */
  line-height: 50px;
  background: #d2deef;
  overflow: hidden;
  width: 100%;
  border-bottom: 2px solid;
}
.main .dfb-box .dfb-head div {
  width: 200px;
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.main .dfb-box .dfb-head div:nth-of-type(1) {
  /* width: 500px; */
  text-align: left
}
.main .dfb-box .dfb-head div:nth-of-type(2) {
  width: 500px;
  /* text-align: left */
}
.main .dfb-box .dfb-head div:nth-of-type(3) {
  /* width: 500px; */
  text-align: right
}
.main .dfb-box .dfb-table-box {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.main .dfb-box table {
  width: 100%;
  height: auto;
  text-align: center;
}
.main .dfb-box table tbody tr {
  height: 50px;
  line-height: 50px;
}
.main .dfb-box table tbody tr:nth-child(odd) {
  background: #eaeff7;
}
.main .dfb-box table tbody tr:nth-child(even) {
  background: #d2deef;
}
.main .dfb-box table tbody tr td {
  width: 250px;
  font-size: 23px;
}
.main .dfb-box table tbody tr td:nth-of-type(2) {
  width: 500px;
  font-size: 23px;

}
.main .dfb-box table tbody tr td:nth-of-type(2) div {
  width: 500px;
  font-size: 23px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main .dfb-box table tbody tr td:last-of-type {
  color: #ed481a;
  font-weight: bold;
}
.dv-scroll-board .rows .row-item{
  font-size: 180rem;
}
</style>