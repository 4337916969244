<template>
    <div>
        <el-backtop></el-backtop>
        <footer>
            <div class="content">版權所有: {{this.$store.state.orgData.orgName}}</div>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
/* 底部样式 */
footer{width: 100%;height: 80px;min-width:1200px;
/* background: #0764c4; */
background: #0f9ddb;
color: #fff;display: flex!important;justify-content: center;align-items: center;}
footer .content{width: 1200px;text-align: center;margin:0 auto;}
footer .content a{color:#fff;}

</style>