<template>
    <div>
        <div class="hidden-md-and-down">
            <nav-bar :currentId="99"></nav-bar>
            <div class="main">
                <div class="ps-title"><router-link to="/jspingshen_index">网络评议</router-link>/<span @click="back">评议项目列表</span>/项目评议</div>
                <div class="container">
                    <div class="mainBox">
                        <div class="saishi-box">
                            <div class="saishi-pic">
                                <!--<img :src="$store.state.baseHost+'/fs/download?storeKey='+projData.contestCoverUrl" :onerror="defaultImg"/>-->
                                <img src="../assets/proj_img.png" />
                            </div>
                            <div class="saishi-info">
                                <div class="saishi-info-title" id="contprojName"><span style="margin-right:40px">{{projData.contprojName?projData.contprojName:'暂无'}}</span></div>
                            </div>
                        </div>
                    </div>
                    <el-button  class="btn btn-default goBack" @click="back();">返回评议项目列表</el-button>
                </div>
                <div class="container2">
                    <div class="title1">
                        <span>项目详情</span>
                    </div>
                    <div class="p-item">
                        <div class="p-item">
                            <div class="p-name">成果申报材料</div>
                            <div class="p-content" id="attachList">
                                <template v-if="attachList.length">
                                    <div class="p-download" v-for="(item,index) in attachList" :key="index"><a :href="item.projattachFileUrl" target="_blank">{{item.projattachFileName}}</a></div>
                                </template>
                                <template v-else>
                                    -- 暂无附件 --
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container2">
                    <div class="title1">
                        <span>项目评分</span>
                    </div>
                    <div class="p-item">
                        <div class="p-name">评议要点</div>
                        <div class="p-content">
                             <div id="pagination-rules">
                                <div class="card-tip" :style="{height:!autoRulesHeight?'120px':rulesHeight}"><div ref="judgeruleRemark"><span id="judgeruleRemark" v-html="judgeruleRemark"></span></div></div>
                            </div>
                            <div class="rules-control" v-if="showMoreRules" @click="showMoreRulesFN">{{!autoRulesHeight ? '展开评议要点':'收起评议要点'}}<span :class="!autoRulesHeight?'down':'up'"></span></div>
                        </div>
                    </div>
                    <div class="p-item">
                        <div class="p-name">评分列表</div>
                        <div class="p-content">
                            <div class="pagination-box" id="card_5">
                                <div class="">
                                    <el-table show-summary :data="itemList" border :summary-method="getSummaries">
                                        <el-table-column type="index" label="序号" width="80"  align="center"></el-table-column>
                                        <el-table-column prop="jruleitemName" label="评分内容"  align="center"></el-table-column>
                                        <el-table-column prop="jruleitemScore" label="满分"  align="center"></el-table-column>
                                        <el-table-column prop="judgeitemScore" label="评委评分" align="center">
                                            <template slot-scope="{row}">
                                                <el-input v-model="row.judgeitemScore" placeholder="请输入分数" @input="change(row)"></el-input>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    
                                    <div class="card-end">
                                        <el-button type="button" @click="back">返回</el-button>&nbsp;
                                        <template v-if="stagejudgeStatus==1 && stageJudgeConfirm==0">
                                            <el-button type="primary" disabled><i class="icon-ok"></i>&nbsp;已评分</el-button><span style="margin-left: 10px;color: #545454;text-decoration: underline;">评分时间：{{stagejudgeScoreTime}}</span>
                                        </template>
                                        <template v-else>
                                            <el-button type="primary" @click="save">提交评分</el-button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <bottom-bar></bottom-bar>
        </div>
         <!-- 移动端 -->
		<div class="hidden-lg-and-up phone_padding" style="overflow: inherit;">
            <phone-header :phoneCurrentId="99"/>
            <div class="xs_main">
                <div class="xs_ps_wrap">
                      <div class="xs_ps_title"><span @click="goList">网络评议</span>/<span @click="back">评议项目列表</span>/项目评议</div>
                      <div class="xs_ps_main">
                          <!-- 项目信息 -->
                            <div class="xs_main_top">
                                <div class="xs_back">
                                    <van-button style="width:100%" type="default" size="small" @click="back">返回评议项目列表</van-button>
                                </div>
                                <div class="xs_main_pro_info">
                                    <div class="xs_main_pro_info_left">
                                            <img :src="$store.state.baseHost+'/fs/download?storeKey='+projData.contestCoverUrl" :onerror="defaultImg"/>
                                    </div>
                                    <div class="xs_main_pro_info_center">
                                        <div class="xs_main_info_right_title">{{projData.contprojName?projData.contprojName:'暂无'}}</div>
                                        <!-- <div class="xs_main_info_right_group"><van-tag plain type="warning" size="large" style="margin-right:10px">{{projData.contprojGroup?projData.contprojGroup:'暂无'}}</van-tag><van-tag plain type="primary" size="large">{{projData.contprojRegion}}</van-tag></div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- 项目详情 -->
                            <div class="xs_main_center">
                                <span class="xs_main_box_title">项目详情</span>
                                <div class="xs_main_box_item_wrap">
                                    <div class="xs_main_box_item">
                                        <div class="xs_main_box_item_p">
                                            成果申报材料
                                        </div>
                                        <div class="xs_main_box_item_content">
                                            <template v-if="attachList.length">
                                                    <div class="p-download" v-for="(item,index) in attachList" :key="index" style="display:flex;padding: .15rem 0">
                                                        <img src="../assets/download-icon.png" alt="" style="width:.4rem;height:.4rem;margin-right:.2rem">
                                                        <a :href="item.projattachFileUrl" target="_blank" style="color:#259dee">{{item.projattachFileName}}</a>
                                                    </div>
                                            </template>
                                            <template v-else>
                                                -- 暂无附件 --
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="xs_main_bottom">
                                <span class="xs_main_box_title">项目评分</span>
                                <div class="xs_main_box_item">
                                        <div class="xs_main_box_item_p">
                                                    评议要点
                                        </div>
                                        <div class="xs_main_box_item_content">
                                                    <div class="xs_main_box_tips">
                                                            <input type="text" id="read-more">
                                                            <label for="remode-more" v-if="showBtn"></label>
                                                        <div class="xs_tips_content" v-html="judgeruleRemark" :class="{'more': ismore}" ref="phoneJudgeruleRemark" :style="{'height':showBtn ? '200px':'','padding-bottom':ispadding ? '60px': ''}"></div>
                                                        <van-button plain  icon="arrow-down" type="info" class="xs_show_more" @click="showMoreContent" v-if="showBtn">展开评议要点</van-button>
                                                        <van-button plain  icon="arrow-up" type="info" class="xs_show_more" @click="hideContent" v-if="hideBtn">收起评议要点</van-button>
                                                    </div>
                                                    <div class="xs_pf_list">
                                                        <!-- <div class="pf_list_title">评分列表</div> -->
                                                        <div class="xs_main_box_item_p" style="margin: .3rem 0;">
                                                            评分列表
                                                        </div>
                                                        <table style="width:100%">
                                                            <colgroup>
                                                                <col width="80">
                                                                <col width="200">
                                                                <col width="120">
                                                                <col width="200">
                                                                <col>
                                                            </colgroup>
                                                            <thead>
                                                                <tr>
                                                                    <th>序号</th>
                                                                    <th>评分内容</th>
                                                                    <th>满分</th>
                                                                    <th>评委评分</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item,index) in itemList" :key="index">
                                                                    <td>{{parseInt(index+1)}}</td>
                                                                    <td>{{item.jruleitemName}}</td>
                                                                    <td>{{item.jruleitemScore}}</td>
                                                                    <td><van-field v-model="item.judgeitemScore" type="number" @input="handleInput(item,index)" input-align="center" placeholder="请输入分数"/></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>合计</th>
                                                                    <th>{{tFootMaxScore}}</th>
                                                                    <th>{{tfootScore}}</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                    <!-- <div class="xs_py">
                                                        <div class="xs_main_box_item_p" style="margin: .3rem 0;">
                                                            评委评语
                                                        </div>
                                                        <div>
                                                            <van-field
                                                                style="border: 1px solid #eee"
                                                                v-model="message"
                                                                rows="4"
                                                                autosize
                                                                type="textarea"
                                                                maxlength="500"
                                                                placeholder="请输入评语"
                                                                show-word-limit
                                                                :disabled="phoneTextareaDisabled"
                                                                />
                                                        </div>
                                                        <div style="font-size:.3rem;text-align: right;margin-top:.2rem;color:#999999">评委：<span >{{this.$store.state.userInfo.judeger.judgerRealname}}</span></div>
                                                    </div> -->
                                        </div>
                                </div>
                                <div class="sbmit_btn_group">
                                    <van-button type="default" size="normal" @click="back">返回</van-button>
                                    <van-button type="info" size="normal" style="margin-left:.8rem" @click="phoneSave()">提交评审</van-button>
                                </div>
                            </div>
                      </div>
                </div>
            </div>
            <phone-footer />
        </div>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import { isWeiXin, isMobile } from '../utils/userAgent.js'
export default {
    components: {
        navBar,
        bottomBar,
    },
    data () {
        return {
            contprojId:null,//项目id
            stageId: '',//环节id
            stagejudgeId: null,//评分id
            contestId: null,
            stageJudgeConfirm:  this.$route.query.stageJudgeConfirm,//是否需要评审确认，1-是，0-否，如果需要，在评审结束前一直修改分数
            projData: {},//项目数据
            attachList:[],//附件数据
            stagejudgeContent: '无',//评语
            stagejudgeContentDisabled: false,//是否可以评分
            itemList: [],//评分情况数据
            judgeruleRemark:'',//评分规则
            stagejudgeStatus:null,//是否已评价，0-未评，1-已评
            // formData:[],//提交的评分项数据

            showMoreRules: false,//显示更多规则按钮
            autoRulesHeight: false,//评分规则高度，false-120px；true-auto
            rulesHeight: '',//规则的实际高度
            stagejudgeScoreTime: null,//评审时间
            
            defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',
            // 移动端项目团队默认头像
            imgSrc: require('../assets/photo0.png'),
            // 评分列表表脚满分合计
            tFootMaxScore: 0,
            // 评分列表表脚评委评分合计
            tfootScore: 0,
            // 评委评语
            message: '无',
            phoneTextareaDisabled: false,
            ismore: false,
            showBtn: false,
            hideBtn: false,
            ispadding: false,
            //打分位数
            stageJudgeDigit: 1,
        }
    },
    methods: {
        // ---------------移动端start----------------------
        goList(){
            this.$router.push("/jspingshen_index")
        },
        // 展开评审要点
        showMoreContent(){
            this.ismore = true
            this.hideBtn = true
            this.showBtn = false
            this.ispadding = true
        },
        hideContent(){
            this.ismore = false
            this.showBtn = true
            this.hideBtn = false
        },
        handleInput(item,index){
            if(Number(item.judgeitemScore) > Number(item.jruleitemScore)){
                this.$toast.fail('评分不能大于' + item.jruleitemScore);
                console.error('评分不能大于' + item.jruleitemScore);
                item.judgeitemScore = ''
                this.tfootScore = 0
                return
            }
            if(Number(item.judgeitemScore)<0){
                this.$toast.fail('评分必须大于等于0');
                console.error('评分必须大于等于0');
                item.judgeitemScore = ''
                 this.tfootScore = 0
                return
            }
            let val = item.judgeitemScore;
            if (val.indexOf(".") != -1) {
                let vlast = val.split(".")[1];
                if(vlast.length>this.stageJudgeDigit){
                     this.$toast.fail('小数点尾数最多只能输入'+this.stageJudgeDigit+'位!');
                    item.judgeitemScore = ''
                     this.tfootScore = 0
                }
            }
            let num = 0
            this.itemList[index].judgeitemScore = item.judgeitemScore
            this.itemList.forEach((item,i)=>{
                num += Number(this.itemList[i].judgeitemScore)
            })
            this.tfootScore = num
        },
        // ---------------移动端end----------------------
        //获取项目数据
        getProjData(){
            this.$post("/api/contproj/queryContproj",{
                contprojId: this.contprojId,
                withAudit: 0,
            }).then((res)=>{
                // console.log(res);
                if(res.result){
                    this.projData = res.resultData.contproj;
                    this.contestId = this.projData.contestId;
                }else{
                    // this.$message.error(res.resultMsg);
                     if(isWeiXin() || isMobile()){
                            this.$toast.fail(res.resultMsg);
                    } else {
                            this.$message.error(res.resultMsg);
                    }
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //获取附件
        getEnclosure(needView){
            this.$post("/api/contproj/listAttach",{
                contprojId: this.contprojId,
                // stageId: this.stageId, //环节ID，默认是0，即为报名阶段的附件
                needView:needView,//	是否需要预览：0-否；1-是，默认是1
            }).then((res)=>{
                // console.log(res);
                if(res.result){
                    let attachList = res.resultData;
                    for(let i=0;i<attachList.length;i++){
                        let projattachFileUrl;
                        if(needView==1){
                            projattachFileUrl = 'http://ow365.cn/?i=22464&n=5&furl=' + attachList[i].projattachFileUrl;
                        }else{
                            projattachFileUrl = this.$store.state.baseHost + '/fs/download?storeKey=' + attachList[i].projattachFileUrl;
                        }
                        if (this.judgmentFile(attachList[i].projattachFileName) == true) {
                            projattachFileUrl = this.$store.state.baseHost + '/' + attachList[i].projattachFileUrl;
                        }
                        attachList[i].projattachFileUrl = projattachFileUrl;
                    }
                    this.attachList = attachList;
                }else{
                    // this.$message.error(res.resultMsg);
                     if(isWeiXin() || isMobile()){
                            this.$toast.fail(res.resultMsg);
                    } else {
                            this.$message.error(res.resultMsg);
                    }
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        //获取评分情况
        getJudge(){
            this.$post("/api/stage/queryJudge",{
                stagejudgeId: this.stagejudgeId,
            }).then((res)=>{
                // console.log(res);
                if(res.result){
                    var data = res.resultData.stageJudge, stageGroup = res.resultData.stageGroup, judgeruleRemark = '暂无评分说明信息.';
                    this.stagejudgeStatus = data.stagejudgeStatus;//评分状态，0=未评,1=已评
                    if(res.resultData.stage.stageJudgeDigit){
                        this.stageJudgeDigit = res.resultData.stage.stageJudgeDigit;
                    }
                    // console.log(this.stagejudgeStatus,this.stageJudgeConfirm)
                     // 移动端
                    let sum = 0
                    data.judgerule.itemList.forEach(item=>{
                        sum += item.jruleitemScore
                        console.log(item);
                    })
                    this.tFootMaxScore = sum
                    //移动端
                    if(data.stagejudgeStatus == 0){//未评
                        this.itemList = data.judgerule.itemList;//评分项数据
                        this.stagejudgeContent = '无';//评语
                    }else{//已评
                        let itemList = data.judgerule.itemList;
                        for (var i = 0; i < data.itemList.length; i++) {
                            for (var j = 0; j < itemList.length; j++) {
                                if (itemList[j].jruleitemId == data.itemList[i].jruleitemId) {
                                    itemList[j].judgeitemScore = data.itemList[i].judgeitemScore;
                                    break;
                                }
                            }
                        }
                        this.itemList = itemList;//评分项数据
                        this.stagejudgeContent = data.stagejudgeContent;//评语
                        this.stagejudgeScoreTime = data.stagejudgeScoreTime;//评审时间

                        if(this.stageJudgeConfirm == 0){//不需要评审确认，只能评一次分数,禁用掉评语
                            this.stagejudgeContentDisabled = true;
                        }
                        
                    }

                    //评分规则
                    if (stageGroup.stagegroupRemark != null && stageGroup.stagegroupRemark != '') {
                        judgeruleRemark = stageGroup.stagegroupRemark;
                    } else {
                        if (data.judgerule.judgeruleRemark != null && data.judgerule.judgeruleRemark != '') {
                            judgeruleRemark = data.judgerule.judgeruleRemark;
                        }
                    }
                    this.judgeruleRemark = judgeruleRemark;
                    setTimeout(()=>{
                        // console.log(this.$refs.judgeruleRemark.offsetHeight)//计算评分规则的高度
                        let h = this.$refs.judgeruleRemark.offsetHeight;
                        this.rulesHeight = (this.$refs.judgeruleRemark.offsetHeight + 67) + 'px';
                        if(h>80){//显示更多规则
                            this.showMoreRules = true;
                        }else{
                            this.showMoreRules = false;
                        }
                         let phoneh =  this.$refs.phoneJudgeruleRemark.offsetHeight
                        console.log(phoneh);
                        if(phoneh > 400){
                            this.showBtn = true
                        } else {
                            this.showBtn = false
                        }
                    },1)

                }else{
                    // this.$message.error(res.resultMsg);
                     if(isWeiXin() || isMobile()){
                            this.$toast.fail(res.resultMsg);
                    } else {
                            this.$message.error(res.resultMsg);
                    }
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        showMoreRulesFN(){
            this.autoRulesHeight = !this.autoRulesHeight;
        },
        //提交评分
        save(){
            let jruleItemList = [];
            for(let i=0;i<this.itemList.length;i++){
                if(this.itemList[i].judgeitemScore == null || this.itemList[i].judgeitemScore == ''){
                    this.$message.error('请确认已输入分数')
                    return
                }
                jruleItemList.push({jruleitemId:this.itemList[i].jruleitemId,judgeitemScore:this.itemList[i].judgeitemScore})
            }
            if(this.stagejudgeContent=='' || this.stagejudgeContent==null){
                this.$message.error('请输入评语');
                return
            }

            // console.log(
            //     this.stagejudgeId,//评分id
            //     this.stageId,//	number	必填	环节ID	
            //     this.contprojId,//	number	必填	项目ID	
            //     this.$store.state.userInfo.judeger.judgerId,//	number	必填	评委ID	
            //     this.stagejudgeContent,//	string	可选	评价内容	
            //     JSON.stringify(jruleItemList)
            // )
            
             this.$confirm('确定要提交评分结果吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '正在提交',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                this.$post("/api/stage/updateJudge",{
                    stagejudgeId: this.stagejudgeId,//评分id
                    stagejudgeStageId: this.stageId,//	number	必填	环节ID	
                    stagejudgeContprojId: this.contprojId,//	number	必填	项目ID	
                    stagejudgeJudgerId: this.$store.state.userInfo.judeger.judgerId,//	number	必填	评委ID	
                    stagejudgeContent: this.stagejudgeContent,//	string	可选	评价内容	
                    jruleItemList:JSON.stringify(jruleItemList),//	string	必填	评分规则项列表JSON字符串
                }).then((res)=>{
                    loading.close();
                    // console.log(res);
                    if(res.result){
                        this.$message.success('打分成功!')
                        this.back();
                    }else{
                        this.$message.error(res.resultMsg);
                    }
                }).catch((res)=>{
                    loading.close();
                    console.log(res);
                })

            }).catch(()=>{
                return;
            })
        },
        // 移动端提交评分
        phoneSave() {
            let jruleItemList = [];
            for(let i=0;i<this.itemList.length;i++){
                if(this.itemList[i].judgeitemScore == null || this.itemList[i].judgeitemScore == ''){
                    this.$toast.fail('请确认已输入分数')
                    return
                }
                jruleItemList.push({jruleitemId:this.itemList[i].jruleitemId,judgeitemScore:this.itemList[i].judgeitemScore})
            }
            // if(this.message=='' || this.message==null){
            //     this.$toast.fail('请输入评语');
            //     return
            // }
            this.$dialog.confirm({
                title: '提示',
                message: '确定要提交评分结果吗',
            }).then(() => {
                this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                });
                // on confirm
                try {
                    this.$post("/api/stage/updateJudge",{
                    stagejudgeId: this.stagejudgeId,//评分id
                    stagejudgeStageId: this.stageId,//	number	必填	环节ID	
                    stagejudgeContprojId: this.contprojId,//	number	必填	项目ID	
                    stagejudgeJudgerId: this.$store.state.userInfo.judeger.judgerId,//	number	必填	评委ID	
                    stagejudgeContent: this.message,//	string	可选	评价内容	
                    jruleItemList:JSON.stringify(jruleItemList),//	string	必填	评分规则项列表JSON字符串
                }).then(res=>{
                        console.log(res);
                        if(res.result){
                            this.$toast.success(res.resultMsg);
                            setTimeout(() => {
                                    this.back();
                            }, 500);
                        } else {
                             this.$toast.fail(res.resultMsg);
                        }
                    })
                  .catch(err=>{
                      console.log(err);
                  })
                } catch(error){
                    console.log(error);
                } finally {
                    this.$toast.clear()
                }
            }).catch(() => {
                // on cancel
            });
        },
        //评分比例
        jruleitemScoreFormatter(row, column){
            if(row.jruleitemWeight!=null){
                return row.jruleitemWeight * 100 + '%';
            }else{
                return '--';
            }
        },
        change(row){
            // console.log(row);
            if(Number(row.judgeitemScore) > Number(row.jruleitemScore)){
                this.$message.error('评分不能大于' + row.jruleitemScore)
                row.judgeitemScore = '';
            }
            if(Number(row.judgeitemScore)<0){
                this.$message.error('评分必须大于等于0')
                row.judgeitemScore = '';
            }
           // if(row.judgeitemScore.indexOf('.') > 0){
                //this.$message.error('请输入整数');
               // row.judgeitemScore = '';
           // }
            let val = row.judgeitemScore;
            if (val.indexOf(".") != -1) {
                let vlast = val.split(".")[1];
                if(vlast.length>this.stageJudgeDigit){
                    this.$message.error('小数点尾数最多只能输入'+this.stageJudgeDigit+'位!')
                    row.judgeitemScore = '';
                }
            }

            // console.log(this.itemList)
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '';
                    return;
                }
                if (index === 1) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += '';
                } else {
                    sums[index] = 'N/A';
                }
            });
            return sums;
        },
        //判断是否为mp4
        judgmentFile(picUrl) {
            let filename = picUrl; //文件路径地址
            let index1 = filename.lastIndexOf(".");
            let index2 = filename.length;
            let postf = filename.substring(index1, index2); //获取文bai件后缀名duzhi
            //判断文件后缀名是否等于视频文件的后缀名
            if (postf === ".avi" || postf === ".mp4" || postf === ".rmvb") {
                return true
            } else {
                return false
            }
        },
        back(){
            //this.$router.go(-1);
            this.$router.push({
                name: 'jspingshen_list',
                query: {
                    stageId: this.stageId,
                    contestId: this.contestId,
                    currentPage: this.$route.query.currentPage
                }
            })
        },
    },
    mounted () {
        

        if(this.$route.query.type){
            this.select_type = this.$route.query.type;
            sessionStorage.setItem('select_type',this.$route.query.type);
        }else{
            sessionStorage.removeItem('select_type');
        }
        if(this.$route.query.contprojId && this.$route.query.stageId && this.$route.query.stagejudgeId){
            this.contprojId = this.$route.query.contprojId;
            this.stageId = this.$route.query.stageId;
            this.stagejudgeId = this.$route.query.stagejudgeId;
            this.getProjData();
            this.getEnclosure(1);
            this.getJudge();

        }else{
            if(!this.$route.query.contprojId){
               if(isWeiXin() || isMobile()){
                        this.$toast.fail('缺少项目ID');
                } else {
                        this.$message.error('缺少项目ID');
                }
            }else if(!this.$route.query.stageId){
               if(isWeiXin() || isMobile()){
                        this.$toast.fail('缺少环节ID');
                } else {
                        this.$message.error('缺少环节ID');
                }
            }else if(!this.$route.query.stagejudgeId){
               if(isWeiXin() || isMobile()){
                        this.$toast.fail('缺少评分ID');
                } else {
                        this.$message.error('缺少评分ID');
                }
            }
        }
        

    },
    
}
</script>

<style scoped>
.main{width: 100%;height: auto;min-height: 92px;background: #e8edf3 url('../assets/pingshen-bg.png') no-repeat top center;margin: 0 auto;min-width: 1200px;}
.main .ps-title{width: 1200px;height: 60px;line-height: 60px;margin:0 auto;color: #fff;font-size: 20px;}
.main .ps-title a{color: #fff;}
.main .ps-title span{color: #fff;cursor: pointer;}


.container{width: 1200px;margin: 0 auto;background: #fff;height: auto;padding:60px 66px 66px 66px;position: relative;box-sizing: border-box;}
.container .mainBox{width: 100%;margin: 0 auto;}
.container .mainBox .saishi-box{width: 100%;height: 140px;display: flex;}
.container .mainBox .saishi-pic{width: 240px;height: 140px;}
.container .mainBox .saishi-pic img{width: 240px;height: 140px;}
.container .mainBox .saishi-info{flex:1;height: 140px;overflow: hidden;margin-left: 20px;display: flex;align-items: center;flex-direction: column;justify-content: space-between;}
.container .mainBox .saishi-info .saishi-info-title{width: 100%;font-size: 26px;color: #000000;word-break:break-all;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:3;/**显示的行数**/
    overflow:hidden;}
.container .mainBox .saishi-info .saishi-info-title .saishi-info-city{display:inline-block;width: auto;height: 22px;line-height: 22px;box-sizing: content-box;font-size: 20px;color: #000;border: 1px solid #038eeb;border-radius: 4px;padding: 0 6px;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
}
.container .mainBox .saishi-info .saishi-info-category{width: 100%;height: 30px;line-height: 30px;font-size:28px;color:orange;font-weight:bold;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}

.goBack{height: 40px;padding: 0 25px;position: absolute;top: 10px;right: 10px;}

.container2{width: 1200px;height: auto;margin: 0 auto;background: #fff;margin-top: 20px;padding: 20px 40px;box-sizing: border-box;}
.container2 .title1 span{border-bottom: 3px solid #007eea;width: auto;display: inline-block;font-size:22px;height: 30px;line-height: 30px;box-sizing: content-box;color: #007eea;}
.container2 .p-item{width: 100%;margin: 20px 0;}
.container2 .p-item .p-name,.container2 .pagination-box .p-item .p-name,.container2 .pagination-rules .p-item .p-name{font-size: 20px;color:#000000;padding-left: 10px;position: relative;height: 30px;line-height: 30px;margin-bottom: 5px;}
.container2 .p-item .p-name::before,.container2 .pagination-box .p-item .p-name::before,.container2 .pagination-rules .p-item .p-name::before{content: "";width: 4px;height: 18px;background: #007eea;position: absolute;left: 0;top: 8px;}
.container2 .p-item .p-content{padding-left: 6px;font-size: 18px;line-height:1.5;color: #4e4c4c;overflow: hidden;padding:10px 0;}
.container2 .p-item .p-content .p-people{width: 210px;height: 90px;display: flex;justify-content: center; align-items: center;float: left;}
.container2 .p-item .p-content .p-people .img{width: 60px;height: 60px;background: url('../assets/photo0.png') no-repeat center;background-size: contain;margin-right: 25px;}
.container2 .p-item .p-content .p-people .p1{font-size: 18px;color: #000;height: 30px;line-height: 30px;}
.container2 .p-item .p-content .p-people .p2{font-size: 20px;color: #999;height: 30px;line-height: 30px;}
.container2 .p-item .p-content .p-download{width: 100%;height: 30px;line-height: 30px;color: #000;font-size: 15px;}
.container2 .p-item .p-content .p-download a{color: #259dee;padding-left: 20px;position: relative;}
.container2 .p-item .p-content .p-download a::before{content: '';width:15px;height:13px;background: url('../assets/download-icon.png');position: absolute;left: 0;top: 5px;}
.container2 .p-item .p-content .p-download span{padding-left: 20px;}
.container2 .p-item .p-content .p-download span strong{font-size: 18px;}



.container2 .pagination-box{margin: 0 auto;}
.container2 .pagination-box .pingfen{width: 428px;height: 25px;line-height: 25px;padding: 8px 10px;border: 1px solid #7a7b7d;border-radius: 4px;box-sizing: content-box;outline-color: #259dee;}
.container2 .pagination-box .pingyu{width: 428px;height: 80px;line-height: 25px;padding: 8px 10px;border: 1px solid #7a7b7d;border-radius: 4px;box-sizing: content-box;outline-color: #259dee;resize: none;}

.container2 .pagination-box .p-btn{width: 450px;height: 40px;line-height: 40px;color: #fff;font-size: 15px;text-align: center;border-radius: 4px;margin: 0 auto;}

.container2 .pagination-rules{width: 990px;height: 130px;margin: 0 auto;overflow: hidden;}
.container2 .pagination-rules .rules-item{margin-top: 10px;margin-bottom: 20px;font-size: 20px;color: #000000;margin-bottom: 20px;}
.container2 .pagination-rules .rules-item .rules-name{height: 32px;line-height: 32px;}
#pagination-rules{overflow: hidden;}
.rules-control{width: 142px;height: 36px;line-height: 36px;text-align: center;border: 1px solid #379fff;margin: 0 auto;margin-top: 30px;cursor: pointer;   -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;color: #379fff;
}
.rules-control .up::before{content: ">";margin-left: 10px;transform: rotate(-90deg);display: inline-block;}
.rules-control .down::before{content: ">";margin-left: 10px;transform: rotate(90deg);display: inline-block;}

/* .card-end .btn-primary{width: auto;height: auto;font-size: 14px;} */
/* .btn-primary:hover{font-size: 14px;} */

table tfoot th,table thead th{font-weight: bold!important;}
table th div,table td{font-size: 23px!important;}
.inputNumberDot{width: 200px;height: 60px;text-align: center;font-size: 26px;}

/* 点击搜索框获取焦点 placeholder消失-开始 */
/* WebKit browsers */

input:focus::-webkit-input-placeholder {
    color: transparent;
/* transparent是全透明黑色(black)的速记法，即一个类似rgba(0,0,0,0)这样的值 */
}
    
    
/* Mozilla Firefox 4 to 18 */
    
input:focus:-moz-placeholder {
    color: transparent;
}
    
    
/* Mozilla Firefox 19+ */
    
input:focus::-moz-placeholder {
    color: transparent;
}
    
    
/* Internet Explorer 10+ */
    
input:focus:-ms-input-placeholder {
    color: transparent;
}
/* 点击搜索框获取焦点 placeholder消失-结束 */

.card-body {
    padding: 20px;
}
.card-tip {
    background-color: #e8f3ff;
    color: #115daa;
    line-height: 1.5;
    padding: 20px 20px;
    margin-bottom: 0px;
    font-size: 18px;
    height: 120px;
    box-sizing: border-box;
    transition: all 0.5s;
}
.card-sub-tit{
	padding: 20px 0;
	font-size: 18px;
}
.sub-textarea .card-textarea{
	border: 1px solid rgb(122, 123, 125);
	background-color: #fbfbfb;
	padding: 10px 15px;
	height: 120px;
	border-radius: 4px;
	font-size: 18px;
    width: 100%;
    box-sizing: border-box;
    resize: none;
}
.card-flex {
    overflow: hidden;
    font-size: 14px;
    color: #999999;
    padding: 10px 20px;
}
.card-flex .card-pp {
    float: right;
}
.card-end {
    text-align: center;
    padding: 20px;
}
/deep/ .el-input>input{text-align: center!important;}
/deep/ .card-body .el-table .el-table__footer-wrapper tbody td:nth-last-child(2) div{font-size: 30px;line-height: normal;}
/* ------------移动端start--------------- */
.xs_main {
		width: 100%;
		height: auto;
		box-sizing: border-box;
	}

	.xs_ps_wrap {
		background: #e8edf3;
		padding-bottom: .5rem;
	}

	.xs_ps_title {
		font-size: 0.4rem;
		color: #fff;
		padding: 0.3rem 0.2rem 0;
		height: 1.2rem;
		/* padding-bottom: .2rem; */
		background: url(../assets/pingshen-bg.png) no-repeat top;
		background-size: 100% 100%;
	}

	.xs_ps_main {
		/* background: #fff; */
		margin: 0 0.2rem;
		margin-top: -.5rem;
	}
    .xs_back {
		width: 40%;
		max-width: 4rem;
	}
	.xs_back button {
		/* margin: .4rem .4rem 0; */
	}
    .xs_main_top {
        background: #fff;
        margin-bottom: .4rem;
        padding: .4rem .2rem;
    }
    .xs_main_center {
        background: #fff;
        margin-bottom: .4rem;
        padding: .4rem .2rem;
    }
    .xs_main_bottom {
        background: #fff;
        margin-bottom: .4rem;
        padding: .4rem .2rem;
    }
    .xs_main_pro_info {
        margin-top: .4rem;
        display: flex;
    }
    .xs_main_pro_info_left {
        width: 3rem;
        height: 2rem;
    }
    .xs_main_pro_info_left img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .xs_main_pro_info_center {
        padding: 0 .2rem;
        display: flex;
        flex: 1;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        overflow: hidden;
    }
    .xs_main_info_right_title {
        font-size: .4rem;
    }
    .xs_main_info_right_group {
        color: orange;
        font-size: .5rem;
        font-weight: 700;
        padding: 0.2rem 0 0;
    }
    .xs_main_pro_info_right {
        /* width: 2rem; */
    }
    .xs_main_box_title {
        color: #007eea;
        border-bottom: 3px solid #007eea;
        font-size: .45rem;
        margin-bottom: .2rem;
        display: inline-block;
    }
    .xs_main_box_item {
        margin: .3rem 0;
    }
    .xs_main_box_item_p {
        border-left: 3px solid #3586ce;
        padding-left: 5px;
        font-size: .4rem
    }
    .xs_main_box_item_content {
        padding: .2rem 0;
        color: #4e4c4c;
        font-size: .3rem;
    }
    .xs_main_box_item_team {
        display: flex;
        flex-wrap: wrap;
    }
    .xs_team_item {
        display: flex;
        margin: .2rem;
    }
    .team_item_left_avatar {
        width: 1rem;
        height: 1rem;
    }
    .team_item_right_info {
        padding: 0 .2rem;
    }
    .team_item_people_name {
        color: #000;
        font-size: .35rem;
    }
    .team_item_people_role {
        font-size: .4rem;
        margin-top: .1rem;
    }
    .xs_main_box_tips {
        background-color: #e8f3ff;
        color: #115daa;
        padding: .2rem;
        position: relative;
    }
    .xs_tips_content {
        margin-top: .1rem;
        overflow: hidden;
        /* height: 200px; */
        /* padding-bottom: 60px */
        /* transition: all .5s; */
    }
    .more {
        height: auto;
        /* transition: all .5s; */
    }
    /* .more:after {
        background-image: none !important;
    } */
    .xs_show_more {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
    .xs_hide_more {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
    /* .xs_show_more:after {
        display: block;
        position: absolute;
        width: 100%;
        height: 150px;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom,rgba(255,255,255,0) 0%,#fff 70%);
        pointer-events: none;
    } */
    #read-more + label {
        display: block;
        position: absolute;
        width: 100%;
        height: 150px;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom,rgba(255,255,255,0) 0%,#fff 70%);
        pointer-events: none;
    }
    #read-more {
        display: none
    }
    /* css文本展收起 */ 
    /* #read-more {
        display: none
    }
    #read-more + label {
        display: block;
        position: absolute;
        width: 100%;
        height: 150px;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom,rgba(255,255,255,0) 0%,#fff 70%);
        pointer-events: none;
    }
    #read-more+label::after {
        content: '展开全部';
        display: inline-block;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        font-size: 1.2rem;
        color: #fff;
        padding: 8px 14px;
        border-radius: 0 2px 2px rgba(0,0,0,2);
        cursor: pointer;
        pointer-events: auto;
    }
    #read-more + label:hover::after {
        background-color: #000;
    }
    #read-more:checked ~ .xs_tips_content {
        height: auto !important;
    }
    #read-more:checked + label {
        display: none
    } */
    /* css文本展收起 */
    .xs_pf_list {

    }
    .pf_list_title {
        margin: .2rem 0;
        font-size: .4rem;
    }
    .xs_pf_list table tfoot th {
        background: #F5F7FA
    }
    .xs_pf_list table thead th, .xs_pf_list table tfoot th {
        font-weight: normal !important
    }
    .xs_pf_list table thead th,.xs_pf_list table tbody td, .xs_pf_list table tfoot th {
        border:  1px solid #eee;
        text-align: center;
        padding: .2rem 0;
    }
    .xs_pf_list table tbody td {
        font-size: .3rem !important;
    }
    .xs_pf_list /deep/ .van-field__value {
        border: 1px solid #DCDFE6;
    }
    .sbmit_btn_group {
        display: flex;
        justify-content: center;
        margin-top: .4rem
    }
    /* ------------移动端end--------------- */
</style>

