<template>
    <div>
        <div class="hidden-md-and-down">
            <nav-bar :currentId="99"></nav-bar>
            <div class="banner">
                <img :src="this.$store.state.baseHost+'/fs/download?storeKey='+orgCover" :onerror="defaultBannerImg"/>
            </div>
            <div class="main">
                <div class="ps-title">网络评议</div>
                <div class="container">
                    <div class="mainBox">
                        <!-- <div class="p-navbar">
                            <span>赛事日期</span>
                            <div class="p-bar">
                                <div class="p-bar-item active">全部</div>
                            </div>
                        </div> -->
                        <div class="p-navbar">
                            <span>评议状态</span>
                            <div class="p-bar" id="selectType">
                                <div class="p-bar-item" :class="{'active': current==item.id}" v-for="(item,index) in tabList" :key="index" @click="activeFn(item.id,item.param)">{{item.name}}</div>
                            </div>
                            <input type="text" id="saishiHtml" hidden/>
                        </div>
                        <div class="pd-list">
                            <div class="pd-item" v-for="(item,index) in dataList" :key="index">
                                <div class="pd-img">
                                    <img :src="item.contestCoverUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contestCoverUrl:require('../assets/ps-img.png')"  :onerror="defaultImg"/>
                                    <div class="type" v-if="item.stageStatus==1">评议中</div>
                                    <div class="type yjs" v-if="item.stageStatus==-1">评议已结束</div>
                                    <div class="type wks" v-if="item.stageStatus==0">未开始</div>
                                </div>
                                <div class="pd-info">
                                    <div class="pd-info-title">{{item.contestName}}</div>
                                    <div class="pd-info-time">评议开始时间：{{item.contestStarttime.split(' ')[0]}} 00:00:00</div>
                                    <div class="pd-info-time">评议截止时间：{{item.contestEndtime.split(' ')[0]}} 23:59:59</div>
                                    <div class="pd-info-proccess">
                                        <div class="pd-info-proccess-yp">已评项目：{{item.contprojOkCnt}}</div>
                                        <div class="pd-info-proccess-wp">未评项目：{{item.contprojTotalCnt - item.contprojOkCnt}}</div>
                                        <div class="pd-info-proccess-wcl">评议完成率：{{getnum(item.contprojOkCnt,item.contprojTotalCnt)}}</div>
                                    </div>
                                </div>
                                <div class="pd-btn">
                                    <router-link :to="{name:'jspingshen_list',query:{stageId:item.stageId,contestId: item.contestId}}"  href="#" v-if="item.stageStatus==1">开始评议</router-link>
                                </div>
                            </div>
                        </div>
                        <!--<div class="all-data">已显示评议事项</div>-->
                    </div>
                </div>
            </div>
            <bottom-bar></bottom-bar>
        </div>
        <!-- 移动端 -->
		<div class="hidden-lg-and-up phone_padding">
            <phone-header :phoneCurrentId="99"/>
            <div class="xs_main">
				<div class="xs_ps_wrap">
					<div class="xs_ps_title">网络评议</div>
					<div class="xs_ps_main">
						<!-- <span class="xs_competiton_staus">赛事状态</span> -->
						<div class="xs_tab_category">
							<van-tabs animated @click="handleChangeVanTab($event)">
								<van-tab v-for="(item,index) in tabList" :title="item.name" :key="index">
									<div class="activity_item_wrapper" v-for="item in arrList" :key="item.stageId">
										<div class="activity_item_wrap">
											<div class="activity_item_left">
												<div class="activeity_item_img_wrap">
                                                    <img :src="
                                                    item.contestCoverUrl
                                                        ? $store.state.baseHost +
                                                        '/fs/download?storeKey=' +
                                                        item.contestCoverUrl
                                                        : require('../assets/ps-img.png')
                                                    " :onerror="defaultImg" alt="" >
													<div class="xs_type xs_doing" v-if="item.stageStatus == 1">评议中</div>
													<div class="xs_type xs_yjs" v-if="item.stageStatus == -1">评议已结束</div>
													<div class="xs_type xs_wks" v-if="item.stageStatus == 0">未开始</div>
												</div>
											</div>
											<div class="activity_item_center">
												<div class="item_title van-multi-ellipsis--l2">
													{{ item.contestName }}</div>
											</div>
											<div class="activity_item_right">
												<van-button style="width:100%" type="info" size="small" :to="{
                                                    name: 'pingshen_list',
                                                    query: {
                                                        stageId: item.stageId,
                                                        contestId: item.contestId,
                                                    },
                                                    }" v-if="item.stageStatus == 1">开始评议</van-button>
											</div>
										</div>
										<div class="item_info">
											<p class="van-ellipsis">评议起止时间：
												<span>{{ item.contestStarttime.split(" ")[0] }} 00:00:00</span> ~
												<span>{{ item.contestEndtime.split(" ")[0] }} 23:59:59</span>
											</p>
											<div class="item_proccess">
												<span>已评项目：{{ item.contprojOkCnt }} </span>
												<span>未评项目：{{ item.contprojTotalCnt - item.contprojOkCnt }} </span>
												<span>评议完成率：{{ getnum(item.contprojOkCnt, item.contprojTotalCnt) }}</span>
											</div>
										</div>
									</div>
								</van-tab>
							</van-tabs>
							<van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
								description="暂无内容" v-show="showPhoneEmpty" />
							<!-- <div class="all-data" style="font-size:.3rem;margin:.8rem 0"
								v-show="controlShowAllDataText">已显示所有评议</div> -->
						</div>
					</div>
				</div>
			</div>
			<phone-footer />
        </div>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";
import { isWeiXin, isMobile } from '../utils/userAgent.js'
export default {
    components: {
        navBar,
        bottomBar,
    },
    data () {
        return {
            tabList:[
                {
                    id: 0,
                    name: '全部',
                    param: 'allList',
                },
                {
                    id: 1,
                    name: '未开始',
                    param: 'wksList',
                },
                {
                    id: 2,
                    name: '评议中',
                    param: 'jxzList',
                },
                {
                    id: 3,
                    name: '评议已结束',
                    param: 'yjsList',
                },
            ],
            current: 0,
            dataList:[],
            allList: [],//全部列表数据
            wksList: [],//未开始列表数据
            jxzList: [],//进行中列表数据
            yjsList: [],//已结束列表数据
            defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
            defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',
            orgCover: null,
            // 移动端数据列表
            arrList: [],
            // 控制已显示所有赛事文本的显示与隐藏
            controlShowAllDataText: true,
            // 控制暂无图片的显示隐藏
            showPhoneEmpty: false
        }
    },
    methods: {
        activeFn(type,param){
            this.current = type;
            this.dataList = this[param];
        },
        getData(){
            this.$post("/api/stage/listJudgerStage",{judgerId: this.$store.state.userInfo.judeger.judgerId,stageType:0}).then((res)=>{
                // console.log(res);
                if(res.result){
                    this.dataList = res.resultData;
                    // 移动端
                    this.arrList = res.resultData;
                    this.allList = res.resultData;
                    let wksList=[],jxzList=[],yjsList=[];
                    for(let i=0;i<res.resultData.length;i++){
                        if(res.resultData[i].stageStatus == 0){//未开始
                            wksList.push(res.resultData[i]);
                        }else if(res.resultData[i].stageStatus == 1){//进行中
                            jxzList.push(res.resultData[i]);

                        }else if(res.resultData[i].stageStatus == -1){//已结束
                            yjsList.push(res.resultData[i]);

                        }
                    }
                    this.wksList = wksList;
                    this.jxzList = jxzList;
                    this.yjsList = yjsList;
                }else{
                     if(isWeiXin() || isMobile()){
                        this.$toast.fail(res.resultMsg);
                     } else {
                            this.$message.error(res.resultMsg);
                     }
                   
                }
            }).catch((res)=>{
                console.log(res);
            })
        },
        // 完成率百分比计算
        getnum(yp,total) {
            let percetage = yp / total;
            if(percetage > 0){
                let aNew;
                let re = /([0-9]+\.[0-9]{4})[0-9]*/;
                aNew = percetage.toString().replace(re, "$1");
                return (Math.round(aNew * 10000) / 100).toFixed(2) + '%';
            }else{
                return '0' + '%'
            }
        },
        /**
         * 移动端tab栏切换回调函数
        */
        handleChangeVanTab(e) {
            console.log(e);
            if (e == 0) {
                this.arrList = this.allList;
            } else if (e == 1) {
                this.arrList = this.wksList;
            } else if (e == 2) {
                this.arrList = this.jxzList;
            } else if (e == 3) {
                this.arrList = this.yjsList;
            }
            if (this.arrList.length == 0) {
                console.log('进来了')
                this.controlShowAllDataText = false
                this.showPhoneEmpty = true
            } else {
                this.controlShowAllDataText = true
                this.showPhoneEmpty = false
            }
        }
    },
    mounted () {
        this.getData();
    },
    created () {
        if(this.$store.state.orgData){
            this.orgCover = this.$store.state.orgData.detail.orgCover;
        }
    }
}
</script>

<style scoped>
.main{width: 100%;height: auto;min-height: 92px;background:#e8edf3 url('../assets/pingshen-bg.png') no-repeat top center;margin: 0 auto;min-width: 1200px;padding-bottom: 30px;}
.main .ps-title{width: 1200px;height: 60px;line-height: 60px;margin:0 auto;color: #fff;font-size: 20px;}

/* .container{width: 100%;margin: 0 auto;background: #fff;height: auto;}
.container .mainBox{width: 1200px;
    height: auto;
    margin: 0 auto;
    background: #fff;
    padding: 35px 0 20px 0;} */

.container{width: 1200px;margin: 0 auto;background: #fff;height: auto;padding:38px 66px;box-sizing:border-box;}
.container .mainBox{width: 100%;margin: 0 auto;}

.container .mainBox .p-navbar{width: 100%;height: 42px;line-height:42px;font-size: 16px;color: #000000;display: flex;}
.container .mainBox .p-navbar span{display: inline-block;}
.container .mainBox .p-navbar .p-bar{flex: 1;display: flex;flex-wrap: nowrap;overflow: hidden;align-items: center;}
.container .mainBox .p-navbar .p-bar .p-bar-item{width: auto;height: 26px;line-height:26px;font-size: 16px;color: #000;text-align: center;border-radius: 4px;cursor: pointer;margin-left: 35px;padding: 0 13px;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
    }
.container .mainBox .p-navbar .p-bar .p-bar-item.active{background:#038eeb;color: #fff;;}
.container .mainBox .p-navbar .p-bar .p-bar-item:hover{background:#038eeb;color: #fff;}

.pd-list{
    width: 100%;
    height: auto;
    padding-top: 40px;
    overflow: hidden;
}
.pd-list .pd-item{width: 100%;height: 135px;display: flex;align-items: center;margin-bottom: 67px;}
.pd-list .pd-item .pd-img{width: 235px;height: 135px;position: relative;}
.pd-list .pd-item .pd-img img{width: 235px;height: 135px;display: block;}
.pd-list .pd-item .pd-img .type{/*width: 55px;height: 20px;*/line-height: 20px;color: #fff;background: #f45700;text-align: center;border-radius: 20px;border: 1px solid #fff;position: absolute;top: 8px;left: 8px;padding: 5px;}
.pd-list .pd-item .pd-img .type.yjs{background: gray;color:#fff;}
.pd-list .pd-item .pd-img .type.wks{background: #5cb85c;color:#fff;}
.pd-list .pd-item .pd-info{width: 530px;height: 135px;overflow: hidden;margin-left: 20px;}
.pd-list .pd-item .pd-info .pd-info-title{width: 100%;height: 35px;line-height:35px;font-size: 20px;color: #000000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.pd-list .pd-item .pd-info .pd-info-time{width: 100%;height: 30px;line-height: 30px;font-size:15.45px;color:#222121;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.pd-list .pd-item .pd-info .pd-info-proccess{width: 100%;height:30px;line-height: 30px;display: flex;justify-content: space-between;font-size: 15.45px;color: #222121;}

.pd-list .pd-item .pd-btn{display: flex;justify-content: flex-end;flex: 1;}
.pd-list .pd-item .pd-btn a{width: auto;height: 45px;line-height: 45px;padding:0 25px;font-size:20px;background: #038eeb;color:#fff;border-radius: 4px;}


.all-data{text-align: center;}

	/* --------------移动端样式------------------ */
	.phone_banner {
		height: 4rem;
	}

	.xs_main {
		width: 100%;
		height: auto;
		/* padding: 0 .2rem; */
		box-sizing: border-box;
	}

	.xs_ps_wrap {
		background: #e8edf3;
		padding-bottom: .5rem
	}

	.xs_ps_title {
		font-size: 0.4rem;
		color: #fff;
		padding: 0.3rem 0.2rem 0;
		height: 2rem;
		/* padding-bottom: .2rem; */
		background: url(../assets/pingshen-bg.png) no-repeat top;
		background-size: 100% 100%;
	}

	.xs_ps_main {
		background: #fff;
		/* padding: 0.3rem; */
		margin: 0 0.2rem;
		margin-top: -1rem;
		/* display: flex;
  align-items: center;
  overflow: hidden; */
	}

	.xs_competiton_staus {
		font-size: 14px;
	}

	.xs_tab_category {
		flex: 1;
		padding-bottom: .1rem
	}

	.xs_tab_category /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
		padding-left: 0;
	}

	.van-tab--active {
		/* color: #fff; */
		/* background: #038eeb; */
	}

	.xs_tab_category /deep/ .van-tabs__line {
		background-color: #038eeb;
	}

	.activity_item_wrapper {
		padding: 0.4rem;
	}

	.activity_item_wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.activity_item_left {}

	.activeity_item_img_wrap {
		width: 3rem;
		height: 2rem;
		position: relative;
	}

	.activeity_item_img_wrap img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.activity_item_center {
		padding: 0 0.4rem;
		flex: 1;
	    /* width: 2rem; */
		/* width: 200px; */
	}

	.item_title {
		font-size: 0.4rem;
	}

	.item_info {
		margin-top: 0.2rem;
	}

	.item_info p {
		font-size: 0.3rem;
	}

	.item_proccess {
		display: flex;
		justify-content: space-between;
		font-size: 0.3rem;
		margin-top: 0.2rem;
	}

	/* .activity_item_right {
		width: 2rem;
	} */

	.xs_type {
		/* width: 1.44rem; */
		/* height: 0.53rem; */
		/* line-height: 20px; */
        padding: 3px 5px;
		color: #fff;
		text-align: center;
		border-radius: 2rem;
		border: 1px solid #fff;
		position: absolute;
		top: 5%;
		left: 5%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 8;
	}

	.xs_doing {
		background: #f45700;
	}

	.xs_wks {
		background: #5cb85c;
	}

	.xs_yjs {
		background: gray;
	}

	.xs_tab_category /deep/ .van-empty__image {
		width: 3rem;
		height: 3rem;
	}

	.xs_tab_category /deep/ .van-empty__description {
		font-size: .4rem;
	}

	/* --------------移动端样式------------------ */
</style>