<template>
  <div>
    <nav-bar v-bind:currentId="2"></nav-bar>
    <div class="main">
        <div class="banner">
          <img :src="orgCover?this.$store.state.baseHost+'/fs/download?storeKey='+orgCover:require('../assets/banner.jpg')" :onerror="defaultBannerImg"/>
        </div>
        <div class="mainBox">
            <div class="title">
                <div class="title-text">大赛报名</div>
            </div>
            <div class="sign-up" v-if="loading">加载中...</div>
            <template v-else>
                <div class="sign-up" v-if="listData.length">
                    <div class="item" v-for="(item,index) in listData" :key="index" :style="{'margin-left':(index%3==0)?'0':'20px'}">
                        <div class="item-pic"><img src="../assets/pic.png"/></div>
                        <div class="item-name">{{item.contestName}}</div>
                        <div class="item-time">开始时间：{{item.contestEnrollStarttime}}</div>
                        <div class="item-time">结束时间：{{item.contestEnrollEndtime}}</div>
                        <a href="javascript:void(0)" @click="signUp(item.contestId)" class="item-btn">立即报名</a>
                    </div>
                </div>
                <div class="sign-up" v-else>暂无数据</div>
            </template>
        </div>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";

export default {
  components:{
    navBar,
    bottomBar
  },
  data(){
      return{
        orgCover: null,
        loading: true,
        listData:[],
        defaultBannerImg: 'this.src="' + require("../assets/banner.jpg") + '"',
      }
  },
  created () {
    if(this.$store.state.orgData){
      this.orgCover = this.$store.state.orgData.detail.orgCover;
    }
  },
  methods: {
    //获取机构下的大赛列表
    getDasaiList(){
      this.$post('/api/contest/list',{orgId:this.$store.state.oid,contestStatus:1}).then((res)=>{
        // console.log(res);
        this.loading = false;
        if(res.result){
          if(res.resultData.length>0){
              this.listData = res.resultData;
          }
        }else{
          this.$message.error(res.resultMsg)
        }

      }).catch((res)=>{
        console.log(res)
        this.$message.error(res);
      })
    },
    signUp(contestId){
      if(!this.$store.state.userInfo){
        this.$router.push({name: 'login',query:{redirect: '/baoming?contestId='+contestId}})
        return
      }
      if(!this.$store.state.userInfo.student){
        this.$message.error('只有学生帐号允许报名');
        return
      }
      this.$router.push({name: 'baoming',query:{contestId: contestId}})
    }
  },
  mounted () {
      this.getDasaiList();
      
  }
}
</script>

<style scoped>
.main{width: 100%;height: auto;}

.mainBox{width: 1200px;height: auto;margin:0 auto;margin-top: 13px;background:#fff;padding:20px 0 20px 0;}
.title{width:1132px;margin: 0 auto;height: 29px;border-bottom:1px solid #c9c9c9;overflow: hidden;display: flex;justify-items: center;justify-content: space-between;}
.title .title-text{font-size:18px;color:#3ebdf6;border-bottom:2px solid #3ebdf6;float: left;height: 27px;line-height: 27px;margin-left: 6px;}
.title .more{text-decoration: none;color:#636363;font-size:14px;height: 29px;line-height: 29px;}

/* 大赛流程 */
.sign-up{width: 1044px;margin: 0 auto;height: auto;min-height:270px;display:block;margin-top: 40px;overflow: hidden;}
.sign-up .item{width: 330px;height: 370px;border-radius: 4px;border: 1px solid #61a7f3;margin-bottom: 30px;position: relative;padding-top: 20px;float:left}
.sign-up .def-temp{width: 332px;height: 372px;background:#fff;}
.sign-up .item .item-pic{width: 295px;height: 159px;margin: 0 auto;}
.sign-up .item .item-pic img{width: 295px;height: 159px;display: block;}
.sign-up .item .item-name{height: 50px;line-height: 25px;font-size:18px;color:#000;margin: 0 auto;text-align:center;overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    padding: 0 20px;
    margin: 10px 0;
}
.sign-up .item .item-time{width:100%;height: 25px;line-height: 25px;font-size:14px;color:#000;text-align: center;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: center;}
.sign-up .item .item-btn{display:block;text-align:center;color:#fff;font-size:16px;background:#0084e1;height:36px;line-height:36px;width: 98px;border-radius: 4px;position: absolute;left: 50%;margin-left: -49px;bottom: 10px;-moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
      user-select:none;}

</style>