<template>
  <div>
    <nav-bar v-bind:currentId="3"></nav-bar>
    <div class="main">
      <div class="banner">
        <img :src="orgCover?this.$store.state.baseHost+'/fs/download?storeKey='+orgCover:require('../assets/wonderful_review_banner.jpg')" />
      </div>
      <!-- <div class="mainBox">
        <div class="title">
          <div class="title-text">精彩回顾</div>
        </div>
        <template v-if="listData.length">
          <div class="saishiList">
            <div class="saishi-item" v-for="(item,index) in listData" :key="index">
              <div class="left">
                <img :src="item.contestCoverUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contestCoverUrl:require('../assets/ad-pic2.png')" />
              </div>
              <div class="right">
                <div class="right-type" v-if="item.contestStatus==1">进行中</div>
                <div class="right-type yjs" v-if="item.contestStatus==-1">已结束</div>
                <div class="right-title">
                  {{item.contestName}}
                </div>
                <div class="right-text">
                  {{item.contestRemark?item.contestRemark:'暂无赛事简介'}}
                </div>
                <div class="right-step">
                  大赛时间：{{item.contestStarttime}} 至 {{item.contestEndtime}}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-saishiList">暂无赛事</div>
        </template>
      </div> -->
        <ul class="wonderful-list">
            <template v-if="listData.length">
                <li v-for="(item,index) in listData" :key="index">
                    <div class="wonderful-month clearfix">
                        <span>{{item.contestStarttime.split(" ")[0]}}</span>
                        <i class="cricle"></i>
                    </div>
                    <div class="wonderful-content">
                        <div class="content-img">
                            <img :src="item.contestCoverUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contestCoverUrl:require('../assets/image404.gif')" :onerror="defaultImg"/>
                        </div>
                        <div class="content-text">
                            <div class="text-title">{{item.contestName}}</div>
                            <p class="text-info">{{item.contestRemark}}</p>
                            <router-link :to="{'name':'wangqisaishi_detail',query:{contestId: item.contestId}}" >查看详情&nbsp;&nbsp;>></router-link>
                        </div>
                    </div>
                </li>
            </template>
            <template v-else>
                <div class="no-list">暂无赛事数据</div>
            </template>
        </ul>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";

export default {
  components: {
    navBar,
    bottomBar
  },
  data() {
    return {
      listData: [],
      orgCover: null,
      defaultImg: 'this.src="' + require("../assets/image404.gif") + '"',
    };
  },
  methods: {
    //获取机构下的大赛列表-进行中
    getDasaiList(){
      this.$post('/api/contest/list',{orgId:this.$store.state.oid,contestStatus:-1}).then((res)=>{
        // console.log(res);
        if(res.result){
          if(res.resultData.length>0){
            this.listData = res.resultData;
            // console.log(this.listData);
          }
        }else{
          this.$message.error(res.resultMsg)
        }
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    },
    
  },
  created () {
    let purl = window.location.href;
		if(purl.indexOf('gradjob')!=-1){
      window.open(`https://www.gradjob.org/dshd/`, '_blank').location;
		} 
    
  },
  mounted(){
    this.getDasaiList();
    
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: auto;
  color:#fff;
  background: #00a9e4;
  padding-bottom: 100px;
}
.banner {
    width: 100%;
    // height: 600px;
    text-align:center;
}
.banner img {
    height:100%;
    width: auto;
    margin: 0 auto;;
}
.wonderful-list {
    width:1200px;
    margin:0 auto;
    /* border: 1px solid #000; */
}
.wonderful-list li {
    display:flex;
    width:100%;
}
.wonderful-list li .wonderful-month {
    width: 100px;
    line-height: 240px;
    text-align: center;
    position: relative;
    border-right: 1px solid rgba(255,255,255,.6);
    /* border: 1px solid #000; */
}
.wonderful-list li .wonderful-month  .cricle {
    width:16px;
    height: 16px;
    background-color: #fff;
    box-shadow:0 0 10px #fff;
    border-radius:50%;
    position:absolute;
    right:0;
    bottom:0;
    transform:translate(50%, 50%);
    z-index: 2;
}
.wonderful-list li .wonderful-content {
    flex:1;
    box-sizing: border-box;
    padding-left:30px;
    padding-top:40px;
    display:flex;
    /* border: 1px solid #0000ff; */
}
.wonderful-list li:first-child .wonderful-content {
    padding-top:0;
}
.wonderful-list li .wonderful-content .content-img {
    overflow:hidden;
    width: 512px;
    height: 240px;
    box-sizing: border-box;
    background-color: #fff;
    border: 5px solid #fff;
    
    position: relative;
    overflow: hidden;
}
.wonderful-list .wonderful-content .content-img img{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%,-50%);
}
.wonderful-list li .wonderful-content .content-text {
    flex:1;
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
}
.wonderful-list .wonderful-content .content-text  .text-title {
    font-size: 18px;
    line-height: 16px;
}
.wonderful-list .wonderful-content .content-text  .text-info {
    line-height: 20px;
    margin:20px 0;
}
.wonderful-list .wonderful-content .content-text  a{
    position: absolute;
    left:20px;
    bottom:10px;
    color:#ffff00;
}

.no-list{text-align: center;font-size: 30px;padding: 100px 0;}

</style>