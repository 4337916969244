<template>
    <div class="uc-sidebar">
        <div class="uc-side-hd">
            <h3>用戶中心</h3>
            <p>User center</p>
        </div>
        <ul class="uc-navbar">
            <!-- <li class="menu"><router-link to="/student_profile"><i class="iconfont icon-gerenziliao"></i>个人资料</router-link></li>
            <li class="menu"><router-link to="/student_competition"><i class="iconfont icon-saishi"></i>我的赛事</router-link></li>
            <li class="menu active"><router-link to="/student_pwd"><i class="iconfont icon-xiugaimima"></i>修改密码</router-link></li> -->
            <li class="menu" :class="currentId==item.id?'active':''" v-for="(item,index) in navbarData" :key="index"><router-link :to="item.link"><i :class="item.iClass"></i>{{item.name}}</router-link></li>
        </ul>
        <div class="uc-foot">
            <a href="javascript:void(0);" class="btn btn-out" @click="logout()">退出登錄</a>
        </div>   
    </div>
</template>

<script>
export default {
    props:{
        currentId: {
            default: 0,
        },
    },
    data () {
        return {
            navbarData:[
                {
                    name: '個人資料',
                    link: '/ft/student_profile',
                    id: 0,
                    iClass: 'iconfont icon-gerenziliao',
                },
                {
                    name: '修改密碼',
                    link: '/ft/student_pwd',
                    id: 6,
                    iClass: 'iconfont icon-xiugaimima',
                },
            ]
        }
    },
    methods: {
        logout(){
			this.$confirm('是否登出?', '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
            }).then(() => {
            //点击确定的操作(调用接口)
            this.$post("/api/appuser/logout").then((res) => {
					// console.log(res);
					if (res.result) {
						this.$store.commit("LOG_OUT");
                        this.$router.push("/ft/login/"+this.$store.state.oid);
					}
				});
            }).catch(() => {
            //点取消的提示
                return;
            });
		},
    }
}
</script>

<style lang="less" scoped>
.uc-sidebar{position: relative;float:left;background-color: #FFFFFF;width: 250px;height: 550px;
    .uc-side-hd{height: 75px;background: url(../../assets/uchead.png) no-repeat;border-left: 7px solid #102b57;padding-left: 20px;padding-top: 15px;box-sizing: border-box;
        h3{margin: 0;font-size: 26px;}
        p{margin: 0;font-size: 12px;color: #888e99;}
    }
    .uc-navbar{padding: 20px 50px;
        li{margin-bottom: 30px;
            a{font-size: 18px;color: #888e99;
                .iconfont{font-size: 18px;padding-right: 20px;}
            }
            &.active a{color: #198cff;}
        }

    }
    .uc-foot{position: absolute;width: 100%;text-align: center;
        .btn-out{background-color: #e0e0e0;width: 140px;height: 42px;color: #666;line-height: 42px;padding: 0;display: inline-block;border-radius: 4px;}
    }
}
</style>