<template>
  <div>
    <nav-bar v-bind:currentId="99"></nav-bar>
    <div class="main">
        <div class="top-banner">
            <img src="../assets/lastCompetition_details_banner.jpg" alt="">
            <h2 id="contestName">{{listData.contestName?listData.contestName:'-- 暂无 --'}}
                <p id="contestTime">比赛时间：{{listData.contestStarttime +  ' 至 ' + listData.contestEndtime}}</p>
            </h2>
        </div>
        <!-- 赛事介绍 -->
        <div class="special_content clearfix">
            <div class="item-bg clearfix">
                <img src="../assets/competition_block_bg2.png" alt="">
            </div>
            <div class="item-content clearfix">
                <h3>赛事介绍</h3>
                <div class="content-text" v-html="listData.contestInstruction" v-if="listData.contestInstruction!=null&&listData.contestInstruction!==''">该赛事暂无详情介绍信息.</div>
                <div class="content-text" v-else>该赛事暂无详情介绍信息.</div>
            </div>
        </div>
        <!-- 报名须知 -->
        <div class="special_content  clearfix">
            <div class="item-bg clearfix">
                <img src="../assets/competition_block_bg2.png" alt="">
            </div>
            <div class="item-content clearfix">
                <h3>报名须知</h3>
                <div class="content-text" v-html="listData.enroll.contestEnrollRemark" v-if="listData.enroll"></div>
                <div class="content-text" v-else>该赛事暂无报名须知信息.</div>
	            <div class="activity-box">
	               <div class="activity-info">
                       <h3>报名时间</h3>
	                   <p class="sign-up" v-if="listData.enroll">{{listData.enroll.contestEnrollStarttime + " 至 " + listData.enroll.contestEnrollEndtime}}</p>
	                   <div class="info-contact">
	                       <h3>联系方式：</h3>
	                       <p>
                                <span><i class="el-icon-phone"></i>{{listData.contestPhone ? listData.contestPhone : '--'}}</span>
                           </p>
	                       <p>
			         	        <span><i class="el-icon-s-promotion"></i>{{listData.contestEmail ? listData.contestEmail : '--'}}</span>
                           </p>
	                       <p>
			         	        <span><i class="el-icon-location-information"></i>{{listData.contestAddress ? listData.contestAddress : '--'}}</span>
                           </p>
	                    </div>
	                   <div class="info-contact">
	                       <p>
                                <span v-if="listData.items">主办单位：{{listData.items.sponsorOrg}}</span>
                           </p>
	                       <p>
                                <span v-if="listData.items">承办单位：{{listData.items.undertakeOrg}}</span>
                           </p>
	                       <p>
                                <span v-if="listData.items">协办单位：{{listData.items.assistOrg}}</span>
                           </p>
	                    </div>
	               </div>
	            </div>
            </div>
        </div>
        <!-- 赛事历程 -->
        <div class="special_content clearfix">
            <div class="item-bg clearfix">
                <img src="../assets/competition_block_bg2.png" alt="">
            </div>
            <div class="item-content clearfix">
                <h3>赛事历程</h3>
                <div class="activity-box">
                   <div class="activity-step" v-for="(item,index) in stateData" :key="index">
                       <div class="title">
                           <p><b>{{item.stageName}}</b><i></i></p>
                       </div>
                       <div class="activity-time" v-if="item.stageStarttime!=null&&item.stageStarttime!=''&&item.stageEndtime!=null&&item.stageEndtime!=''">
                           <span>{{item.stageStarttime?item.stageStarttime.split(" ")[0]:'待定' + " ~ " + item.stageEndtime?item.stageEndtime.split(" ")[0]:'待定'}}</span>
                       </div>
                       <div class="activity-time" v-else>待定</div>
                   </div> 
                </div>
            </div>
        </div>
        <div class="saishifengsai" v-if="fujianData.length">
            <el-carousel :interval="4000" type="card" height="400px">
                <el-carousel-item v-for="(item,index) in fujianData" :key="index">
                <!-- <h3 class="medium">{{ item }}</h3> -->
                <!-- <img :src="item.contattachFileUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contattachFileUrl:require('../assets/image404.gif')"/> -->
                <el-image style="width:auto;height:100%;" :src="item.contattachFileUrl?$store.state.baseHost+'/fs/download?storeKey='+item.contattachFileUrl:require('../assets/image404.gif')" fit="fit"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 获奖名单 -->
        <div class="winning-list">
            <div class="winning-banner">
                <img src="../assets/winning-list.jpg" alt="">
            </div>
             <el-table :data="winnerData" border>
                <el-table-column prop="contprojName" label="获奖项目" align="center"></el-table-column>
                <el-table-column prop="conteamName" label="获奖团队" align="center" v-if="conteamName!=null"></el-table-column>
                <el-table-column prop="contprojLeader" label="获奖团队" align="center" v-else>
                    <template slot-scope="{row}">
                        负责人：{{ row.contprojLeader || '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="contprojGroup" label="参赛组别" align="center"></el-table-column>
                <el-table-column prop="contprojIndustry" label="参赛类别" align="center"></el-table-column>
                <el-table-column prop="contprizeName" label="获得奖项" align="center"></el-table-column>
            </el-table>
        </div>
        <div class="goback">
            <router-link to="/wangqisaishi_list">返回往期列表</router-link>
        </div>
    </div>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import bottomBar from "@/components/bottomBar";

export default {
  components: {
    navBar,
    bottomBar
  },
  data() {
    return {
      listData: [],
      orgCover: null,
      contestId: null,
      stateData:[],
      prizeData: [],
      fujianData: [],
      winnerData:[],
    };
  },
  methods: {
    //获取机构下的大赛列表-进行中
    getDasaiList(){
      this.$post('/api/contest/query',{contestId:this.contestId}).then((res)=>{
        console.log(res);
        if(res.result){
            this.listData = res.resultData;
            // console.log(this.listData);
        }else{
          this.$message.error(res.resultMsg)
        }
      }).catch((res)=>{
        console.log(res)
        // this.$message.error(res);
      })
    },
    //获取大赛的所有环节信息
    getListStage(){
        this.$post("/api/contest/listStage",{contestId:this.contestId}).then((res)=>{
            console.log(res);
            if(res.result){
                this.stateData = res.resultData;
            }else{
                this.$message.error(res.resultMsg);
            }
        }).catch((res)=>{
            console.log(res);
        })
    },
    //获取大赛的奖项信息
    getListPrize(){
        this.$post("/api/contest/listPrize",{contestId:this.contestId}).then((res)=>{
            console.log(res);
            if(res.result){
                this.prizeData = res.resultData;
            }else{
                this.$message.error(res.resultMsg);
            }
        }).catch((res)=>{
            console.log(res);
        })
    },
    getListFujian(){
        this.$post("/api/contest/listAttach",{contestId:this.contestId,category:2}).then((res)=>{
            console.log(res)
            if(res.result){
                if(res.resultData.length){
                    this.fujianData = res.resultData
                }
            }else{
                this.$message.error(res.resultMsg);
            }
        }).catch((res)=>{
            console.log(res);
        })
    },
    getListWinner(){
        this.$post("/api/contest/listWinner",{contestId:this.contestId}).then((res)=>{
            console.log('附件')
            console.log(res)
            if(res.result){
                if(res.resultData.length){
                    this.winnerData = res.resultData
                }
            }else{
                this.$message.error(res.resultMsg);
            }
        }).catch((res)=>{
            console.log(res);
        })
    },
  },
  created () {
    
  },
  mounted(){
      if(this.$route.query.contestId){
            this.contestId = this.$route.query.contestId;
            this.getDasaiList();
            this.getListStage();
            this.getListPrize();
            this.getListFujian();
            this.getListWinner();
      }else{
          this.$message.error('缺少大赛id')
      }
    
  },
};
</script>
<style scoped>
.main {
    color:#fff;
  background: #00a9e4;

}
.main .top-banner {
    width: 100%;
    margin:0 auto;
    position: relative;
}
.main .top-banner img {
    display:block;
    width: 100%;
}
.main .top-banner h2 {
	width:100%;
	text-align:center;
    font-size: 42px;
    position: absolute;
    top:30%;
    left:50%;
    transform: translate(-50%,-30%);
}
.main .top-banner p {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    padding: 0 15px;
    margin-top: 13px;
    line-height: 24px;
    color: #009ae4;
    background-color: #fff;
}
/* 赛事历程    ----通过项目-----*/
.special_content .activity-box .projectes-pass {
    box-sizing: border-box;
    padding:0 10px;
    margin: 20px 0;
    border: 1px dashed rgba(255,255,255,.5);
}
.activity-box .projectes-pass  h4 {
    text-align: center;
    font-size:16px;
    line-height: 32px;
    /* border: 1px solid #000; */
}
.project-box {
    display:flex;
}
.project-box  ul {
    text-align: center;
    font-weight: bold;
    line-height: 40px;
    border-top: 1px solid rgba(255,255,255,.5);
    border-bottom: 1px solid rgba(255,255,255,.5);
    border-right: 1px solid rgba(255,255,255,.5);
}
.project-box ul>li {
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
	padding: 0 5px;
}
.project-box  ul:first-child {
    border-left: 1px solid rgba(255,255,255,.5);
}
 .project-box .project-name {
    flex:4;
}
.project-box .project-team {
    flex:3;
}
.project-box .team-num {
    flex:1;
}
.project-box .industry-sort {
    flex:2;
}
.project-box .industry-ratio {
    flex:1.5;
}
.project-box  ul  li {
    line-height: 40px;
    font-weight: normal;
    border-bottom: 1px solid rgba(255,255,255,.5);
}
.project-box  ul  li:first-child {
      border-top: 1px solid rgba(255,255,255,.5);
}
.project-box  ul  li:last-child {
    border-bottom: none;
}

.projectes-pass .total {
    width:25%;
    line-height: 40px;
    display:flex;
    justify-content:space-around;
}
.projectes-pass .total span {
    flex:1;
}
/* 获奖名单 */
.winning-list,.special_content{
    width:1200px;
    margin: 40px auto;
    /* border: 1px solid #ffff00; */
}
.winning-list .winning-banner {
    width:1200px;
    height: 400px;
    margin: 0 auto;
}
.winning-list .winning-banner img {
    display:block;
    width:100%;
}
/* 返回按钮 */
.goback {
    padding:30px 0 60px;
    text-align: center;
}
.goback a {
    display:inline-block;
    width: 128px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    background:linear-gradient(to right, #45a1db 5%, #9f70ff 25%,#fb7bff 55%,#9f70ff 80%,#45a1db 100%);
    color:#fff;
}
.copyright {
    text-align:center;
    font-size: 12px;
    line-height: 80px;
    background-color: #0f9ddb;
}

.saishifengsai{width: 1200px;height: auto;margin: 0 auto;}

/deep/ .el-table th, .el-table tr{
    background:transparent;
    color: #fff;
}
/deep/  .el-table, .el-table__expanded-cell {
    background-color: transparent;
    color: #fff;
}

/deep/ .el-table tr {
    background-color: transparent!important;
}
/deep/  .el-table--enable-row-transition .el-table__body td, .el-table .cell{
    background-color: transparent;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: transparent;
    
}
</style>