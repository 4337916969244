import { render, staticRenderFns } from "./GzzjLogin.vue?vue&type=template&id=df08d60c&scoped=true&"
import script from "./GzzjLogin.vue?vue&type=script&lang=js&"
export * from "./GzzjLogin.vue?vue&type=script&lang=js&"
import style0 from "./GzzjLogin.vue?vue&type=style&index=0&id=df08d60c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df08d60c",
  null
  
)

export default component.exports